import React, { useState, useContext, useEffect } from 'react';
import { Button, Row } from 'reactstrap';
import cogoToast from 'cogo-toast';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import CurrencyInput from 'react-currency-input-field';
// import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import MainTable from '../../../components/tables/MainTable';
import { RecaudoContext } from '../RecaudoContext';
// import { refFormat } from '../../../helpers/tableFormatters';
import { currencyFormat } from '../../../helpers/tableFormatters';
import LoteInfo from '../../recaudar/sections/LoteInfo';
// import ThemeBackground from '../../../components/common/ThemeBackground';
import { clientFormatter } from '../../../helpers/tableFormatters';
import { recaudoServices } from '../../../services';
import Spinner from 'reactstrap/lib/Spinner';
import { formatDate } from '../../../helpers/dateFormatters';
import RecaudoLoteModal from '../modals/RecaudoLoteModal';
import RecaudoClientModal from '../modals/RecaudoClientModal';

const RecaudoLote = () => {
  const context = useContext(RecaudoContext);
  const { loading, creditsPag, pagSelected, loteSelected, recaudosLote, showModalLote, handleChangePag, handleChangeLote, handleSaveLote, onCancelLote } = context;
  const [requesting, setRequesting] = useState(false);
  const [showModal, setShowModal] = useState();
  const [creditSelected, setCreditSelected] = useState();
  const [recaudos, setRecaudos] = useState(0);
  const [numRecaudos, setNumRecaudos] = useState(0);
  const [dataTable, setDataTable] = useState([]);

  const [dateRange, setDateRange] = useState();
  const [lotes, setLotes] = useState();
  const [listLotes, setLIstLotes] = useState([]);

  const [recaudoSelected, setRecaudoSelected] = useState();
  const [showRecaudoModal, setShowRecaudoModal] = useState();
  const [showLoteModal, setShowLoteModal] = useState();

  const columns = [
    {
      dataField: 'recaudo',
      text: 'Nro.',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'nombreCliente',
      text: 'Cliente',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      // formatter: (dataField, selected) => clientFormatter(dataField, {
      //   nomCliente: selected.nombreCliente,
      //   tipoIdCliente: selected.tipoIdentificacion,
      //   idCliente: selected.identificacion
      // }),
    },
    {
      dataField: 'credito',
      text: 'Crédito',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'nombreModalidad',
      text: 'Modalidad',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'nombreConcepto',
      text: 'Concepto',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'valorRecaudo',
      text: 'Valor Recaudado',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acciones',
      classes: 'border-0 py-2 text-center',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  useEffect(() => {
    if (!dateRange || !pagSelected) return;
    loadLotes();
  }, [dateRange, pagSelected])

  useEffect(() => {
    if(!lotes) return;
    const tempListLotes = lotes.map(lote => ({
      value: lote.lote,
      label: `${lote.lote} - ${lote.fecha}`
    }));
    handleChangeLote();
    setLIstLotes(tempListLotes);
  }, [lotes])


  // useEffect(() => {
  //   setRecaudos(0);
  //   setNumRecaudos(0);
  //   setCreditSelected(null);
  //   // loadDatatable();
  // }, [creditsPag]);

  const actionsFormatter = (dataField, fieldSelected) => {
    if (!fieldSelected) return <></>
    return (
      <div className='pl-4'>
        <IconButton
          onClick={() => handleClickRemove(fieldSelected)}
        >
          <FontAwesomeIcon
            style={{ width: '16px' }}
            icon={faTrash}
            color="SteelBlue"
          />
        </IconButton>
      </div>
    )
  }

  const handleClickRemove = data => {
    setRecaudoSelected(data);
    setShowRecaudoModal(true);
  }

  const moneyFormatter = value => {
    return (
      <CountUp end={value} duration={1} prefix="$" separator="." decimal="," decimals={2} style={{fontSize: '1rem'}}/>
    )
  }

  const currencyInputFormatter = selected => {
    return (
      <div className='pl-4'>
        <CurrencyInput
          className="form-control pl-2 text-right"
          allowNegativeValue={false}
          id={selected.credito}
          prefix='$'
          intlConfig={{ locale: 'es-CO', currency: 'COP' }}
          onValueChange={ (e) => setCreditSelected({ id: selected.credito, value: e || '0'  })}
          decimalScale={2}
          maxLength={20}
          placeholder="$ 0.00"
          tabIndex="5" />
      </div>
    )
  }

  // useEffect(() => {
  //   if(!creditSelected) return;
  //   onChangeRecaudo(creditSelected);
  // }, [creditSelected])

  // useEffect(() => {
  //   if(!dataTable || !dataTable[0]) return;
  //   const creditsRecaudos = dataTable.filter(c => c.recaudo > 0);

  //   const tempRecaudo = creditsRecaudos[0] ? creditsRecaudos.map(c=>c.recaudo).reduce((a, b) => a+b) : 0;
  //   const tempNumRecaudos = creditsRecaudos.length;
  //   setRecaudos(tempRecaudo);
  //   setNumRecaudos(tempNumRecaudos);
  // }, [dataTable])

  const loadLotes = async () => {
    const response = await recaudoServices.getLotes(
      { 
        idPag: pagSelected.id, 
        startDate: dateRange[0], 
        endDate: dateRange[1], 
      });
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los lotes por Pagaduría' });
      return;
    }
    setLotes(response.objResponse);
  }

  const loadDatatable = data => {
    let tempData;
    if(data){
      tempData = [...dataTable];
      const index = dataTable.findIndex(credit => credit.credito == data.id);
      const selected = tempData[index];
      const recaudoValue = Number(data.value.replaceAll(' ', '').replace('$', '.').replace('.', '').replace(',', '.'))
      const intereses = recaudoValue >= selected.saldo_interes 
        ? selected.saldo_interes
        : recaudoValue;
      const capital = recaudoValue > selected.saldo_interes 
        ? recaudoValue - selected.saldo_interes
        : 0;
      const updatedItem = {
        ...tempData[index],
        intereses,
        capital,
        recaudo: recaudoValue
      };
      tempData[index] = updatedItem
    } else {
      tempData = creditsPag.map(credit => {
        return {
          ...credit,
          intereses: 0,
          capital: 0,
          recaudo: 0
        }
      })
    }
    setDataTable(tempData);
  }

  const onChangeRecaudo = (data, table) => {
    // loadDatatable(data);

  }

  const handleCleanLote = () => {
    setRecaudos(0);
    setNumRecaudos(0);
    setDataTable([]);
    onCancelLote();
  }

  const handleDateRange = (dates) => {
    if(!dates[0] || !dates[1]) return;
    const range = [];
    range[0] = dates[0] ? formatDate(dates[0]) : "";
    range[1] = dates[1] ? formatDate(dates[1]) : "";
    setDateRange(range);
  }

  const onRecaudar = async paymentData => {
    // const selectedRecaudos = dataTable.filter(c => c.recaudo > 0)
    // if(selectedRecaudos.length < 1 || !pagSelected){
    //   cogoToast.warn("No hay valores para recaudar",
    //     { position: 'bottom-left', heading: 'Sin Valores' });
    //   return;
    // }
    // setRequesting(true);
    // const payload = {
    //   idPagaduria: pagSelected.id,
    //   recaudos: selectedRecaudos.map(r => ({
    //     idPrestamo: r.prestamo,
    //     valorRecaudo: r.recaudo,
    //     abonoInteres: r.intereses,
    //     abonoCapital: r.capital
    //   })),
    //   tipoRecaudo: 2,
    //   ...paymentData,
    // }

    // const response = await recaudoServices.setRecaudoLote(payload);
    // if (!response || response.error) {
    //   cogoToast.error(response.msg || "No se logro completar la solicitud de recaudo por lote",
    //     { position: 'bottom-left', heading: 'Error' });
    //   return;
    // } else {
    //   cogoToast.success("Recaudo por lote realizado con éxito",
    //     { position: 'bottom-left', heading: 'Exitoso' });
    // }
    // // loadDatatable();
    // handleSaveLote();
    // setRequesting(false);
    
  }

  const onCloseModal = value => {
    // if (value) loadClientRecaudes();
    setShowRecaudoModal(!showRecaudoModal);
    setRecaudoSelected(null);
  }

  const toggleModalLote = value => {
    if (value) loadLotes();
    setShowLoteModal(!showLoteModal);
  }

  const handleAnularLote = () => {
    if(!loteSelected) return;
    setShowLoteModal(true);
  }
  
  return (
    <>
      {requesting ? (
        <Row className="py-4 d-flex justify-content-center">
            <Spinner color="primary" />
        </Row>
      ) : (
        <>
          <RecaudoClientModal
            open={showRecaudoModal} 
            data={recaudoSelected}
            toggle={onCloseModal} 
          />
          <RecaudoLoteModal
            open={showLoteModal} 
            data={loteSelected}
            toggle={toggleModalLote}
            recaudos={recaudosLote}
          />
          <LoteInfo
            title="Datos Generales del Lote"
            context={RecaudoContext}
            loteSelected={loteSelected}
            listLotes={listLotes}
            handleCleanLote={handleCleanLote}
            handleChangePag={handleChangePag}
            handleChangeLote={handleChangeLote}
            handleDateRange={handleDateRange}
            showDatePicker
            noButtons />
          <MainTable
            title={!loteSelected ? `Recaudos Registrados` : `Recaudos Registrados - Lote ${loteSelected.label}`}
            data={recaudosLote}
            columns={columns}
            keyField="credito"
            className="px-2"
            loading={loading}
            customActionTitle="Anular Lote"
            handleCustomAction={handleAnularLote}
            noSearch
            noAdd
            customAction
          />
          {/* <div className='card mt-3 w-100 p-4 d-flex flex-row justify-content-between'>
            <ThemeBackground color='info' />
            <DetailRow title="Cantidad" className="mb-1">
              <div className='display-4 fs-4 font-weight-normal text-center text-sans-serif text-info'>
                {numRecaudos}
              </div>
            </DetailRow>
            <DetailRow title="Total a recaudar" className="mb-1">
              <div className='display-4 fs-4 font-weight-normal text-sans-serif text-info'>
                <CountUp end={recaudos} duration={1} prefix="$" separator="." decimal="," decimals={2} />  
              </div>
            </DetailRow>
          </div> */}
        </>
      )}
      
    </>
  )
}

export default RecaudoLote