import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { PreliquidacionContext } from '../PreliquidacionContext';
import { currencyFormat, percentageFormat } from '../../../helpers/tableFormatters';
import CountUp from 'react-countup';

const GeneralConditionsInfo = () => {
  const context = useContext(PreliquidacionContext);
  const { loading, selected, handlePlanModal } = context;

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
        <Row className="text-right fs-3">
          <Col lg={12}>
            <DetailRow textNormal title="Monto Solicitado">{selected.monto ? currencyFormat(selected.monto) : <span className='p-2'></span>}</DetailRow>
            <DetailRow textNormal title="Tasa de Interés">
              {selected.tasa ? percentageFormat(selected.tasa) : <span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Plazo en Meses" childrenClasses="uppercase">
              {selected.plazo || <span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Tipo de Fórmula" childrenClasses="uppercase">
              {selected.nomTipoFormula || <span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Porcentaje Prima" childrenClasses="uppercase">
              {selected.tasaPrima ? percentageFormat(selected.tasaPrima) : <span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Valor Prima" childrenClasses="uppercase">
              {selected.valorPrima ? currencyFormat(selected.valorPrima) :<span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Fecha de Primer Corte" childrenClasses="uppercase">
              {selected.fechaCorte ? selected.fechaCorte :<span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Cuota Mensual" childrenClasses="text-primary fs-5">
              <CountUp end={selected.valorCuota || 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
              <div>
              <a className="font-weight-semi-bold fs--1 text-nowrap cursor-pointer" onClick={() => handlePlanModal(true)}>
                Ver Plan de Pagos
                <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
              </a>
              </div>
            </DetailRow>
          </Col>
        </Row>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default GeneralConditionsInfo;
