//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown,Modal } from 'reactstrap';
import team3 from '../../assets/img/team/3.jpg';
import Avatar from '../common/Avatar';
import PasswordResetModal from '../passwordResetModal/PasswordResetModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [showModal, setShowModal] = useState(false);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onClick={() => setDropdownOpen(!dropdownOpen)}
     /*  onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }} */
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={team3} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
         {/*  <DropdownItem className="font-weight-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="mr-1" />
            <span>Go Pro</span>
          </DropdownItem> */}
          {/* <DropdownItem divider /> */}
          {/* <DropdownItem href="#!">Set status</DropdownItem> */}
          <DropdownItem tag={Link} to="#">
			<i className="fas mr-1 fa-plus text-white">
			  {' '}
              <FontAwesomeIcon icon={faCog} color="gray" />
            </i>
            Cambiar Preferencias
          </DropdownItem>
         {/*  <DropdownItem href="#!">Feedback</DropdownItem> */}
         
          <DropdownItem tag={Link} to="#" onClick={() => setShowModal(true)}>
			<i className="fas mr-1 fa-plus text-white">
			  {' '}
              <FontAwesomeIcon icon={faKey} color="gray" />
            </i>
            Cambiar Contraseña
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/authentication/basic/logout">
			<i className="fas mr-1 fa-plus text-white">
			  {' '}
              <FontAwesomeIcon icon={faSignOutAlt} color="gray" />
            </i>
            Cerrar Sesión
          </DropdownItem>
        </div>
      </DropdownMenu>
      <Modal isOpen={showModal} size="md">
      <PasswordResetModal setShowModal={setShowModal}/>
      </Modal>
    </Dropdown>
  );
};

export default ProfileDropdown;
