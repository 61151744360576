import React, { Fragment } from 'react';
import WizardInput from './WizardInput';
import {
  Card,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Table,
  CustomInput,
  Form,
  Col,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import ContactListComponent from '../TablaFormPropiedades';

const options = ['49 - No responsable de Iva', '22 - Obligado a cumplir deberes formales'];

class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: '',
      ListaActividades: [],
      DataGrilla: []
    };
  }

  componentDidMount = () => {
    this.ConsultarActvidadesEconomicas();
    this.ConsultarResponsabilidades();
    if(this.props.ArrayClasificacion){
      this.setState(state => ({
        ...state,
        DataGrilla: this.props.ArrayClasificacion
      }));
    }
  };
  cambiarselect = data => {
    let value = data.target.value;
    this.setState(state => ({
      ...state,
      SelectTipoDocumento: value
    }));
  };

  cambiarselectActividadPrincipal = data2 => {
    let dataactividadprincipal = { value: data2.value, label: data2.label };
    this.props.setValue("ActividadPrincipal", dataactividadprincipal);
    this.setState(state => ({
      ...state,
      ActividadPrincipal: dataactividadprincipal
    }));
  };

  cambiarselectActividadSecundaria = data3 => {
    let dataactividadsecundaria = { value: data3.value, label: data3.label };
    this.props.setValue("ActividadSecundaria", dataactividadsecundaria);
    this.setState(state => ({
      ...state,
      ActividadSecundaria: dataactividadsecundaria
    }));
  };

  cambiarselectResponsabilidades = data4 => {
    let dataresponsabilidades = { value: data4.value, label: data4.label };
    this.setState(state => ({
      ...state,
      Responsabilidades: dataresponsabilidades
    }));
  };
  ConsultarResponsabilidades = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    fetch(urlApi + '/responsabilidad/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaResponsabilidades: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  ConsultarActvidadesEconomicas = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    fetch(urlApi + '/actividad/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaActividades: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  quitarCampo = data => {
    for (let i = 0; i < this.state.DataGrilla.length; i++) {
      if (data.IdResponsabilidad === this.state.DataGrilla[i].IdResponsabilidad) {
        this.state.DataGrilla.splice(i, 1);
        break;
      }
    }
    this.setState(state => ({
      ...state,
      arrayList2: this.state.arrayList2
    }));
  };

  agregarCampo = () => {
    if (this.state.Responsabilidades) {
      let ObjRegistro = {};
      let EstadoID = false;
      ObjRegistro.Responsabilidad = this.state.Responsabilidades.label;
      ObjRegistro.IdResponsabilidad = this.state.Responsabilidades.value;
      if (this.state.DataGrilla.length > 0) {
        for (let i = 0; i < this.state.DataGrilla.length; i++) {
          if (this.state.DataGrilla[i].IdResponsabilidad == this.state.Responsabilidades.value) {
            this.state.DataGrilla[i].Responsabilidad = this.state.Responsabilidades.label;
            EstadoID = true;
          }
        }
      } else {
        this.setState(state => ({
          ...state,
          DataGrilla: [ObjRegistro]
        }));
       this.props.agregarcampo2(this.state.DataGrilla)
      }
      if (EstadoID == false) {
        let ObjRegistro2 = {};
        ObjRegistro2.Responsabilidad = this.state.Responsabilidades.label;
        ObjRegistro2.IdResponsabilidad = this.state.Responsabilidades.value;

        this.state.DataGrilla.push(ObjRegistro2);
        this.setState(state => ({
          ...state,
          DataGrilla: this.state.DataGrilla
        }));
        this.props.agregarcampo2(this.state.DataGrilla)

        // this.limpiar();

        // this.limpiar();
      }
    }
  };

  render() {
    return (
      <>
        <Fragment>
          <Row form>
            <Col>
              <label>Actividad Principal*</label>
              <Select
                className="z7"
                style="fullWidth-select"
                name="ActividadPrincipal"
                onChange={this.cambiarselectActividadPrincipal}
                value={this.state.ActividadPrincipal}
                options={this.state.ListaActividades}
                {...this.props.register("ActividadPrincipal")}
              />
            </Col>

            <Col>
              <label>Actividad Secundaria*</label>
              <Select
                className="z7"
                id="ActividadSecundaria"
                style="fullWidth-select"
                name="ActividadSecundaria"
                onChange={this.cambiarselectActividadSecundaria}
                value={this.state.ActividadSecundaria}
                options={this.state.ListaActividades}
                {...this.props.register("ActividadSecundaria")}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Responsabilidades*</label>
              <Select
                style="fullWidth-select"
                name="Responsabilidades"
                onChange={this.cambiarselectResponsabilidades}
                value={this.state.Responsabilidades}
                options={this.state.ListaResponsabilidades}
                {...this.props.register("Responsabilidades")}
              />
            </Col>

            <Col>
              <Label>Clasificacion</Label>
              <div style={{ border: 'outset', borderRadius: '10px' }}>
                <div className="contact-table">
                  <ContactListComponent
                    panel={this.props.panel}
                    searchInput={this.props.searchInput}
                    contactlists={this.state.DataGrilla}
                    headerLista={['', 'Responsabilidad', '']}
                    bodyLista={['Responsabilidad', '']}
                    quitarCampo={data => this.quitarCampo(data)}
                    NombreMaestro={'Maestro Registro Glosas'}
                    Acciones={true}
                    Consultar={data => this.Consultar(data)}
                    selectValue={data => this.selectValue(data)}
                    handleSearch={data => this.handleSearch(data)}
                    deleteSelected={() => this.deleteSelected}
                    actiononContact={(action, data) => this.actiononContact(action, data)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col />
          </Row>
        </Fragment>
        <div>
          <Button onClick={this.agregarCampo}>
            <i className="mr-1">
              {' '}
              <FontAwesomeIcon icon={faPlus} />
            </i>
            Agregar
          </Button>
        </div>
      </>
    );
  }
}

export default BasicUserForm;
