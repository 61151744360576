import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import { refFormat } from '../../../helpers/tableFormatters';
import ThemeBackground from '../../../components/common/ThemeBackground';
import { getTodayStr } from '../../../helpers/dateFormatters';
import Flatpickr  from 'react-flatpickr';
import { getLocaleCalendarConfig } from '../../../helpers/utils';

const CreditInfo = () => {
  const today = new Date()
  const context = useContext(DesembolsoContext);
  const { selected, desembolsoDate, handleDesembolsoDate } = context;

  return (
    <Card className={`my-3`} >
      <ThemeBackground color='info' />
      <FalconCardHeader title="Información del Crédito"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {selected && (
        <Row>
          <Col lg={4} xs={4}>
            <DetailRow title="Nro. de Solicitud">{selected.idSolicitud ? refFormat(selected.idSolicitud) : <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Línea de Crédito">{selected.nomLinea || <span className='p-2'></span>}</DetailRow>
          </Col>

          <Col lg={4} xs={4}>
            <DetailRow title="Número de Crédito">{selected.idCredito || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Sublínea de Crédito">{selected.nomSubLinea || <span className='p-2'></span>}</DetailRow>
          </Col>

          <Col lg={4} xs={4} className="text-right">
            <DetailRow title="Fecha de Desembolso">
              {/* {getTodayStr() || <span className='p-2'></span>} */}
              <Flatpickr
                className="form-control text-right"
                placeholder='aaaa/mm/dd'
                name="first_pay"
                value={desembolsoDate}
                id="first_pay"
                options={{
                  dateFormat: "Y/m/d",
                  maxDate: today,
                  locale: getLocaleCalendarConfig(),
                }}
                onChange={(date, dateStr) => {
                  handleDesembolsoDate(dateStr.replaceAll("/", "-"))
                }}
              />
            </DetailRow>
            <DetailRow title="Modalidad de Crédito">{selected.nomModalidad || <span className='p-2'></span>}</DetailRow>
          </Col>

        </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default CreditInfo;
