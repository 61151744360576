import React, { Fragment, useState, useEffect } from 'react';
import { Modal , ModalHeader } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';
import PageHeader from '../../components/common/PageHeader'
import MainTable from '../../components/tables/MainTable';
import PagaduriasResumen from './PagaduriasResumen';
import AdvisersForm from './AdvisersForm';
import { advisersServices } from '../../services';
import { authSesion } from "../../helpers/authSesion";

const AdvisersPage = () => {
  const [ loading, setLoading ] = useState();
  const [ selected, setSelected ] = useState();
  const [ editing, setEditing ] = useState();
  const [ listData, setListData ] = useState([]);
  const [currentSearch, setCurrentSearch] = useState("");


  useEffect(() => {
    load();
  }, [])

  const columns = [
    {
        dataField: 'nombre',
        text: 'Nombre del Asesor',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
        dataField: 'identificacion',
        text: 'Identificación',
        headerClasses: 'border-0',
        formatter: (value, selected) => fttrIdentification(selected),
        classes: 'border-0 py-2 align-middle',
    },
    {
        dataField: 'pagaduriaPpal',
        text: 'Pagaduría Principal',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
    },
    {
        dataField: '',
        headerClasses: 'border-0',
        text: '',
        formatter: (estado, selected) => fttrPagaduriasResumenBtn(selected),
        classes: 'border-0 py-2 align-middle text-left',
    },
    {
      dataField: '',
      headerClasses: 'border-0',
      text: 'Editar',
      classes: 'border-0 py-2 align-middle',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(selected),
      align: 'center',
      headerAlign: 'center'
  },
  ];

  const fttrIdentification = (adviser) => {
    return (
      <div>{`${adviser.tipoIdentificacion}. ${adviser.identificacion}`}</div>
    )
  }

  const fttrPagaduriasResumenBtn = (adviser) => {
    return <PagaduriasResumen 
            key={adviser.idAsesor} 
            selected={adviser}
            handleChange={handleChangeMainPagaduria}
            /> 
  }

  const actionsFormatter = (adviser) => {
    if(!adviser) return <></>
    return (
      <div>
        <Tooltip title="Editar">
          <IconButton
            onClick={() => {
              setSelected(adviser)
              setEditing(true);
            }}
          >
            <FontAwesomeIcon
              style={{ width: '20px' }}
              icon={faEdit}
              color="SteelBlue"
            />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const load = async () => {
    setLoading(true);
    const response = await advisersServices.getAdvisers(currentSearch)

    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    setListData(response.objResponse);
    setLoading(false);
  }

  const handleSearch = async keyword => {
    setCurrentSearch(keyword);

    const response = await advisersServices.getAdvisers(keyword)

    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    setListData(response.objResponse);
  }

  const handleSaveEdit = async (idSelected, pagaduriasSelected) => {
    const reqBody = {}
    reqBody.sesion = authSesion();
    reqBody.idAsesor = idSelected;
    reqBody.asesorPagaduria = pagaduriasSelected;
    
    const response = await advisersServices.updateAdviser(reqBody);
    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la edicion' });
    } else {
      cogoToast.success(response.msg, { position: 'bottom-right', heading: 'Asesor actualizado' });
    }

    setEditing(false);
    load();
  }

  const handleChangeMainPagaduria = async (idMainPag, idAdviser) => {
    const reqBody = {}
    reqBody.sesion = authSesion();
    reqBody.idAsesor = idAdviser;
    reqBody.idPagaduria = idMainPag;

    const response = await advisersServices.updateMainPagAdviser(reqBody);
    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la edicion' });
    } else {
      cogoToast.success(response.msg, { position: 'bottom-right', heading: 'Asesor actualizado' });
    }
    load();
  }

  return (
    <Fragment>
      <Modal 
        isOpen={editing}
        size="md" 
        toggle={() => setEditing(false)}>
        <ModalHeader>
            <div className="row pt-3 ">
                <div className="flex col-md-12">
                <h3>Editar Asesor</h3>
                </div>
            </div>
        </ModalHeader>
        <AdvisersForm 
          adviser={selected} 
          handleCancel={() => setEditing(false)} 
          handleSaveEdit={handleSaveEdit}
        /> 
      </Modal>
      <PageHeader
        title="Asesores"
        className="mb-3"
      />
      <MainTable 
        data={listData} 
        columns={columns} 
        keyField="identificacion"
        search={handleSearch}
        className="px-2"
        loading={loading}
        noAdd
      />
    </Fragment>
  )
}

export default AdvisersPage
