import React from 'react';
import { Card, Input, Button, ModalBody, ModalHeader, ModalFooter, Label, Table, CustomInput, Form } from 'reactstrap';
import { urlApi } from '../../configuracion/serverConfig';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';

class GestionPerfilesForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: '',
      Estado: true
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoPerfil: true,
      permisos: [],
      nombrePerfil: ''
    };
  }

  isItemSelected = item => this.state.permisos.findIndex(elem => elem.tarea === item.tarea) !== -1;

  //obtener tareas(permisos) del perfil seleccionado
  getTareas(sesion, idPerfil) {
    return new Promise((resolve, reject) => {
      fetch(urlApi + '/perfil/id/' + sesion + '/' + idPerfil, {
        method: 'GET',
        body: JSON.stringify(),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            resolve(data.objResponse[0].tareas);
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })
        .catch(err => {
          console.log('err', err);
          reject();
        });
    });
  }

  async componentDidMount() {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    for (var key in this.props.data) {
      let value = this.props.data[key];
      this.state[key] = value;
    }

    if (this.props.data.idPerfil) {
      this.state.permisos = await this.getTareas(sesion, this.props.data.idPerfil);
      this.state.nombrePerfil = this.props.data.nombre;
      let EstadoPerfilTemporal = false;

      if (this.props.data.estado === '1') {
        EstadoPerfilTemporal = true;
      } else if (this.props.data.estado === '0') {
        EstadoPerfilTemporal = false;
      }
      this.setState(state => ({
        ...state,
        EstadoPerfil: EstadoPerfilTemporal
      }));
    }

    //obtener todas las tareas(permisos)
    fetch(urlApi + '/tarea/' + sesion, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListData: data.objResponse
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));

    // const res = axios.get(gsUrlApi +'/soportesFacturas/listar/'+sIdEmpresa);
  }

  MostarAlerta = data => {
    confirmAlert({
      title: 'Registro Guardado',
      message: 'Se ha guardado correctamente el registro',
      buttons: [
        {
          label: 'ACEPTAR',
          onClick: this.props.MostrarFormulario('Guardado')
        }
      ]
    });
  };

  agregar = e => {
    e.preventDefault();
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    let objPerfil = {};
    objPerfil.sesion = sesion;
    objPerfil.idPerfil = 0;
    objPerfil.nombre = this.state.nombrePerfil;
    objPerfil.estado = this.state.EstadoPerfil;
    objPerfil.tareas = this.state.permisos.map(item => {
      return { tarea: item.tarea };
    });
    if (objPerfil.estado === true) {
      objPerfil.estado = '1';
    } else if (objPerfil.estado === false) {
      objPerfil.estado = '0';
    }

    if (this.props.data.idPerfil) {
      objPerfil.idPerfil = this.props.data.idPerfil;
      fetch(urlApi + '/perfil', {
        method: 'PUT',
        body: JSON.stringify(objPerfil),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            cogoToast.success("El perfil fue actualizado correctamente", { position: 'bottom-right', heading: 'Actualizado' });
            this.props.MostrarFormulario('Guardado');
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })
        .catch(err => console.log('err', err));
    } else {
      fetch(urlApi + '/perfil', {
        method: 'POST',
        body: JSON.stringify(objPerfil),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            cogoToast.success("El perfil fue agregado correctamente", { position: 'bottom-right', heading: 'Agregado' });
            this.props.MostrarFormulario('Guardado');
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })

        .catch(err => console.log('err', err));
    }
  };

  CheckedEstado = () => {
    this.setState({ EstadoPerfil: !this.state.EstadoPerfil });
  };

  onCheck = (e, item) => {
    //e.target.value = !e.target.value;
    if (e.target.checked) {
      let permiso = {
        tarea: item.tarea
      };
      this.state.permisos.push(permiso);
    } else {
      let encontrado = this.state.permisos.findIndex(elem => elem.tarea === item.tarea);
      if (encontrado !== -1) {
        //si encuentra elimina
        this.state.permisos.splice(encontrado, 1);
      }
    }
    this.setState(state => ({
      ...state,
      permisos: this.state.permisos
    }));
  };

  checkAll = (e) => {
    if (e.target.checked) {
      let permisosTemporal = [];
			this.state.ListData.map(item => {
				let encontrado = this.state.permisos.findIndex(elem => elem.tarea === item.tarea);
				if (encontrado === -1) {
          //si no encuentra agrega
          permisosTemporal = this.state.permisos;
					permisosTemporal.push(item);
				}
			});

      this.setState(state => ({
        ...state,
        permisos: permisosTemporal
      }));
		} else {
      this.setState(state => ({
        ...state,
        permisos: []
      }));
		}

  }

  onInputchange = e => {
    if (e) {
      let name = e.target.name;
      let value = e.target.value;
      this.setState(state => ({
        ...state,
        [name]: value
      }));
    }
  };

  render() {
    return (
      <>
        <div>
          <Card>
            <div className="right-panel roe-box-shadow">
              <div className="">
              <Form onSubmit={this.agregar}>
                <ModalBody>
                  <div className="form-group">
                    <div className="row pt-3 pb-3 ">
                      <div className="flex col-md-12">
                        <Label for="exampleName">Nombre del Perfil</Label>
                        <Input type="text" name="nombrePerfil" value={this.state.nombrePerfil} onChange={this.onInputchange} id="nombre" />
                      </div>
                    </div>
                    <Label>Seleccionar los permisos otorgados al perfil</Label>
                    <div style={{ border: 'outset' }}>
                      <div className="contact-table">
                        {this.state.ListData && this.state.ListData.length ? (
                          <Table borderless hover className="mb-0">
                            <thead className="thead-light">
                              <tr>
                                <th style={{ 'text-align': 'left' }}>
                                <input onChange={(e) => this.checkAll(e)} checked={this.state.permisos.length === this.state.ListData.length} type="checkbox" />
                                </th>
                                <th>Nombre</th>
                              </tr>
                            </thead>
                            <tbody>
                        
                              {this.state.ListData.map((item, key) => {
                                return (
                                  <tr key={key}>
                                     <td style={{ 'text-align': 'left' }}>
                                       <input onChange={e => this.onCheck(e, item)} checked={this.isItemSelected(item)} type="checkbox" />
                                       </td>
                                    <td>
                                      <div>{item.nombre}</div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          <div className="text-center no-found-message">No Roles Found....</div>
                        )}
                      </div>
                    </div>
                    
                    </div>
                  </ModalBody>
                  <ModalFooter className="no-border">
                    <div className="row col-md-12">
                      <div className="col-md-2 text-left">
                          <CustomInput
                            type="switch"
                            name="estado"
                            id="exampleCheck1"
                            checked={this.state.EstadoPerfil}
                            onChange={() => this.CheckedEstado()}
                          />

                          <Label for="exampleCheck1" check>
                            Activo
                          </Label>
                      </div>

                    <div className="col-md-10 text-right">
                      <Button
                        className="mr-1" color="primary" size="sm" outline
						            style={{'height': '36px'}}
                        type="submit"
                      >
						              <i className="mr-1">
                          {' '}
                          <FontAwesomeIcon icon={faSave} />
                        </i>
                        Guardar
                      </Button>
                      &nbsp; &nbsp;

                      <Button
                        className="mr-1" color="primary" size="sm" outline
                        style={{'height': '36px'}}
                        onClick={e => this.props.MostrarFormulario('Cancelar')}
                      >
						              <i className="mr-1">
                          {' '}
                          <FontAwesomeIcon icon={faUndo} />
                        </i>
                        Cancelar
                      </Button>
                    </div>
                    </div>
                  </ModalFooter>

                </Form>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
export default GestionPerfilesForm;
