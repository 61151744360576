import React from 'react';
import PropTypes from 'prop-types';
import Background from './Background';
import corner1 from '../../assets/img/illustrations/corner-1.png';
import corner2 from '../../assets/img/illustrations/corner-2.png';
import corner3 from '../../assets/img/illustrations/corner-3.png';
import corner4 from '../../assets/img/illustrations/corner-4.png';

const getImage = color => {
  switch (color) {
    case 'warning':
      return corner1;
    case 'info':
      return corner2;
    case 'success':
      return corner3;
    default:
      return corner4;
  }
};

const ThemeBackground = ({ color, style }) => {

  return (
    <Background image={getImage(color)} className="bg-card" style={style} />
  );
};

ThemeBackground.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ThemeBackground;
