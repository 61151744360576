import React, { useState, useEffect, useRef, Fragment } from 'react';
import Spinner from 'reactstrap/lib/Spinner';
import cogoToast from 'cogo-toast';
import { SolicitudContext } from './SolicitudContext';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import PageHeader from '../../components/common/PageHeader';
import SolicitudContent from './SolicitudContent';
import SolicitudAside from './SolicitudAside';
import ModalSearchClient from './ModalSearchClient';
import DeniedCard from './contents/DeniedCard';
import { advisersServices } from '../../services/advisers.services'; 
import { getItemFromStore } from '../../helpers/utils';
import { solicitudServices } from '../../services';
import { getTodayStr } from '../../helpers/dateFormatters';

const SolicitudPage = () => {
  const [showModal, setShowModal] = useState();
  const [loading, setLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState();
  const [client, setClient] = useState();
  const [formCreditData, setFormCreditData] = useState();
  const [formCreditInfo, setFormCreditInfo] = useState();
  const [formRefData, setFormRefData] = useState();
  const [selectedPagaduria, setSelectedPagaduria] = useState();
  const [cutDate, setCutDate] = useState();
  const [date, setDate] = useState(getTodayStr());

  const [creditLinesOptions, setCreditLinesOptions] = useState([]);
  const [creditSublinesOptions, setCreditSublinesOptions] = useState([]);
  

  useEffect(() => {
    getCurrentUser();
    getCreditLines();
  }, [])

  const getCurrentUser = async () => {
    const sesion = await getItemFromStore('Usuario', '', sessionStorage);
    const { identificacion } = sesion;

    const response = await advisersServices.getAdviserById(identificacion);
    if(!response || response.error){
      setLoading(false);
      return;
    }
    setCurrentUser(response.objResponse[0]);
    setLoading(false);
  }

  const getCreditLines = async (id) => {
    const response = await solicitudServices.getCreditLines(id);
    if(!response || response.error){
      return;
    }
    setCreditLinesOptions(response.objResponse);
  }

  const loadCreditSublines = async (creditLineId) => {
    const response = await solicitudServices.getCreditSublines(creditLineId);
    if(!response || response.error){
      return;
    }
    setCreditSublinesOptions(response.objResponse);
  }


  const onSelectClient = (value) => {
    setClient(value);
    setShowModal(false);
  }

  const onOpenModal = () => {
    setShowModal(true);
  }

  const handleCreditInfoForm = data => {
    setFormCreditInfo(data);
  }

  const handleCreditForm = (values) => {
    setFormCreditData(values);
  }

  const handleCutDate = (date) => {
    setCutDate(date);
  }

  const handleFormDate = (date) => {
    setDate(date);
  }

  const onSelectPagaduria = value => {
    setSelectedPagaduria(value);
  }

  const handleFormRef = data => {
    setFormRefData(data);
  }
 
  const onSave = async () => {
    if(
      !currentUser ||
      !client ||
      !formCreditInfo ||
      !formCreditData ||
      !selectedPagaduria ||
      !formRefData ||
      !cutDate
      ){
        cogoToast.error("Por favor completa toda la información requerida", 
        { position: 'bottom-left', heading: 'Campos requeridos faltantes.' });
        return;
      }
      setLoading(true);
      const today = getTodayStr();

      const payload = {
        credito: formCreditInfo.creditNumber,
        fecha: date || today,
        idPagaduria: selectedPagaduria,
        idLinea: formCreditInfo.creditLine,
        idSubLinea: formCreditInfo.creditSubline,
        idCliente: client.id,
        idAsesor: currentUser.idAsesor,
        comision: 0,
        monto: formCreditData.amount,
        plazo: formCreditData.term,
        tasa: formCreditData.rate,
        tipoFormula: formCreditData.type,
        tasaPrima: formCreditData.bonus,
        fechaCorte: cutDate,
        valorCuota: formCreditData.cuota,
        estado: 1,
        idModalidad: 0,
        refSolicitud: {
          identificacion: formRefData.identificacion,
          nombre: formRefData.fullName,
          domicilio: formRefData.address,
          telefono: formRefData.phone,
          correo: formRefData.email || "",
        }
      }
      const response = await solicitudServices.createSolicitud(payload);
      if(!response || response.error){
        resetData();
        setLoading(false);
        cogoToast.error(response.msg, 
        { position: 'bottom-left', heading: 'Error en solicitud' });
        return;
      };
      resetData();
      setLoading(false);
      cogoToast.success("Su solicitud ha sido creada con éxito", 
        { position: 'bottom-left', heading: 'Solicitd Creada' });
  }

  const resetData = () => {
    setClient(null);
    setFormCreditData(null);
    setFormCreditInfo(null);
    setFormRefData(null);
    setSelectedPagaduria(null);
    setCutDate(null);
  }

  return (
    <SolicitudContext.Provider value={{
      adviser: currentUser,
      creditLinesOptions,
      creditSublinesOptions,
      client,
      date,
      onOpenModal,
      onSelectClient,
      handleCreditForm,
      handleCutDate,
      handleCreditInfoForm,
      handleFormRef,
      handleFormDate,
      onSelectPagaduria,
      onSave,
      loadCreditSublines
    }}>
    <Fragment>
      <ModalSearchClient show={showModal} toggle={() => setShowModal(false)} />  
      <PageHeader title="Solicitud de Crédito" />
      {loading ? (
        <div className='container-fluid text-center p-4'>
          <Spinner color="primary" />
        </div>
      ) : (
        currentUser ? (
        <ContentWithAsideLayout aside={<SolicitudAside />} isStickyAside={false}>
          <SolicitudContent />
        </ContentWithAsideLayout>
        ) : (
          <DeniedCard />
        )
      )}
    </Fragment>
    </SolicitudContext.Provider>
  );
};

export default SolicitudPage
