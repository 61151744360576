import React from 'react'
import GestionUsuarioEmpresa from './GestionPersonaEmpresa';
import { PersonFilterProvider } from "./context";

function index() {
    return (
        <PersonFilterProvider>
            <GestionUsuarioEmpresa />
        </PersonFilterProvider>
    )
}

export default index
