import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';


const DeniedCard = () => (
  <Card className="text-center m-4">
    <CardBody className="p-5">

      <div className="display-1 text-200 fs-error">
        <FontAwesomeIcon icon="exclamation" className="mr-2" />
      </div>
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
        No cuenta con el rol adecuado para esta funcionalidad.
      </p>
      <hr />
      <p>
      Por favor asegúrese de contar con los permisos adecuados para realizar esta funcionalidad
      </p>
      <Link className="btn btn-primary btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        Regresar a la página Principal
      </Link>
    </CardBody>
  </Card>
);

export default DeniedCard;
