import React, { useContext, useState, forwardRef } from 'react';
import {
  Input,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { SolicitudContext } from '../SolicitudContext';
import Flatpickr  from 'react-flatpickr';
import { getLocaleCalendarConfig } from '../../../helpers/utils'

const GeneralDataAdviser = () => {
  const context = useContext(SolicitudContext);
  const { adviser, date, handleFormDate } = context;

  const today = new Date();

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Datos Generales del Asesor de Crédito">
        <div className='mx-2' style={{minWidth: '250px'}}>
          <FormGroup>
            <strong>Fecha Solicitud: </strong>
              <Flatpickr
                className="form-control text-right pr-2"
                placeholder='aaaa/mm/dd'
                value={date}
                options={{
                  dateFormat: "Y/m/d",
                  maxDate: today,
                  locale: getLocaleCalendarConfig(),
                }}
                onChange={(date, dateStr) => {
                  handleFormDate(dateStr.replaceAll("/", "-"))
                }}
              />
          </FormGroup>
        </div>
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {adviser && (
        <Row>
          <Col lg className="col-xxl-5">
            <DetailRow title="Identificación">{`${adviser.tipoIdentificacion} ${adviser.identificacion}` || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Nombres y Apellidos">{adviser.nombre || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            <DetailRow title="Telefono">{adviser.telefono || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Correo">{adviser.correo || <span className='p-2'></span>}</DetailRow>
          </Col> 
        </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default GeneralDataAdviser;
