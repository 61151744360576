import React, {
	useReducer,
	createContext,
	useMemo,
	FC,
	useContext
} from "react";

const SAVE_FILTER_STATE = "SAVE_FILTER_RESULTS";
const SAVE_FILTER_ROLES = "SAVE_FLIGHT_SEARCH";
const SAVE_METADATA_FILTERS = "SAVE_METADATA_FILTERS";

export const PersonFilterContext = createContext(null);
PersonFilterContext.displayName = "PersonFilterContext";

function personFilterReducer(state, action) {
	switch (action.type) {
		case SAVE_FILTER_STATE:
			return { ...state, stateFilter: action.value };

		case SAVE_FILTER_ROLES:
			return { ...state, rolesFilter: action.value };
		
		case SAVE_METADATA_FILTERS:
				return { ...state, metadataFilters: action.value };
			
		default:
			return state;
	}
}

export const PersonFilterProvider = (props) => {
	const [state, dispatch] = useReducer(personFilterReducer, {stateFilter: "1,0", rolesFilter: "*"});

	const saveFilterState = (value) =>
		dispatch({ type: SAVE_FILTER_STATE, value });
	const saveFilterRoles = (value) =>
		dispatch({ type: SAVE_FILTER_ROLES, value });
	const saveFiltersMetadata = (value) =>
		dispatch({ type: SAVE_METADATA_FILTERS, value });

	const value = useMemo(
		() => ({
			...state,
			saveFilterState,
			saveFilterRoles,
			saveFiltersMetadata
		}),
		[state]
	);

	return <PersonFilterContext.Provider value={value} {...props} />;
};

export const usePersonFilter = () => {
	const context = useContext(PersonFilterContext);
	if (context == undefined)
		throw new Error(
			`usePersonFilter debe ser usado con un PersonFilterProvider`
		);

	return context;
};
