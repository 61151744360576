import React, { useState, useEffect, Fragment } from 'react';
import WizardInput from '../../auth/wizard/WizardInput';
import { Col, CustomInput, Row } from 'reactstrap';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
const options = ['CC', 'NIT'];

const FormLocation = (props) => {
  const [initialData, setInitialData] = useState({
    Codigo: '',
    Procedimiento: ''
  });
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialData);
  const [EstadoUsuario, setEstadoUsuario] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [ListaPaises, setListaPaises] = useState([]);
  const [Listadpto, setListadpto] = useState([]);
  const [ListaCiudad, setListaCiudad] = useState([]);

  const [paisresidencia, setPaisresidencia] = useState();
  const [departamentoresidencia, setDepartamentoresidencia] = useState();
  const [ciudadmunicipioresidencia, setCiudadmunicipioresidencia] = useState();

  
  useEffect(() => {
    if (props.PaisResidencia) {
      setPaisresidencia(props.PaisResidencia.value);
    }
    if (props.DepartamentoResidencia) {
      ConsultarDept({id: props.PaisResidencia})
      setDepartamentoresidencia(Number(props.DepartamentoResidencia.value));
    }
    if (props.CiudadResidencia) {
      Consultarciudad(props.DepartamentoResidencia)
      setCiudadmunicipioresidencia(Number(props.CiudadResidencia.value));
    }
  }, []);

  useEffect(() => {
    if(!props.PaisResidencia || !props.editing) return;
    ConsultarDept({identificador: props.extraInfo.terceroUbicacion.infoPaisRes.split('|')[0]})
    Consultarciudad({identificador: props.extraInfo.terceroUbicacion.infoDepartamentoRes.split('|')[0]})
  }, [props.PaisResidencia])

  const cambiarselectpais = data2 => {
    
    var paises = props.countries;
    let objectPaises = {};
    for (let i = 0; i < paises.length; i++) {
      if (Number(data2) === paises[i].value) {
        objectPaises = paises[i]; 
      }    
    }
    setPaisresidencia(objectPaises.value);
    ConsultarDept(objectPaises);
    props.cambiarselectpais3(objectPaises.value);
  };


  const cambiarselectciudad = data4 => {

    var ciudades = ListaCiudad;
    let objectCiudades = {};
    for (let i = 0; i < ciudades.length; i++) {
      if (Number(data4) === Number(ciudades[i].value)) {
        objectCiudades = ciudades[i]; 
      }    
    }
    setCiudadmunicipioresidencia(objectCiudades.value);
    props.cambiarselectciudad3(objectCiudades.value)
  }

  const cambiarselectdepartamento = data3 => {
    var departamentos = Listadpto;
    let objectDepartamentos = {};
    for (let i = 0; i < departamentos.length; i++) {
      if (Number(data3) === Number(departamentos[i].value)) {
        objectDepartamentos = departamentos[i]; 
      }    
    }
    setDepartamentoresidencia(objectDepartamentos.value);
    Consultarciudad(objectDepartamentos);
    props.cambiarselectdepartamento3(objectDepartamentos)
  }

  const ConsultarDept = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    if(!data.identificador){
      data.identificador = props.countries.filter(p => p.value === data.id)[0].identificador
    }

    fetch(urlApi + '/dpto/pais/' + sesion + '/' + data.identificador, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          setListadpto(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const Consultarciudad = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/municipio/dpto/' + sesion + '/' + data.identificador + '/1/100/', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].estado;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          setListaCiudad(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  return (
    <>
      <Fragment>
        <Row form>
        <Col>
        <label>Pais de Residencia*</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="z7"
                  id="paisresidencia"
                  placeholder="Seleccionar"
                  name="paisresidencia"
                  onChange={(input) => cambiarselectpais(input.target.value)}
                  value={props.PaisResidencia}
                  options={props.countries}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              </Col>
          <Col>
          <label>Departamento de Residencia*</label>
          <WizardInput
                type="select"
                tag={CustomInput}
                  className="z7"
                  id="departamentoresidencia"
                  placeholder="Seleccionar"
                  name="departamentoresidencia"
                  onChange={(input) => cambiarselectdepartamento(input.target.value)}
                  value={props.DepartamentoResidencia?.value}
                  options={Listadpto}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
                
            
          </Col>
        </Row>
        <Row form>
          <Col>
          <label>Ciudad / Municipio de Residencia*</label>
          <WizardInput
                type="select"
                tag={CustomInput}
                  className="superponer2"
                  id="ciudadmunicipioresidencia"
                  placeholder="Seleccionar"
                  name="ciudadmunicipioresidencia"
                  onChange={(input) => cambiarselectciudad(input.target.value)}
                  value={props.CiudadResidencia}
                  options={ListaCiudad}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              
          
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Dirección de Residencia*"
              name="DireccionResidencia"
              id="DireccionResidencia"
              innerRef={props.register({
                required: "requerido",
              })}
              errors={props.errors}
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <WizardInput
              type="email"
              label="Correo Electrónico"
              name="CorreoElectronico"
              id="CorreoElectronico"
              innerRef={props.register({
                required: false,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
              })}
              errors={props.errors}
              customError={
                props.errors['CorreoElectronico'] && 
                props.errors['CorreoElectronico'].type === "pattern" 
                  ? "Por favor escriba un correo electrónico válido."
                  : false 
              }
            />
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Telefono Celular*"
              name="TelefonoCelular"
              id="TelefonoCelular"
              innerRef={props.register({
                required: 'requerido',
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                validate: value => value.length === 10 || value.length === 0
              })}
              errors={props.errors}
              customError={
                props.errors['TelefonoCelular'] && 
                props.errors['TelefonoCelular'].type !== "required" 
                  ? "Por favor escriba un telefono válido."
                  : false 
              }
            />
          </Col>
        </Row>
        <Row form>
          <Col>
            <WizardInput
              type="text"
              label="Teléfono Fijo"
              name="TelefonoFijo"
              id="TelefonoFijo"
              innerRef={props.register({
                required: false,
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                validate: value => value.length === 10 || value.length === 0
              })}
              errors={props.errors}
              customError={
                props.errors['TelefonoFijo'] && 
                props.errors['TelefonoFijo'].type !== "required" 
                  ? "Por favor escriba un telefono válido."
                  : false 
              }
            />
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Télefono Alterno"
              name="TelefonoAlterno"
              id="TelefonoAlterno"
              innerRef={props.register({
                required: false,
                pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                validate: value => value.length === 10 || value.length === 0
              })}
              errors={props.errors}
              customError={
                props.errors['TelefonoAlterno'] && 
                props.errors['TelefonoAlterno'].type !== "required" 
                  ? "Por favor escriba un telefono válido."
                  : false 
              }
            />
          </Col>
        </Row>
      
      
      </Fragment>
    </>
  );
}

export default FormLocation;