import React, { useContext } from 'react';
import { Row, Spinner } from 'reactstrap';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CreditInfo from './sections/CreditInfo';
import ClientInfo from './sections/ClientInfo';
import { DesembolsoContext } from './DesembolsoContext';
import AsideLayout from './sections/AsideLayout'
import Deductions from './sections/Deductions';
import CashSection from './sections/CashSection';
import WireTransferSection from './sections/WireTransferSection';
import ChequeSection from './sections/ChequeSection';

const PrestamoDetails = () => {
  const context = useContext(DesembolsoContext);
  const { loading } = context;
  
  if(loading){
    return (
      <Row className="py-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </Row>
    )
  }

  return (
    <ContentWithAsideLayout aside={<AsideLayout></AsideLayout>} isStickyAside={false}>
        <CreditInfo />
        <ClientInfo />
        <Deductions />
        <CashSection />
        <WireTransferSection />
        <ChequeSection />
    </ContentWithAsideLayout>
  )
}

export default PrestamoDetails