import React, { useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CustomInput from 'reactstrap/lib/CustomInput';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { PreliquidacionContext } from '../PreliquidacionContext';
import { refFormat } from '../../../helpers/tableFormatters';
import ThemeBackground from '../../../components/common/ThemeBackground';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import CreditSelectModal from '../CreditSelectModal';
import { CREDIT_MODE, CREDIT_MODE_ID, MODAL_MODE, MODAL_VIEW } from '../../../data/constans';
import creditModalities from '../../../data/preliquidacion/creditModalities';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { currencyFormat } from '../../../helpers/tableFormatters';

const CreditInfo = () => {
  const context = useContext(PreliquidacionContext);
  const { register, triggerValidation, errors, watch, setValue, getValues } = useForm();
  const { 
    loading, 
    liquidacion,
    selected, 
    updateBalance,
    unificationBalance,
    selectedCredits,
    handleCreditInfoForm, 
    onChangeCreditMode, 
    onSelecCreditModal } = context;

  const [showSelectCreditModal, setShowSelectCreditModal] = useState();

  const watchCreditMode = watch("creditMode");

  useEffect(() => {
    if(liquidacion || watchCreditMode === undefined) return;
    onChangeCreditMode();
  }, [watchCreditMode])

  const onChange = async e => {
    const { name, value } = e.target;
    setValue(name, value);
    const formData = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleCreditInfoForm(isValid ? formData : null);
  }

  const handleSelectCreditModal = (value) => {
    setShowSelectCreditModal(value)
  }

  const onSubmitSelecCreditModal = ({total, data}) => {
    if(liquidacion) return;
    if([CREDIT_MODE_ID.UPDATE].includes(watchCreditMode)){
      onSelecCreditModal({total, data, mode: CREDIT_MODE.UPDATE})
    }

    if([CREDIT_MODE_ID.UNIFICATION].includes(watchCreditMode)){
      onSelecCreditModal({total, data, mode: CREDIT_MODE.UNIFICATION})
    }
  }

  return (
    <>
    {showSelectCreditModal && (
        <CreditSelectModal
          viewMode={liquidacion ? MODAL_VIEW.READONLY : MODAL_VIEW.WRITE}
          mode={
            !liquidacion ? 
              [CREDIT_MODE_ID.UNIFICATION].includes(watchCreditMode) 
              ? MODAL_MODE.MULTIPLE 
              : MODAL_MODE.SINGLE
            : [CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad}`) 
              ? MODAL_MODE.MULTIPLE 
              : MODAL_MODE.SINGLE
          }
          title={liquidacion && selected
            ? selected.nomModalidad
            : watchCreditMode 
              ? creditModalities.filter(creditMode => creditMode.value === Number(watchCreditMode))[0].label 
              : 'Seleccionar' }
          open={showSelectCreditModal} 
          idClient={selected.clienteId} 
          collapseOneOpen={handleSelectCreditModal} 
          onSubmit={onSubmitSelecCreditModal}
          inSelectedCredits={
            liquidacion && selected
              ? [CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad}`)
                ? selected.prestamos
                : selected.prestamos.filter(credit => credit.tipo === 1 )
              : selectedCredits 
                ? selectedCredits.filter(credit => credit.tipo === 1 ) 
                : []}
          inThirdCredits={
            liquidacion && selected 
              ? selected.prestamos.filter(credit => credit.tipo === 2 )
              : selectedCredits 
                ? selectedCredits.filter(credit => credit.tipo === 2) 
                : []}
          inTotal={liquidacion && selected
            ? [CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad}`)
              ? selected.valorUnificacion 
              : selected.valorActualizacion
            : undefined
          }
        />
    )}
    <Card className={`my-3`} >
      <ThemeBackground color='info' />
      <FalconCardHeader title="Información del Crédito"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
        <form onChange={onChange}>
        <Row>
          <Col lg={4} xs={4}>
            <DetailRow title="Nro. de Solicitud">{selected.solicitud ? refFormat(selected.solicitud) : <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Línea de Crédito">{selected.nomLinea || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Modalidad de Crédito" >
              {liquidacion ? (
                 selected ? selected.nomModalidad : <span className='p-2'></span>
              ) : (
                <FormGroup className="pr-4">
                  <WizardInput
                    className="pl-2"
                    type="select"
                    tag={CustomInput}
                    placeholder="Seleccionar"
                    id="creditMode"
                    name="creditMode"
                    options={creditModalities}
                    errors={errors}
                    innerRef={register({
                      required: true,
                    })}
                  />
                </FormGroup>
              )}
            </DetailRow>
          </Col>

          <Col lg={4} xs={4}>
            <DetailRow title="Fecha de Solicitud">{selected.fecha || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Sublínea de Crédito">{selected.nomSubLinea || <span className='p-2'></span>}</DetailRow>
            {[CREDIT_MODE_ID.UNIFICATION].includes(watchCreditMode) ||
            (liquidacion && [CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad}`)) ? (
              <DetailRow title="Saldo Unificación">
                <CountUp end={unificationBalance} duration={1} prefix="$" separator="." decimal="," decimals={2} />
                </DetailRow>
            ):<></>} 
            
            {[CREDIT_MODE_ID.UPDATE].includes(watchCreditMode) || 
            (liquidacion && [CREDIT_MODE_ID.UPDATE].includes(`${selected.idModalidad}`)) ? (
              <DetailRow title="Saldo Actualización">
                <CountUp end={updateBalance} duration={1} prefix="$" separator="." decimal="," decimals={2} />
              </DetailRow>
            ):<></>}

            {liquidacion && [CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad}`) ? (
              <DetailRow title="Saldo Refinanciación">
                <CountUp end={selected.monto} duration={1} prefix="$" separator="." decimal="," decimals={2} />
              </DetailRow>
            ):<></>}
          </Col>

          <Col lg={4} xs={4} className="text-right">
            <DetailRow title={liquidacion ? "Fecha de Liquidación" : "Fecha de Pre-Liquidación"}>
              {getTodayStr() || <span className='p-2'></span>}
            </DetailRow>
            <DetailRow title="Nro. de Crédito">
              {selected.idCredito || <span className='p-2'></span>}
            </DetailRow>
            {[CREDIT_MODE_ID.UPDATE, CREDIT_MODE_ID.UNIFICATION].includes(watchCreditMode) || 
            (liquidacion && [CREDIT_MODE_ID.UPDATE, CREDIT_MODE_ID.UNIFICATION, CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad }`)) ? (
              <DetailRow title="-">
                <a className="font-weight-semi-bold fs--1 text-nowrap cursor-pointer" onClick={() => handleSelectCreditModal(true)}>
                  {liquidacion
                    && [CREDIT_MODE_ID.UPDATE].includes(`${selected.idModalidad }`)
                      && "Ver Créditos Actualizados"}
                  {liquidacion
                    && [CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad }`)
                      && "Ver Créditos Unificados"}
                  {liquidacion
                    && [CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad }`)
                      && "Ver Crédito Refinanciado"}
                  {!liquidacion && "Seleccionar Créditos"}
                  <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
                </a>
              </DetailRow>
            ):<></>}
          </Col>

        </Row>
        </form>
        ) : <></>}
      </CardBody>
    </Card>
    </>
  );
};

export default CreditInfo;
