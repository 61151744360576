import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import Label from 'reactstrap/es/Label';
import classNames from 'classnames';
import { urlApi } from '../../configuracion/serverConfig';
import Logo from '../navbar/Logo';
import { ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUndo} from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';

const PasswordResetForm = ({ setRedirect, setRedirectUrl, layout, hasLabel,setShowModal }) => {
  // State
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    let objReset = {
      sesion: JSON.parse(sessionStorage.getItem('Usuario')).sesionId,
      claveOld: oldPassword,
      claveNew: newPassword,  
    };   
    fetch( urlApi + '/pass', {
      method: 'POST',
      body: JSON.stringify(objReset),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.status === 0) { 
          cogoToast.success("Contraseña actualizada correctamente", { position: 'bottom-right', heading: 'Actualizado' });
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error' });
        }
      })
      .catch(err => console.log('err', err));
      //setRedirect(true);
      setShowModal(false);
  };

  /* useEffect(() => {
    setRedirectUrl(`/authentication/${layout}/login`);
  }, [setRedirectUrl, layout]); */

  useEffect(() => {
    if (newPassword === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(newPassword !== confirmPassword);
  }, [newPassword, confirmPassword]);

  return (
    <>
      <ModalBody>
    <div className="text-center">
       <Logo />
    <h5>Cambiar la Contraseña</h5>
    <Form className={classNames('mt-3', { 'text-left': hasLabel })}>
    <FormGroup>
        {hasLabel && <Label>Contraseña actual</Label>}
        <Input
          placeholder={!hasLabel ? 'Contraseña actual' : ''}
          value={oldPassword}
          onChange={({ target }) => setOldPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Nueva contraseña</Label>}
        <Input
          placeholder={!hasLabel ? 'Nueva contraseña' : ''}
          value={newPassword}
          onChange={({ target }) => setNewPassword(target.value)}
          type="password"
        />
      </FormGroup>
      <FormGroup>
        {hasLabel && <Label>Repite la nueva contraseña</Label>}
        <Input
          placeholder={!hasLabel ? 'Repite la nueva contraseña' : ''}
          value={confirmPassword}
          onChange={({ target }) => setConfirmPassword(target.value)}
          type="password"
        />
      </FormGroup>
      {/* <Button color="primary" block className="mt-3" disabled={isDisabled}>
        Cambiar contraseña
      </Button> */}
      <div className="my-4">
      <Button
        className="mr-1" color="primary" size="sm" outline
		style={{'height': '36px'}}
        disabled={isDisabled}
        onClick={handleSubmit}
      >
		<i className="mr-1">
		{' '}
		<FontAwesomeIcon icon={faKey} />
		</i>
        Cambiar
      </Button>
      &nbsp; &nbsp;
      <Button 
        className="mr-1" color="primary" size="sm" outline
		style={{'height': '36px'}}
        onClick={() => setShowModal(false)}
      >
		<i className="mr-1">
		{' '}
		<FontAwesomeIcon icon={faUndo} />
		</i>
        Cancelar
      </Button>
    </div>
    </Form>
    </div>
    </ModalBody>
   
  </>
  );
};

PasswordResetForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  setRedirectUrl: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

PasswordResetForm.defaultProps = { layout: 'basic', hasLabel: false };

export default withRedirect(PasswordResetForm);
