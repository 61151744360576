import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,

} from 'reactstrap';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import CustomInput from 'reactstrap/lib/CustomInput';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import { currencyFormat } from '../../../helpers/tableFormatters';
import CountUp from 'react-countup';

const WayToPay = () => {
  const context = useContext(DesembolsoContext);
  const { loading, selected, totalDeductions, neto, diff, conceptosList, handleSelectedConcept } = context;

  const onChnageConcepto = values => {
    handleSelectedConcept(values.value)
  }

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Forma de Pago"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
        <Row className="text-right fs-3">
          <Col lg={12}>
            <DetailRow title="Concepto de Egreso">
              <FormGroup>
                <WizardInput
                className="text-right"
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="egresoConcept"
                  name="egresoConcept"
                  options={conceptosList}
                  onChange={(e) => onChnageConcepto({ name: 'egresoConcept', value: e.target.value })}
                  errors={[]}
                  tabIndex="6"
                />
              </FormGroup>
            </DetailRow>
            <DetailRow textNormal title="Monto Liquidado">{selected.monto ? currencyFormat(selected.monto) : <span className='p-2'></span>}</DetailRow>
            <DetailRow textNormal title="Total Deducciones">
              <CountUp end={totalDeductions || 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
            </DetailRow>
            <DetailRow textNormal title="Neto a Desembolsar" childrenClasses="text-primary fs-3 font-weight-bold">
              <CountUp end={neto || 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
            </DetailRow>
            <DetailRow textNormal title="Diferencia" childrenClasses="text-warning fs-3 font-weight-bold">
              <CountUp end={diff} duration={1} prefix="$" separator="." decimal="," decimals={2} />
            </DetailRow>
          </Col>
        </Row>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default WayToPay;
