import React, { useState, useEffect, Fragment } from 'react';
import cogoToast from 'cogo-toast';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PrejuridicoProcessContext } from './PrejuridicoProcessContext';
import PageHeader from '../../../components/common/PageHeader';
import { juridicoServices } from '../../../services';
import { currencyFormat, idFormat, refFormat } from '../../../helpers/tableFormatters'
import MainTable from '../../../components/tables/MainTable';
import ButtonIcon from '../../../components/common/ButtonIcon';
import PrejuridicoDetails from '../cobro/PrejuridicoDetails';
import ConfirmModal from '../../../components/modals/confirmModal';

const PrejuridicoProcess = () => {
  const [loading, setLoading] = useState(false);

  const [searchKey, setSearchKey] = useState();

  const [cobrosList, setCobrosList] = useState([]);
  const [selectedCredit, setSelectedCredit] = useState([]);
  const [selectedCredits, setSelectedCredits] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [clientSelected, setClientSelected] = useState();
  const [lawyerSelected, setLawyerSelected] = useState();
  const [dataCost, setDataCost] = useState();
  const [totalCost, setTotalCost] = useState(0);
  const [total, setTotal] = useState(0);
  const [generalInfo, setGeneralInfo] = useState({});

  const [saveId, setSaveId] = useState();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadCobros();
  }, [])

  useEffect(() => {
    if(!dataCost) return
    const tempTotalCost = Object.keys(dataCost).map(item => 
      dataCost[item] 
        ? dataCost[item]
        : 0).reduce((a, b) => 
          Number(a) + Number(b))
    setTotalCost(tempTotalCost)
  }, [dataCost])

  useEffect(() => {
    if(!selectedCredits || !selectedCredits[0]) return;
    const totalCredits = selectedCredits.map(item => item.monto || 0).reduce((a,b) => a + b)
    const tempTotal = totalCost + totalCredits;
    setTotal(tempTotal)
  }, [totalCost, selectedCredits])

  useEffect(() => {
    loadCobros();
  }, [searchKey])

  const columns = [
    {
        dataField: 'procesoJuridico',
        text: 'Proceso',
        headerClasses: 'border-0',
        align: 'left',
        classes: 'border-0 py-2 align-middle',
        sort: true,
        formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'nombreDemandado',
      text: 'Demandado',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nombreAbogado',
      text: 'Abogado Apoderado',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => lawyerFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nombreJuzgado',
      text: 'Despacho/Proceso',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => despachoFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'valorEmbargo',
      text: 'Valor Embargo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acción',
      classes: 'border-0 py-2',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nombreDemandado}</div>
        <div>{`${fieldSelected.tipoIdDemandado}`} {idFormat(fieldSelected.idDemandado)}</div>
      </div>
    )
  }

  const lawyerFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nombreAbogado}</div>
        <div>{`${fieldSelected.tipoIdAbogado}`} {idFormat(fieldSelected.idAbogado)}</div>
      </div>
    )
  }

  const despachoFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className=''>{fieldSelected.nombreJuzgado}</div>
        <div>{`${fieldSelected.numeroProceso}`} {fieldSelected.fechaNotificacion}</div>
      </div>
    )
  }

  const actionsFormatter = (dataField, fieldSelected) => {
    if(!fieldSelected) return <></>
    return (
      <div className='pl-4'>
      <IconButton
        onClick={() => handleShowDetails(fieldSelected)}
      >
        <FontAwesomeIcon
          style={{ width: '16px' }}
          icon={faGavel}
          color="SteelBlue"
        />
      </IconButton>
      </div>
    )
  }

  const loadCobros = async () => {
    const response = await juridicoServices.getProcesosPrejuridicos(searchKey);
    if(!response || response.error){
      setLoading(false);
      cogoToast.error(response.msg, 
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }
    setCobrosList(response.objResponse);
  }

  const handleSearch = value => {
    setSearchKey(value);
  }

  const handleSelectCheckbox = (data, isSelect) => {
    data.tipo = 1;
    let tempSelecetdCredits;
    if (isSelect) {
      tempSelecetdCredits = [...selectedCredits];
      tempSelecetdCredits.push(data);
    } else {
      tempSelecetdCredits = selectedCredits.filter(credit => credit.prestamo != data.prestamo);
    }

    setSelectedCredits(tempSelecetdCredits)
  }

  const handleShowDetails = (selected) => {
    setLoading(true)
    setSelectedCredit(selected)
    loadDataDetails(selected);
    setLoading(false)
    setShowDetails(true);
  }

  const loadDataDetails = (data) => {
    setSaveId(data.procesoJuridico)
    const tempClient = {
      id: data.demandado,
      terceroInformacion: {
        identificacion: data.idDemandado,
        tipoIdentificacion: data.tipoIdDemandado,
        nombreCompleto: data.nombreDemandado,

      },
      terceroUbicacion: {
        correo: data.correoDemandado,
        telefono1: data.telefonoDemandado,
      }
    }

    const tempLawyer = {
      id: data.abogado,
      terceroInformacion: {
        identificacion: data.idAbogado,
        tipoIdentificacion: data.tipoIdAbogado,
        nombreCompleto: data.nombreAbogado,

      },
      terceroUbicacion: {
        correo: data.correoAbogado,
        telefono1: data.telefonoAbogado,
      }
    }

    const tempGeneralInfo = {
      despacho: data.juzgado,
      numRef: data.numeroProceso,
      dateNotification: data.fechaNotificacion
    }

    const tempCredits = data.prestamos.map(credit => ({
      nomPagaduria: credit.nombrePagaduria,
      credito: credit.credito,
      monto: credit.saldo
    }))

    const tempDataCost = {
      costLawyer: data.honorarioAbogado,
      costCurador: data.honorarioCurador,
      emplazamiento: data.emplazamiento,
      citatory: data.citatorio,
      notificationAdvise: data.notificacion,
      viaticos: data.viaticos,
      costOther: data.otrosGastos,
      costIntereses: data.intereses,
    }

    setClientSelected(tempClient);
    setLawyerSelected(tempLawyer);
    setSelectedCredits(tempCredits);
    setDataCost(tempDataCost);
    setGeneralInfo(tempGeneralInfo);
  }

  const onClientSelect = (client) => {
    setClientSelected(client)
  }

  const onSelectLawyer = (lawyer) => {
    setLawyerSelected(lawyer)
  }

  const handleBack = () => {
    setShowDetails(false);
    setSelectedCredits([]);
    setClientSelected(null);
    setLawyerSelected(null);
    setSaveId(null);
    loadCobros();
  }

  const handleChangeCost = (values) => {
    setDataCost(values);
  }

  const handleGeneralInfoChange = (values) => {
    setGeneralInfo(values);
  }

  const onSave = async ({ noBack }) => {
    setLoading(true)
    return new Promise(async (resolve, reject) => {
      const payload = {
        "procesoJuridico": saveId || 0,
        "demandado": clientSelected && clientSelected.id,
        "abogado": lawyerSelected && lawyerSelected.id,
        "fechaLiquidacion": selectedCredit ? selectedCredit.fechaLiquidacion : "",
        "juzgado": generalInfo && generalInfo.despacho,
        "numeroProceso": generalInfo && generalInfo.numRef,
        "fechaNotificacion": generalInfo && generalInfo.dateNotification,
        "valorEmbargo": total,
        "honorarioAbogado": dataCost ? Number(dataCost.costLawyer || 0) : 0,
        "honorarioCurador": dataCost ? Number(dataCost.costCurador || 0) : 0,
        "emplazamiento": dataCost ? Number(dataCost.emplazamiento || 0) : 0,
        "citatorio": dataCost ? Number(dataCost.citatory || 0) : 0,
        "notificacion": dataCost ? Number(dataCost.notificationAdvise || 0) : 0,
        "viaticos": dataCost ? Number(dataCost.viaticos || 0) : 0,
        "otrosGastos": dataCost ? Number(dataCost.costOther || 0) : 0,
        "intereses": dataCost ? Number(dataCost.costIntereses || 0) : 0,
        "estado": 1,
        "prestamos": selectedCredit && selectedCredit.prestamos.map(item => ({ prestamo: item.prestamo }))
      }

      const response = await juridicoServices.savePrejuridico(payload);


      if (!response || response.error || !response.objResponse) {
        cogoToast.error(response.msg || "No se logro completar la operación de Guardar",
          { position: 'bottom-left', heading: 'Error' });
        setLoading(false);
        resolve(false);
        return;
      }

      const id = response.objResponse.procesoJuridico;

      if(!noBack) {
        cogoToast.success("Información guardada con éxito",
          { position: 'bottom-left', heading: 'Exitoso' });
      }

      setLoading(false);
      if(!noBack) handleBack();
      resolve({ id });
    })
  }

  const liquidar = async () => {
    const onSaveResponse = await onSave({noBack: true})
    if(!onSaveResponse || !onSaveResponse?.id) return;
    const payload = {
      "procesoJuridico": onSaveResponse.id,
    }
    const response = await juridicoServices.liquidar(payload);


    if (!response || response.error || !response.objResponse) {
      cogoToast.error(response.msg || "No se logro completar la operación de Liquidación",
        { position: 'bottom-left', heading: 'Error' });
      setLoading(false);
      return;
    }
    setLoading(false);
    cogoToast.success("Operación completada",
        { position: 'bottom-left', heading: 'Exitoso' });
    handleBack();
  }

  const onLiquidar = () => {
    setShowModal(true);
  }

  return (
    <PrejuridicoProcessContext.Provider value={{
      loading,
      client: clientSelected,
      lawyer: lawyerSelected,
      credits: selectedCredits,
      dataCost,
      generalInfo,
      totalCost,
      total,
      onClientSelect,
      onSelectLawyer,
      handleChangeCost,
      handleGeneralInfoChange,
      onSave,
      onLiquidar
    }}>
      <Fragment>
        <ConfirmModal 
          open={showModal} 
          onCancel={() => setShowModal(false)} 
          onOk={() => {
            setShowModal(false)
            liquidar()
          }}  
          title="Confirmar Liquidación"
          text="Confirmar"
          body="Por favor confirme para realizar la operación"
        />
        <PageHeader title={showDetails ? "Proceso Jurídico" : "Procesos Pre-Jurídicos"} className="mb-3" >
        {showDetails && <ButtonIcon className="mt-3" onClick={handleBack} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
          Atrás
        </ButtonIcon>}
        </PageHeader>
        {showDetails ? (
          <PrejuridicoDetails context={PrejuridicoProcessContext}/>
        ) : (
          <MainTable 
            data={cobrosList}
            currentSearch={searchKey}
            columns={columns} 
            keyField="procesoJuridico"
            search={handleSearch}
            className="px-2"
            loading={loading}
            noAdd
          />
        )}
      </Fragment>
    </PrejuridicoProcessContext.Provider>
  );
};

export default PrejuridicoProcess
