import React from 'react';
import { Card } from 'reactstrap';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';
import WizardInput from '../../components/auth/wizard/WizardLayout'
import PageHeader from '../common/PageHeader';
import ButtonIcon from '../common/ButtonIcon';
import { authSesion } from '../../helpers/authSesion';
import { normalizeReqBody } from '../../helpers/normalizers';
import { personsServices } from '../../services';
import PersonForm from './PersonForm';

const GestionUsuarioEmpresaForm = (props) => {

  const agregar = async formData => {
    const sesionToken = authSesion();
    const reqBody = normalizeReqBody(formData, sesionToken, true);

    const resp = await personsServices.add(reqBody);
    
    if(resp.error){
      cogoToast.error(resp.error.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
    } else {
      cogoToast.success('El usuario fue agregado correctamente', {
        position: 'bottom-right',
        heading: 'Agregado'
      });
    }
    props.MostrarFormulario("Cargar")
  };

  return (
    <>
      <PageHeader
        title="Registro Gestión Persona | Empresa"
        className="mb-3"
      >
        <ButtonIcon className="mt-3" onClick={() => props.MostrarFormulario("Cancelar")} icon={faTimes} transform="shrink-3 down-2" color="falcon-default" size="sm">
            Cancelar
        </ButtonIcon>
      </PageHeader>
      <Card>
      <WizardInput>
        <PersonForm handleSubmitForm={agregar} />
      </WizardInput>
      </Card>
    </>
  );

}
export default GestionUsuarioEmpresaForm;
