import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Badge, Card, CardBody, Col, Row, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { numberFormatter } from '../../../helpers/utils';
import Avatar from '../../../components/common/Avatar';
import { StateContext } from '../StateContext';

import weatherIcon from '../../../assets/img/icons/weather-icon.png'

const ClientMiniCard = ({ stateId, title, total, badge, emoji  }) => {
  const context = useContext(StateContext);
  const { client, creditState, onSelectCreditState } = context;
  const [ active, setActive ] = useState(false);

  useEffect(() => {
    setActive(client && [creditState].includes(stateId))
  }, [client, creditState])

  const onClick = () => {
    onSelectCreditState(stateId);
  }

  return (
    <Card className={`h-md-100 cursor-pointer ${ active && 'custom_active'}` } onClick={onClick}>
      <FalconCardHeader
        className={`pb-0 ${ active && 'custom_active'}`}
        title={
          <Row className="d-flex justify-content-between mx-1">
          <h6 className={`mb-0 ${ active && 'text-white'}`}>{title}</h6>
            <Badge pill color="soft-success" className="fs--2">
              {badge}
            </Badge>
          </Row>
        }
        light={false}
        titleTag="h6"
      />
      <CardBody className="py-2">
        <Row className="flex-grow-1">
          <Col className="d-flex align-items-center">
            <div className={`fs-3 font-weight-normal text-sans-serif text-700 line-height-1 mb-1 ${active && 'text-white'}`}>
              {numberFormatter(total, 1)}
            </div>
            
          </Col>
          <Col xs="auto" className="pl-0">
            <Avatar
              emoji={emoji} 
              size= 'l'
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

export default ClientMiniCard