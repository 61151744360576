import React from 'react';
import { Table } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { Input, Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUndo } from '@fortawesome/free-solid-svg-icons';
import ButtonIcon from '../common/ButtonIcon';
class ContactList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };
  }

  obtenerId = e => {
    const selectedTem = this.state.selected ? this.state.selected : [];
    if (selectedTem.includes(e.target.value)) {
      let index = selectedTem.indexOf(e.target.value);
      if (index > -1) {
        selectedTem.splice(index, 1);
      }
      this.setState(state => ({
        ...state,
        selected: null,
        ...state,
        selected: selectedTem
      }));
    } else {
      selectedTem.push(e.target.value);
      this.setState(state => ({
        ...state,
        selected: null,
        ...state,
        selected: selectedTem
      }));
    }
  };

  alertaEliminar2 = e => {
    confirmAlert({
      title: 'ATENCION',
      message: 'No hay ningun dato seleccionado, por favor seleccione varios a eliminar',
      buttons: [
        {
          label: 'Aceptar',
          onClick: () => this.onClick2('Aceptar')
        }
      ]
    });
  };

  onClick2 = () => {};

  EliminarSelected = () => {
    if (this.state.selected && this.state.selected.length > 0) {
      this.props.actiononContact('deleteAll', this.state.selected);
    } else {
      this.alertaEliminar2();
    }
  };
  render() {
    let FechaCorta = '';
    if (this.props.ComplementoFcha) {
      FechaCorta = this.props.ComplementoFcha;
    }
    return (
      <div className="right-panel roe-box-shadow">
        <div className="contact-table">
          <Scrollbars
            autoHide
            className="contact-scroll-height"
            style={{
              minHeight: '240px'
            }}
          >
            {this.props.contactlists && this.props.contactlists.length ? (
              <Table borderless hover className="mb-0" id={['Identificacion']}>
                <thead className="">
                  <tr>
                    {this.props.headerLista.map((e, i) => {
                      if (i > 1 && this.props.Acciones === false) {
                        return <th className="" />;
                      } else if (i > 0 && this.props.Acciones === true) {
                        return <th className="" />;
                      }
                    })}
                  </tr>
                  <tr className="border-bottom">
                    {this.props.headerLista.map((e, i) => {
                      let total = this.props.headerLista.length - 1;
                      let porcentaje = 0;
                      if (this.props.Acciones === true) {
                        porcentaje = 79 / total;
                      } else {
                        porcentaje = 79 / total;
                      }

                      if (i < 1) {
                        return (
                          <>
                            <th style={{ width: '1%' }}>{e} </th>
                          </>
                        );
                      } else {
                        return <th style={{ width: porcentaje + '%' }}>{e} </th>;
                      }
                    })}
                    {this.props.Acciones === true ? (
                      <th className="" style={{ width: '15%' }}>
                        
                      </th>
                    ) : (
                      ''
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.props.contactlists.map((e, i) => {
                    return (
                      <tr key={i} className="border-bottom">
                        {this.props.bodyLista.map((k, i) => {
                          if (i < 1) {
                            return (
                              <>
                                <td />
                                <td style={{verticalAlign: "middle"}}>{e[k]}</td>
                              </>
                            );
                          } else {
                            return <td style={{verticalAlign: "middle"}} >{e[k]}</td>;
                          }
                        })}
                        {this.props.Acciones === true ? (
                          <td>
                          <ButtonIcon onClick={() => this.props.quitarCampo(e)} icon="trash" transform="shrink-3 down-2" color="danger" size="sm" />
                          </td>
                        ) : (
                          ''
                        )}
                        
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="text-center no-found-message mt-4"></div>
            )}
          </Scrollbars>
        </div>
      </div>
    );
  }
}

export default ContactList;
