import React, { useState, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, Badge, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainTable from '../../components/tables/MainTable';
import { personsServices } from '../../services';
import { SolicitudContext } from './SolicitudContext';

const ModalSearchClient = ({ show, title, toggle, onSelect, terceros, lawyers }) => {
  const context = useContext(SolicitudContext);
  if (!onSelect) {
    var { onSelectClient } = context;
  }
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState();

  const columns = [
    {
      dataField: 'terceroInformacion.nombre1',
      text: 'Nombre/Razón social',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (name, row) => nameFormatter(name, row),
      sort: true
    },
    {
      dataField: 'terceroInformacion.identificacion',
      headerClasses: 'border-0',
      text: 'Identificación',
      classes: 'border-0 py-2 align-middle',
      sort: false
    },
    {
      dataField: 'terceroUbicacion.correo',
      headerClasses: 'border-0',
      text: 'Correo',
      classes: 'border-0 py-2 align-middle',
      sort: false
    },
  ];

  const nameFormatter = (name, data) => {
    if (!data) return <></>
    const { nombre2, apellido1, apellido2, nombreComercial, razonSocial } = data.terceroInformacion;
    return razonSocial ? <span>{`${nombreComercial || razonSocial}`}</span> : <span>{`${name} ${nombre2} ${apellido1} ${apellido2}`}</span>
  }

  const handleSearch = async (value) => {
    setLoading(true);
    const resp = await personsServices.getByPattern({
      roles: lawyers ? '11' : terceros ? '0' : '1',
      pattern: value,
    })
    if (!resp || resp.error) {
      setLoading(false);
      return;
    }

    setListData(resp.objResponse);
    setLoading(false);
  }

  const handleSelect = (value) => {
    if (onSelect) {
      onSelect(value);
    } else {
      onSelectClient(value);
    }
    setListData([]);
  }

  return (
    <Modal isOpen={show} size="xl" toggle={() => {
      setListData([]);
      toggle();
    }}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => toggle()}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        <div className="row pt-3 ">
          <div className="flex col-md-12">
            <h3>{title ? title : terceros ? "Buscar Tercero" : "Buscar Cliente"}</h3>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <MainTable
          data={listData}
          columns={columns}
          keyField="terceroInformacion.identificacion"
          search={handleSearch}
          className="px-2"
          loading={loading}
          pageSize={10}
          rowClasses="main-table-custom-row"
          handleSelect={{ mode: 'radio', clickToSelect: true, onSelect: handleSelect }}
          noAdd
        />
      </ModalBody>
    </Modal>
  );
};

export default ModalSearchClient;
