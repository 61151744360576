import React from 'react';
import { Row } from 'reactstrap';

const DetailRow = ({ title, children, titleClass, childrenClasses, textNormal, className }) => (
  <Row className={`d-flex flex-column pb-2 ${className}`}>
    <div className={`fs--2 ${titleClass}`}>
        {title}
    </div>
    <div className={`${textNormal ? "" : 'font-weight-semi-bold' } ${childrenClasses}`}>{children}</div>
  </Row>
);

export default DetailRow;
