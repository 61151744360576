import React, { useContext, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { StateContext } from '../../state/StateContext';
import { idFormat } from '../../../helpers/tableFormatters';
import ThemeBackground from '../../../components/common/ThemeBackground';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { CREDIT_MODE_ID } from '../../../data/constans';
import { currencyFormat, refFormat } from '../../../helpers/tableFormatters';
import ButtonIcon from '../../../components/common/ButtonIcon';

const ClientInfo = () => {
  const context = useContext(StateContext);
  const { creditSelected: selected } = context;
  return (
    <Card className="my-3" >
      <ThemeBackground color='info' />
      <FalconCardHeader title="Información del Cliente"></FalconCardHeader>
      <CardBody className="border-top px-4">
        {selected && (
        <Row>
          <Col lg={6} xs={12}>
            <DetailRow title="Pagaduría">{selected.nomPagaduria || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Identificación">
              {selected.tipoIdCliente} {idFormat(selected.idCliente)}
            </DetailRow>
            <DetailRow title="Teléfono">{selected.telefono || <span className='p-2'></span>}</DetailRow>
          </Col>

          <Col lg={6} xs={12}>
            <DetailRow title="Razón Social o Nombre del Cliente">{selected.nomCliente || selected.razonSocial || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Correo">{selected.correo || <span className='p-2'></span>}</DetailRow>
          </Col>

        </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default ClientInfo;
