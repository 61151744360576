import React from 'react';
import { Col, Row } from 'reactstrap';
import UserForm from './UserForm';
import Section from '../../../components/common/Section';
import Logo from '../../../components/navbar/Logo';
import AuthWizardProvider from './AuthWizardProvider';

const WizardLayout = (props) => {
  return (
          <AuthWizardProvider>
            {props.children}
          </AuthWizardProvider>
  );
};

export default WizardLayout;
