import React, { useState, useContext, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { StateContext } from '../StateContext';
import { idFormat } from '../../../helpers/tableFormatters';
import ThemeBackground from '../../../components/common/ThemeBackground';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { CREDIT_MODE_ID } from '../../../data/constans';
import { currencyFormat } from '../../../helpers/tableFormatters';
import ButtonIcon from '../../../components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalCredits from '../children/CreditSelectModal';

const CreditInfo = () => {
  const context = useContext(StateContext);
  const { creditSelected: selected, handleRefinance } = context;

  const [showThirdModal, setShowThirdModal] = useState();
  const [thirdCredits, setThirdCredits] = useState([]);

  const handleSelectCreditModal = () => {
    setThirdCredits(selected.prestamos)
    setShowThirdModal(true);
  }

  const closeModal = () => {
    setShowThirdModal(false)
    setThirdCredits(selected.prestamos)
  }

  return (
    <>
    <ModalCredits open={showThirdModal} title="Refinanciación" data={thirdCredits} toggle={closeModal} />
    <Card className="my-3" >
      <ThemeBackground color='info' />
      <FalconCardHeader title="Información del Crédito">
        {selected && selected.estado == 2 && (
          <ButtonIcon onClick={handleRefinance} color="falcon-primary" size="sm">
            Refinanciar
          </ButtonIcon>
        )}
      </FalconCardHeader>
      <CardBody className="border-top px-4">
        {selected && (
        <Row>
          <Col lg={4} xs={4}>
            <DetailRow title="Nro. de Credito">{selected.idCredito || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Línea de Crédito">{selected.nomLinea || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Modalidad de Crédito">{selected.nomModalidad || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Identificación">
              {selected.tipoIdCliente} {idFormat(selected.idCliente)}
            </DetailRow>
          </Col>

          <Col lg={4} xs={4}>
            <DetailRow title="Fecha Liquidación">{selected.fecha || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Sublínea de Crédito">{selected.nomSubLinea || <span className='p-2'></span>}</DetailRow>

            {[CREDIT_MODE_ID.UPDATE].includes(`${selected.idModalidad}`) && (
              <DetailRow title="Monto Actualización">{selected.valorActualizacion ? currencyFormat(selected.valorActualizacion) : <span className='p-2'></span>}</DetailRow>
            )}
            {[CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad}`) && (
              <DetailRow title="Monto Unificación">{selected.valorUnificacion ? currencyFormat(selected.valorUnificacion) : <span className='p-2'></span>}</DetailRow>
            )}

            <DetailRow title="Razón Social o Nombre del Cliente" childrenClasses="text-uppercase">{selected.nomCliente || <span className='p-2'></span>}</DetailRow>    

            {[CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad }`) ? (
              <DetailRow title="-">
                <a className="font-weight-semi-bold fs--1 text-nowrap cursor-pointer" onClick={() => handleSelectCreditModal(true)}>
                  {/* {liquidacion
                    && [CREDIT_MODE_ID.UPDATE].includes(`${selected.idModalidad }`)
                      && "Ver Créditos Actualizados"}
                  {liquidacion
                    && [CREDIT_MODE_ID.UNIFICATION].includes(`${selected.idModalidad }`)
                      && "Ver Créditos Unificados"} */}
                  {[CREDIT_MODE_ID.REFINANCE].includes(`${selected.idModalidad }`)
                      && "Ver crédito Refinanciado"}
                  <FontAwesomeIcon icon="angle-right" transform="down-1.5" className="ml-1" />
                </a>
              </DetailRow>
            ):<></>}
            
          </Col>

          <Col lg={4} xs={4} className="text-right">
            <DetailRow title="Fecha de Desembolso">{selected.fecha  || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Pagaduría">{selected.nomPagaduria || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Saldo Actual">{selected.saldoProyectado ? currencyFormat(selected.saldoProyectado) : <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Fecha Vencimiento">{selected.fechaVencimiento  || <span className='p-2'></span>}</DetailRow>
          </Col>

        </Row>
        )}
      </CardBody>
    </Card>
    </>
  );
};

export default CreditInfo;
