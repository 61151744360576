import React from 'react';
import {
  FormGroup,
  Label,
  Input,
  Card
} from 'reactstrap';
import CountUp from 'react-countup';
import { useForm } from "react-hook-form";
import CardSummary from '../../components/dashboard/CardSummary';
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr  from 'react-flatpickr';
import { getLocaleCalendarConfig } from '../../helpers/utils';
import RequiredMarked from '../../components/common/RequiredMarked'

function SimulatorDisplay({ 
  fee, 
  handleCalculatePlan, 
  handleClickPlan, 
  vertical, 
  requiredMark,
  minDate = false,
}) {
  const { register, handleSubmit } = useForm();
  const today = new Date().toISOString().split("T")[0].replaceAll("-","/");

  const onSubmitDate = date => {
    handleCalculatePlan(date)
  }

  return (
    <div className={`${!vertical ? 'card-deck' : '' }`}>
      <div className={`${!vertical ? 'card mb-3 w-100 p-4' : 'mb-3' }`}>
        <form>
          <FormGroup>
            <Label for="first_pay">
              {requiredMark && <RequiredMarked />}
              Fecha Primer Corte
            </Label>
            <Flatpickr
              className="form-control"
              placeholder='aaaa/mm/dd'
              name="first_pay"
              id="first_pay"
              {...register('first_pay')}
              options={{
                dateFormat: "Y/m/d",
                minDate: minDate || today,
                locale: getLocaleCalendarConfig(),
              }}
              onChange={(date, dateStr) => {
                onSubmitDate(dateStr.replaceAll("/", "-"))
              }}
            />
          </FormGroup>
        </form>
      </div>
      <CardSummary useBtn btnAction={() => handleClickPlan(true)} content={fee || "0"} title="Cuota Mensual" color="info" linkText="Ver Plan de Pagos" rate="">
        {<CountUp end={fee ? fee : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />}
      </CardSummary>
    </div>
  )
}

export default SimulatorDisplay
