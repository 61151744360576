import React from 'react';
import GeneralConditionsInfo from './GeneralConditionsInfo';
import WayToPay from './WayToPay';
import ObservationsSection from './ObservationsSection';

const AsideLayout = () => {
  return (
      <>
        <GeneralConditionsInfo />
        <WayToPay />
        <ObservationsSection />
      </>
  )
}

export default AsideLayout