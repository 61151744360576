import React, { useState, useEffect, Fragment } from 'react';
import cogoToast from 'cogo-toast';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { JuridicoProcessContext } from './JuridicoProcessContext';
import PageHeader from '../../../components/common/PageHeader';
import { juridicoServices } from '../../../services';
import { currencyFormat, idFormat, refFormat } from '../../../helpers/tableFormatters'
import MainTable from '../../../components/tables/MainTable';
import ButtonIcon from '../../../components/common/ButtonIcon';
// import PrejuridicoDetails from '../cobro/PrejuridicoDetails';

const JuridicoProcess = () => {
  const [loading, setLoading] = useState(false);

  const [searchKey, setSearchKey] = useState();

  const [processList, setProcessList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [saveId, setSaveId] = useState();

  useEffect(() => {
    loadProcess();
  }, [])

  useEffect(() => {
    loadProcess();
  }, [searchKey])

  const columns = [
    {
        dataField: 'procesoJuridico',
        text: 'Proceso',
        headerClasses: 'border-0',
        align: 'left',
        classes: 'border-0 py-2 align-middle',
        sort: true,
        formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'nombreDemandado',
      text: 'Demandado',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nombreAbogado',
      text: 'Abogado Apoderado',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => lawyerFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nombreJuzgado',
      text: 'Despacho/Proceso',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => despachoFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'valorEmbargo',
      text: 'Valor Embargo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acción',
      classes: 'border-0 py-2',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nombreDemandado}</div>
        <div>{`${fieldSelected.tipoIdDemandado}`} {idFormat(fieldSelected.idDemandado)}</div>
      </div>
    )
  }

  const lawyerFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nombreAbogado}</div>
        <div>{`${fieldSelected.tipoIdAbogado}`} {idFormat(fieldSelected.idAbogado)}</div>
      </div>
    )
  }

  const despachoFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className=''>{fieldSelected.nombreJuzgado}</div>
        <div>{`${fieldSelected.numeroProceso}`} {fieldSelected.fechaNotificacion}</div>
      </div>
    )
  }

  const actionsFormatter = (dataField, fieldSelected) => {
    if(!fieldSelected) return <></>
    return (
      <div className='pl-4'>
      <IconButton
        onClick={() => console.log(fieldSelected)}
      >
        <FontAwesomeIcon
          style={{ width: '16px' }}
          icon={faGavel}
          color="SteelBlue"
        />
      </IconButton>
      </div>
    )
  }

  const handleBack = () => {
    loadProcess();
  }

  const loadProcess = async () => {
    const response = await juridicoServices.getProcesosJuridicos(searchKey);
    if(!response || response.error){
      setLoading(false);
      cogoToast.error(response.msg, 
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }
    setProcessList(response.objResponse);
  }

  const handleSearch = value => {
    setSearchKey(value);
  }


  return (
    <JuridicoProcessContext.Provider value={{
      loading
    }}>
      <Fragment>
        <PageHeader title={showDetails ? "Proceso Jurídico" : "Procesos Jurídicos"} className="mb-3" >
        {showDetails && <ButtonIcon className="mt-3" onClick={handleBack} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
          Atrás
        </ButtonIcon>}
        </PageHeader>
        {showDetails ? (
          <></>
        ) : (
          <MainTable 
            data={processList}
            currentSearch={searchKey}
            columns={columns} 
            keyField="procesoJuridico"
            search={handleSearch}
            className="px-2"
            loading={loading}
            noAdd
          />
        )}
      </Fragment>
    </JuridicoProcessContext.Provider>
  );
};

export default JuridicoProcess
