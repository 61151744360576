import React, { useState, useContext, useEffect } from 'react';
import { Button, Row } from 'reactstrap';
import cogoToast from 'cogo-toast';
import CountUp from 'react-countup';
import CurrencyInput from 'react-currency-input-field';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import MainTable from '../../../components/tables/MainTable';
import { RecaudoContext } from '../RecaudoContext';
import { currencyFormat } from '../../../helpers/tableFormatters';
import LoteInfo from '../sections/LoteInfo';
import ThemeBackground from '../../../components/common/ThemeBackground';
import { clientFormatter } from '../../../helpers/tableFormatters';
import { recaudoServices } from '../../../services';
import Spinner from 'reactstrap/lib/Spinner';
import RecaudoLoteModal from '../modals/RecaudoLoteModal';
import { EnvironmentContext } from '../../../context/Context';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { currencyFormat as currencyNumberFormat, formatterId } from '../../../helpers/formatters';
import numToWord from '../../../helpers/numToWord';

const RecaudoLote = () => {
  const context = useContext(RecaudoContext);
  const environment = useContext(EnvironmentContext);
  const { loading, creditsPag, pagSelected, showModalLote, toggleModalLote, handleSaveLote, onCancelLote } = context;
  const [requesting, setRequesting] = useState(false);
  const [showModal, setShowModal] = useState();
  const [creditSelected, setCreditSelected] = useState();
  const [recaudos, setRecaudos] = useState(0);
  const [numRecaudos, setNumRecaudos] = useState(0);
  const [dataTable, setDataTable] = useState([]);

  const [success, setSuccess] = useState();

  const [invoiceProps, setInvoiceProps] = useState();

  const columns = [
    {
      dataField: 'credito',
      text: 'Crédito',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: '',
      text: 'Cliente',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField, selected) => clientFormatter(dataField, {
        nomCliente: selected.nombreTercero,
        tipoIdCliente: selected.tipoIdentificacion,
        idCliente: selected.identificacion
      }),
    },
    {
      dataField: 'fechaCorte',
      text: 'Fecha Corte',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'valorCuota',
      text: 'Valor Cuota',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'intereses',
      text: 'Abono Interés',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField) => moneyFormatter(dataField),
    },
    {
      dataField: 'capital',
      text: 'Abono Capital',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField) => moneyFormatter(dataField),
    },
    {
      dataField: '',
      text: 'Valor Recaudo',
      headerClasses: 'border-0 text-center',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (dataField, selected) => currencyInputFormatter(selected),
    },
  ];

  useEffect(() => {
    setRecaudos(0);
    setNumRecaudos(0);
    setCreditSelected(null);
    loadDatatable();
  }, [creditsPag]);

  const moneyFormatter = value => {
    return (
      <CountUp end={value} duration={1} prefix="$" separator="." decimal="," decimals={2} style={{fontSize: '1rem'}}/>
    )
  }

  const currencyInputFormatter = selected => {
    return (
      <div className='pl-4'>
        <CurrencyInput
          className="form-control pl-2 text-right"
          allowNegativeValue={false}
          id={selected.credito}
          prefix='$'
          intlConfig={{ locale: 'es-CO', currency: 'COP' }}
          onValueChange={ (e) => setCreditSelected({ id: selected.credito, value: e || '0'  })}
          decimalScale={2}
          maxLength={20}
          placeholder="$ 0.00"
          tabIndex="5" />
      </div>
    )
  }

  useEffect(() => {
    if(!creditSelected) return;
    onChangeRecaudo(creditSelected);
  }, [creditSelected])

  useEffect(() => {
    if(!dataTable || !dataTable[0]) return;
    const creditsRecaudos = dataTable.filter(c => c.recaudo > 0);

    const tempRecaudo = creditsRecaudos[0] ? creditsRecaudos.map(c=>c.recaudo).reduce((a, b) => a+b) : 0;
    const tempNumRecaudos = creditsRecaudos.length;
    setRecaudos(tempRecaudo);
    setNumRecaudos(tempNumRecaudos);
  }, [dataTable])

  const loadDatatable = data => {
    let tempData;
    if(data){
      tempData = [...dataTable];
      const index = dataTable.findIndex(credit => credit.credito == data.id);
      const selected = tempData[index];
      const recaudoValue = Number(data.value.replaceAll(' ', '').replace('$', '.').replace('.', '').replace(',', '.'))
      const intereses = recaudoValue >= selected.saldo_interes 
        ? selected.saldo_interes
        : recaudoValue;
      const capital = recaudoValue > selected.saldo_interes 
        ? recaudoValue - selected.saldo_interes
        : 0;
      const updatedItem = {
        ...tempData[index],
        intereses,
        capital,
        recaudo: recaudoValue
      };
      tempData[index] = updatedItem
    } else {
      tempData = creditsPag.map(credit => {
        return {
          ...credit,
          intereses: 0,
          capital: 0,
          recaudo: 0
        }
      })
    }
    setDataTable(tempData);
  }

  const onChangeRecaudo = (data, table) => {
    loadDatatable(data);

  }

  const createInvoiceProps  = (payload) => {
    const selectedConcept = payload.idTesoreria 
      ? environment.cashLoteConcepts.filter(concept => concept.conceptotesoreria == payload.conceptoTesoreria)
      : [];
    const total = payload.recaudos.map(recaudo => recaudo.valorRecaudo).reduce((a, b) => a+b)
    const montoWord = numToWord(total)

    
    const tempOwnProps = {
      client: pagSelected.razonSocial,
      clientTypeId: pagSelected.tipoIdentificacion,
      clientId: pagSelected.identificacion ? formatterId(Number(pagSelected.identificacion)) : "",
      serial: payload.consecutivo,
      ciudad: payload.ciudad,
      amount: currencyNumberFormat(total || 0),
      concept: selectedConcept[0] ? selectedConcept[0].nombre : "",
      sumWord: montoWord || "",
      checkCash: payload.modalidadPago == 1,
      checkWireTransfer: payload.modalidadPago == 2,
      checkPSE: false,
      checkCheque: payload.modalidadPago == 3,
      date: getTodayStr().split('-'),
      observations: payload.observacion
    }
    setInvoiceProps(tempOwnProps)
  }

  const handleCleanLote = () => {
    setRecaudos(0);
    setNumRecaudos(0);
    setDataTable([]);
    onCancelLote();
  }

  const onRecaudar = async paymentData => {
    const selectedRecaudos = dataTable.filter(c => c.recaudo > 0)
    if(selectedRecaudos.length < 1 || !pagSelected){
      cogoToast.warn("No hay valores para recaudar",
        { position: 'bottom-left', heading: 'Sin Valores' });
      return;
    }
    setRequesting(true);
    const payload = {
      idPagaduria: pagSelected.id,
      recaudos: selectedRecaudos.map(r => ({
        idPrestamo: r.prestamo,
        valorRecaudo: r.recaudo,
        abonoInteres: r.intereses,
        abonoCapital: r.capital
      })),
      tipoRecaudo: 2,
      ...paymentData,
    }

    const response = await recaudoServices.setRecaudoLote(payload);
    if (!response || response.error) {
      cogoToast.error(response.msg || "No se logro completar la solicitud de recaudo por lote",
        { position: 'bottom-left', heading: 'Error' });
      return;
    }
    handleSuccess({
      ...payload, 
      consecutivo: response.objResponse.consecutivo,
      ciudad: response.objResponse.ciudad
    });
  }

  const handleSuccess = (payload) => {
    // toggle(true);
    createInvoiceProps(payload);
    setSuccess(true);
    setRequesting(false);
  }

  const onOk = () => {
    handleSaveLote();
    setRequesting(false);
    loadDatatable();
  }

  const handleSaveClick = (values) => {
    const parsedValues = { ...values, amount: values.amount.replaceAll(',', '.') } 
    const expectAmount = values.amount ? Number(parsedValues.amount) : 0;
    const selectedRecaudos = dataTable.filter(c => c.recaudo > 0)
    if(!selectedRecaudos[0]){
      cogoToast.warn("No existen valores por recaudar",
        { position: 'bottom-left', heading: 'Valores Requeridos' });
        return;
    }
    const selectedCount = selectedRecaudos.length;
    const selectedAmount = selectedRecaudos.map(item => item.recaudo).reduce((a, b) => a + b)

    if(selectedCount != values.cant || selectedAmount != expectAmount){
      cogoToast.warn("Los valores esperados no coinciden con los seleccionados",
        { position: 'bottom-left', heading: 'Valores no coinciden' });
      return;
    }

    toggleModalLote();
  }
      
  return (
    <>
      {requesting ? (
        <Row className="py-4 d-flex justify-content-center">
            <Spinner color="primary" />
        </Row>
      ) : (
        <>
          <RecaudoLoteModal
            open={showModalLote} 
            data={{total: recaudos, numRecaudos }}
            toggle={toggleModalLote}
            success={success}
            onSubmit={onRecaudar}
            invoiceProps={invoiceProps}
            onOk={onOk}
          />
          <LoteInfo context={RecaudoContext} handleCleanLote={handleCleanLote} handleSaveClick={handleSaveClick} />
          <MainTable
            title="Créditos Activos"
            data={dataTable}
            columns={columns}
            keyField="credito"
            className="px-2"
            loading={loading}
            noSearch
            noAdd
          />
          <div className='card mt-3 w-100 p-4 d-flex flex-row justify-content-between'>
            <ThemeBackground color='info' />
            <DetailRow title="Cantidad" className="mb-1">
              <div className='display-4 fs-4 font-weight-normal text-center text-sans-serif text-info'>
                {numRecaudos}
              </div>
            </DetailRow>
            <DetailRow title="Total a recaudar" className="mb-1">
              <div className='display-4 fs-4 font-weight-normal text-sans-serif text-info'>
                <CountUp end={recaudos} duration={1} prefix="$" separator="." decimal="," decimals={2} />  
              </div>
            </DetailRow>
          </div>
        </>
      )}
      
    </>
  )
}

export default RecaudoLote