import React from 'react';
import { Col } from 'reactstrap';
import DetailRow from './DetailRow';
import Badge from 'reactstrap/es/Badge';

const PersonalInfo = ({
    data,
    natural
}) => {

    const stateFormatter = status => {
      let color = '';
      let text = '';
      switch (status) {
        case '1':
          color = 'success';
          text = 'Activo';
          break;
        case '0':
          color = 'secondary';
          text = 'Inactivo';
          break;
        default:
          color = 'warning';
          text = 'Pendiente';
      }
      return (
        <div>
        <Badge color={`soft-${color}`} className="rounded-capsule text-uppercase">
          {text}
        </Badge>
        </div>
      );
    };

    if(natural){
      return (
        <>
          <Col lg className="col-xxl-5">
            <DetailRow title="Tipo de Identificación">{data.tipoIdentificacion || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Primer Nombre">{data.nombre1 || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Primer Apellido">{data.apellido1 || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Fecha de Expedición">{data.fechaExpedicion || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Departamento de Expedición">{data.infoDepartamentoExp.split('|')[1] || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Fecha de Nacimiento">{data.fechaNacimiento}</DetailRow>
            <DetailRow title="Departamento de Nacimiento">{data.infoDepartamentoNac.split('|')[1] || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            
            <DetailRow title="Número de Identificación">{data.identificacion || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Segundo Nombre">{data.nombre2 || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Segundo Apellido"> {data.apellido2 || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Pais de Expedición">{data.infoPaisExp.split('|')[1] || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Ciudad/Municipio de Expedición">{data.infoMunicipioExp.split('|')[1] || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Pais de Nacimiento">{data.infoPaisNac.split('|')[1] || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Ciudad/Municipio de Nacimiento">{data.infoMunicipioNac.split('|')[1] || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Estado">{stateFormatter(data.estado)}</DetailRow>
          </Col> 
        </>
      )
    } else {
      return (
        <>
          <Col lg className="col-xxl-5">
            <DetailRow title="Tipo de Identificación">{data.tipoIdentificacion}</DetailRow>
            <DetailRow title="Nombre Comercial">{data.nombreComercial}</DetailRow>
            <DetailRow title="Razon Social">{data.razonSocial}</DetailRow>
          </Col>
          <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            <DetailRow title="Número de Identificación">{data.identificacion}</DetailRow>
            <DetailRow title="Estado">{stateFormatter(data.estado)}</DetailRow>
          </Col> 
        </>
      )
    }
}

export default PersonalInfo;
