const arr = x => Array.from(x);
const num = x => Number(x) || 0;
const str = x => String(x);
const isEmpty = xs => xs.length === 0;
const take = n => xs => xs.slice(0, n);
const drop = n => xs => xs.slice(n);
const reverse = xs => xs.slice(0).reverse();
const comp = f => g => x => f(g(x));
const not = x => !x;
const chunk = n => xs =>
    isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
let numToWords = n => {

    let a = [
        '', 'uno', 'dos', 'tres', 'cuatro',
        'cinco', 'seis', 'siete', 'ocho', 'nueve',
        'diez', 'once', 'doce', 'trece', 'catorce',
        'quince', 'dieciseis', 'diecisiete', 'dieciocho', 'diecinueve'
    ];

    let l = [
        '', 'ciento ', 'dos', 'tres', 'cuatro',
        'quinientos ', 'seis', 'sete', 'ocho', 'nove'
    ];

    let b = [
        '', '', 'veinte', 'treinta', 'cuarenta',
        'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'
    ];

    let bb = [
        '', '', 'veinti', 'treinta y ', 'cuarenta y ',
        'cincuenta y ', 'sesenta y ', 'setenta y ', 'ochenta y ', 'noventa y '
    ];

    let g = [
        '', 'mil', 'millones', 'billones', 'trillones', 'cuatrillones',
        'quintillones', 'sextillones', 'septillones', 'octillones', 'nonilliones'
    ];

    // this part is really nasty still
    // it might edit this again later to show how Monoids could fix this up
    let makeGroup = ([ones, tens, huns]) => {
        return [
            num(huns) === 0 ? '' : huns == 1 || huns == 5 ? l[huns] : l[huns] + 'cientos ',
            num(ones) === 0 ? b[tens] : b[tens] && bb[tens] || '',
            a[tens + ones] || a[ones]
        ].join('');
    };

    let thousand = (group, i) => group === '' ? group : `${group} ${g[i]}`;

    if (typeof n === 'number')
        return numToWords(String(n));
    else if (n === '0')
        return 'zero';
    else
        return comp(chunk(3))(reverse)(arr(n))
            .map(makeGroup)
            .map(thousand)
            .filter(comp(not)(isEmpty))
            .reverse()
            .join(' ');
};

export default numToWords