export const normalizeReqBody = (data, sesion, create) => ( {
  sesion: sesion,
  id: data.id || '',
  terceroInformacion: data.SelectTipoDocumento !== "NIT" ? {
    identificacion: data.NumeroIdentiicacion,
    tipoIdentificacion: data.SelectTipoDocumento,
    nombre1: data.PrimerNombre?.toUpperCase(),
    nombre2: data.SegundoNombre?.toUpperCase(),
    apellido1: data.PrimerApellido?.toUpperCase(),
    apellido2: data.SegundoApellido?.toUpperCase(),
    fechaExpedicion: data.FechaExpedicion,
    idPaisExp: Number(data.paisexpedicion),
    razonSocial: data.RazonSocial?.toUpperCase() || "",
    nombreComercial: data.NombreComercial?.toUpperCase() || "",
    idDepartamentoExp: data.departamentoexpedicion.value ? Number(data.departamentoexpedicion.value) : Number(data.departamentoexpedicion) ,
    idMunicipioExp: Number(data.ciudadmunicipioexpedicion),
    fechaNacimiento: data.FechaNacimiento,
    idPaisNac: Number(data.paisnacimiento),
    idDepartamentoNac: data.departamentonacimiento.value ? Number(data.departamentonacimiento.value) : Number(data.departamentonacimiento),
    idMunicipioNac: Number(data.ciudadmunicipionacimiento),
    estado: create ? "1" : data.EstadoPerson === "1" || data.EstadoPerson === true ? "1" : "0"
  } : {
    identificacion: data.NumeroIdentiicacion,
    tipoIdentificacion: data.SelectTipoDocumento,
    nombre1: "",
    nombre2: "",
    apellido1: "",
    apellido2: "",
    fechaExpedicion: "0000-00-00",
    idPaisExp: "",
    razonSocial: data.RazonSocial?.toUpperCase(),
    nombreComercial: data.NombreComercial?.toUpperCase(),
    idDepartamentoExp: "",
    idMunicipioExp: "",
    fechaNacimiento: "0000-00-00",
    idPaisNac: "",
    idDepartamentoNac: "",
    idMunicipioNac: "",
    estado: create ? "1" : data.EstadoPerson === "1" || data.EstadoPerson === true ? "1" : "0"
  },
  terceroRol: data.propiedades.map(rol => ( {idRol: Number(rol.IdPropiedad)} )),
  terceroUbicacion: {
    idPaisRes: Number(data.paisresidencia),
    idDepartamentoRes: data.departamentoresidencia.value ? Number(data.departamentoresidencia.value) : Number(data.departamentoresidencia),
    idMunicipioRes: Number(data.ciudadmunicipioresidencia),
    correo: data.CorreoElectronico?.toUpperCase(),
    telefono1: data.TelefonoCelular,
    telefono2: data.TelefonoFijo,
    telefono3: data.TelefonoAlterno,
    direccionRes: data.DireccionResidencia?.toUpperCase(),
   },
  terceroClasificacion: {
    idActividad1: Number(data.ActividadPrincipal),
    idActividad2: Number(data.ActividadSecundaria),
    terceroResponsabilidad: data.Responsabilidades.map(resp => ( {idResponsabilidad: Number(resp.IdResponsabilidad)} )),
  },
  terceroRepresentacion: {
    identificacionRep: data.NumeroidentificacionRep || "",
    tipoIdentificacionRep: data.SelectTipoDocumentoRep || "",
    nombre1Rep: data.PrimerNombreRep?.toUpperCase() || "",
    nombre2Rep: data.SegundoNombreRep?.toUpperCase() || "",
    apellido1Rep: data.PrimerApellidoRep?.toUpperCase() || "",
    apellido2Rep: data.SegundoApellidoRep?.toUpperCase() || "",
    fechaInicioRep: data.FechaInicioRepresentacionRep || "0000-00-00",
    correoRep: data.CorreoElectronicoRep?.toUpperCase() || "",
    telefono1Rep: data.TelefonoCelularRep || "",
    telefono2Rep: data.TelefonoAlternoRep || "", 
  },
  terceroFinanciera: data.terceroFinanciera.map(bill => ({
    idBanco: Number(bill.IdBanco),
    tipoCuenta: bill.IdTipoCuenta,
    numeroCuenta: bill.NumeroCuenta,
    estado: "1"
  }) )
})

export const parsePerson = (data) => ( {
  sesion: data.sesion,
  id: data.id,
  SelectTipoDocumento: data.terceroInformacion.tipoIdentificacion,
  NumeroIdentiicacion: data.terceroInformacion.identificacion,
  PrimerNombre: data.terceroInformacion.nombre1,
  SegundoNombre: data.terceroInformacion.nombre2,
  PrimerApellido: data.terceroInformacion.apellido1,
  SegundoApellido: data.terceroInformacion.apellido2,
  FechaExpedicion: data.terceroInformacion.fechaExpedicion,
  paisexpedicion: data.terceroInformacion.idPaisExp,
  RazonSocial: data.terceroInformacion.razonSocial,
  NombreComercial: data.terceroInformacion.nombreComercial,
  departamentoexpedicion: { value: data.terceroInformacion.idDepartamentoExp, identificador: data.terceroInformacion.infoDepartamentoExp.split("|")[0] },
  ciudadmunicipioexpedicion: data.terceroInformacion.idMunicipioExp,
  FechaNacimiento: data.terceroInformacion.fechaNacimiento,
  paisnacimiento: data.terceroInformacion.idPaisNac,
  departamentonacimiento: { value: data.terceroInformacion.idDepartamentoNac, identificador: data.terceroInformacion.infoDepartamentoNac.split("|")[0] },
  ciudadmunicipionacimiento: data.terceroInformacion.idMunicipioNac,
  estado: data.terceroInformacion.estado,
  propiedades: data.terceroRol.map(rol => ({ IdPropiedad: rol.idRol, Propiedad: rol.infoRol })),
  paisresidencia: data.terceroUbicacion.idPaisRes,
  departamentoresidencia: { value: data.terceroUbicacion.idDepartamentoRes, identificador: data.terceroUbicacion.infoDepartamentoRes.split("|")[0] },
  ciudadmunicipioresidencia: data.terceroUbicacion.idMunicipioRes,
  CorreoElectronico: data.terceroUbicacion.correo,
  TelefonoCelular: data.terceroUbicacion.telefono1,
  TelefonoFijo: data.terceroUbicacion.telefono2,
  TelefonoAlterno: data.terceroUbicacion.telefono3,
  ActividadPrincipal: Number(data.terceroClasificacion.idActividad1),
  ActividadSecundaria: Number(data.terceroClasificacion.idActividad2),
  ActividadPrincipalLabel: data.terceroClasificacion.infoActividad1,
  ActividadSecundariaLabel: data.terceroClasificacion.infoActividad2,
  Responsabilidades: data.terceroClasificacion.terceroResponsabilidad.map(res => ({ IdResponsabilidad: res.idResponsabilidad, Responsabilidad: res.infoResponsabilidad.split("|")[1] })),
  NumeroidentificacionRep: data.terceroRepresentacion.identificacionRep,
  SelectTipoDocumentoRep: data.terceroRepresentacion.tipoIdentificacionRep,
  PrimerNombreRep: data.terceroRepresentacion.nombre1Rep,
  SegundoNombreRep: data.terceroRepresentacion.nombre2Rep,
  PrimerApellidoRep: data.terceroRepresentacion.apellido1Rep,
  SegundoApellidoRep: data.terceroRepresentacion.apellido2Rep,
  FechaInicioRepresentacionRep: data.terceroRepresentacion.fechaInicioRep,
  CorreoElectronicoRep: data.terceroRepresentacion.correoRep,
  TelefonoCelularRep: data.terceroRepresentacion.telefono1Rep,
  TelefonoAlternoRep: data.terceroRepresentacion.telefono2Rep,
  terceroFinanciera: data.terceroFinanciera.map(acc => ({
    IdBanco: acc.idBanco,
    IdTipoCuenta: acc.tipoCuenta,
    NumeroCuenta: acc.numeroCuenta,
    Banco: acc.infoBanco,
    TipoCuenta: acc.tipoCuenta
  })),
  EstadoPerson: data.terceroInformacion.estado,
  DireccionResidencia: data.terceroUbicacion.direccionRes
})