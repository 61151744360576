import React from 'react';
import { Row, Col, Media } from 'reactstrap';
import Flex from '../../common/Flex';

const ListItem = ({
    title
}) => {

    return (
    <Row className="align-items-center py-2 border-bottom border-200">
        <Col className="py-1">
            <Media className="align-items-center">
            <Media body className="position-relative">
                <Flex tag="h6" align="center" className="mb-0">
                <a className="text-800 stretched-link" href="#!">
                    {title}
                </a>
                </Flex>
            </Media>
            </Media>
        </Col>
    </Row>
    )
}

export default ListItem;
