export const CREDIT_MODE_ID = {
    NEW: '1',
    UPDATE: '2',
    UNIFICATION: '3',
    REFINANCE: '4',
};

export const CREDIT_MODE = {
    NEW: 'NEW',
    UPDATE: 'UPDATE',
    UNIFICATION: 'UNIFICATION'
}

export const MODAL_MODE = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
}

export const MODAL_VIEW = {
    WRITE: 'WRITE',
    READONLY: 'READONLYU'
}

export const CREDIT_STATE_ID = {
    ALL: '0',
    ACTIVE: '2',
    CLOSED: '3'
}