import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getCreditsByClient = (idClinet, state) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/cli/${sesionToken}/${state || '2'}/${idClinet}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getClientCrediResume = (idClinet) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/dash/${sesionToken}/${idClinet}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getCreditsByPagaduria = (idPag) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/pag/${sesionToken}/2/${idPag}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getCreditPayments = (id) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/pago/${sesionToken}/${id}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getCreditProyeccion = (id) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/proyeccion/${sesionToken}/${id}/1`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const setInitCredit = (data) => {
    if(!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken
    return fetch(`${urlApi}/recaudo/rapido`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const creditServices = {
    getCreditsByClient,
    getCreditsByPagaduria,
    getClientCrediResume,
    getCreditPayments,
    getCreditProyeccion,
    setInitCredit
}