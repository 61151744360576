import React, { useState, useContext, useEffect } from 'react';
import { Row, Spinner } from 'reactstrap';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CreditInfo from './sections/CreditInfo';
import { StateContext } from './StateContext';
import CreditGeneralData from './sections/CreditGeneralData'
import { currencyFormat } from '../../helpers/tableFormatters';
import MainTable from '../../components/tables/MainTable';
import { creditServices } from '../../services';

const CreditDetails = () => {
  const context = useContext(StateContext);
  const { loading, creditSelected: selected } = context;
  const [paymentsPlan, setPaymentsPlan] = useState([]);

  useEffect(() => {
    if(!selected) return;
    getCreditPlan();
  }, [selected])

  const columns = [
    {
        dataField: 'periodo',
        text: 'Cuota',
        headerClasses: 'border-0 text-left',
        classes: 'border-0 py-2 text-left align-middle',
    },
    {
        dataField: 'fechaCorte',
        headerClasses: 'border-0 text-left',
        text: 'Fecha Corte',
        classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha Pago',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'valorRecaudo',
      headerClasses: 'border-0 text-right',
      text: 'Valor Pagado',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'abonoInteres',
      headerClasses: 'border-0 text-right',
      text: 'Interés',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'abonoCapital',
      headerClasses: 'border-0 text-right',
      text: 'Capital',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'descuento',
      headerClasses: 'border-0 text-right',
      text: 'Descuento',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'saldoProyectado',
      headerClasses: 'border-0 text-right pr-3',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle pr-3',
    },
  ]

  const getCreditPlan = async () => {
    const resp = await creditServices.getCreditPayments(selected.prestamo);
    if(resp.error) return;
    setPaymentsPlan(resp.objResponse)
  }

  if (loading) {
    return (
      <Row className="py-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </Row>
    )
  }

  return (
    <>
    <ContentWithAsideLayout aside={<CreditGeneralData/>} isStickyAside={false}>
      <CreditInfo />
    </ContentWithAsideLayout>
    <MainTable 
      loading={!paymentsPlan}
      data={paymentsPlan}
      columns={columns}
      keyField="periodo"
      headerClassName="d-none"
      pageSize={12}
      className='pr-4'
      noSearch
      noAdd
    />
    </>
  )
}

export default CreditDetails;