import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import {
  Badge,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover
} from 'reactstrap';

const PagaduriasResumen = ({selected, handleChange}) => {
  const [ mainPag, setMainPag ] = useState();
  const { register } = useForm();

  useEffect(() => {
    if(!selected.pagaduriaPpal) return;
    const defaultPag = selected.asesorPagaduria.filter(pag => pag.pagaduriaPpal === 1 )[0]?.idPagaduria
    if(!defaultPag) return;
    setMainPag(defaultPag)
  }, [])

  const onSubmit = (e) => {
    const { mainPagaduria } = e.currentTarget;
    setMainPag(Number(mainPagaduria.value))
    handleChange(mainPagaduria.value, selected.idAsesor);
}

  return (
    <Fragment>
      <Badge
        id={`pop_${selected.idAsesor}`}
        color="soft-success" 
        className="rounded-capsule btn"
      >
        +{selected.cntPagadurias}
      </Badge>
      <UncontrolledPopover
        target={`pop_${selected.idAsesor}`}
        trigger="legacy"
      >
        <PopoverHeader>
          <h5>Pagadurías Permitidas</h5>
          <small className='font-weight-light'>Seleccione la Pagaduría principal del Asesor</small>
        </PopoverHeader>
        <PopoverBody>
          <form onChange={(e) => onSubmit(e)}>
            <div className='d-flex flex-column mb-2'>
              {selected.asesorPagaduria.map(item => (
                <label
                  htmlFor={`pag_pop_${item.idPagaduria}`}
                  className='d-flex align-items-center p-2 pr-5 border-bottom m-0'
                >
                  <input
                    id={`pag_pop_${item.idPagaduria}`}
                    name='mainPagaduria'
                    className='mr-2'
                    value={item.idPagaduria}
                    type='radio'
                    checked={item.idPagaduria === mainPag}
                    ref={register}
                  />
                  <span>{item.infoPagaduria}</span>
                </label>
              )) }
            </div>
          </form>
        </PopoverBody>
      </UncontrolledPopover>
    </Fragment>
  );
}

export default PagaduriasResumen
