import React, { useState } from 'react';
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Row,
  UncontrolledDropdown
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ButtonIcon from '../../common/ButtonIcon';
import FalconCardHeader from '../../common/FalconCardHeader';
import Loader from '../../common/Loader';


const SingleDetailsCard = ({
    title,
    data,
    handleEditAction,
    sectionKey,
    step,
    children,
    style={},
}) => {
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)

    return (
      <Card className={`mb-3`} style={{...style}}>
        <FalconCardHeader title={title}>
          <ButtonIcon onClick={() => handleEditAction(title, step, data)} tag={Link} color="falcon-default" size="sm" icon="pencil-alt" to="#!" iconClassName="fs--2">
            Editar
          </ButtonIcon>
        </FalconCardHeader>
        <CardBody className="bg-light border-top px-4">
          {loading ? (
            <Loader />
          ) : (
            <Row>
              {children}
            </Row>
          )}
        </CardBody>
      </Card>
    )
}

export default SingleDetailsCard;
