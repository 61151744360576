import React, { useContext, useState, Fragment, useEffect } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faSitemap, faEye } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import FormIdentificacion from './formSteps/FormIdentificacion';
import FormPropiedades from './formSteps/FormPropiedades';
import FormUbicacion from './formSteps/FormUbicacion';
import FormClasificacion from './formSteps/FormClasificacion';
import FormRepresentacion from './formSteps/FormRepresentacion';
import FormFinanciera from './formSteps/FormFinanciera';
import AppContext, { AuthWizardContext } from '../../context/Context';
import WizardModal from '../auth/wizard/WizardModal';
import ButtonIcon from '../common/ButtonIcon';
import { worldServices, generalServices } from '../../services';
import cogoToast from 'cogo-toast';

const PersonForm = ({handleSubmitForm, editing, editingStep, selected, extraInfo}) => {
  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AuthWizardContext);
  const { register, handleSubmit, errors, watch, setValue } = useForm();

  const [modal, setModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [objectIdentificacion, setIdentificacion] = useState({});
  const [objectPropiedades, setPropiedades] = useState({});
  const [objectUbicacion, setUbicacion] = useState({});
  const [objectClasificacion, setClasificacion] = useState({});
  const [objectRepresentacion, setRepresentacion] = useState({});
  const [objectFinanciera, setFinanciera] = useState({});

  const onSubmitData = data => {
    let currentFormData = data;

    if(step === 2){
      currentFormData = { propiedades: objectPropiedades.ArrayPropiedades || [] }
      if(currentFormData.propiedades.length < 1){
        cogoToast.warn('Por favor seleccione por lo menos una propiedad.', {
          position: 'bottom-right',
          heading: 'Requerido'
        });
        return;
      }
    }

    if(step === 4){
      currentFormData = { ...data, Responsabilidades: objectClasificacion.ArrayClasificacion || [] }
      if(objectClasificacion.ActividadPrincipal){
        currentFormData.ActividadPrincipal = objectClasificacion.ActividadPrincipal
      }

      if(objectClasificacion.ActividadSecundaria){
        currentFormData.ActividadSecundaria = objectClasificacion.ActividadSecundaria
      }

      if(currentFormData.Responsabilidades.length < 1){
        cogoToast.warn('Por favor seleccione por lo menos una responsabilidad.', {
          position: 'bottom-right',
          heading: 'Requerido'
        });
        return;
      }
    }

    if(step === 6 || (objectIdentificacion?.Identificacion !== "NIT" && step === 5)){
      currentFormData = { terceroFinanciera: objectFinanciera.ArrayFinanciera || [] }
    }

    setUser({ ...user, ...currentFormData });

    if(step === 6 || editing || (objectIdentificacion?.Identificacion !== "NIT" && step === 5)){
      handleSubmitForm({ ...user, ...currentFormData })
      return
    }
    setStep(step + 1);
  };

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    editingStep && setStep(editingStep)
  }, [editingStep])

  const init = async () => {
    await loadRoles();
    await loadCountries();
    if(selected){
      setUser({...selected})
      setIdentificacion({
        PaisExpedicion: selected.paisexpedicion,
        PaisNacimiento: selected.paisnacimiento,
        DepartamentoExpedicion: selected.departamentoexpedicion,
        DepartamentoExpedicion: selected.departamentoexpedicion,
        DepartamentoNacimiento: selected.departamentonacimiento,
        DepartamentoNacimiento: selected.departamentonacimiento,
        CiudadExpedicion: selected.ciudadmunicipioexpedicion,
        CiudadNacimiento: selected.ciudadmunicipionacimiento,
        Identificacion: selected.SelectTipoDocumento,
        Estado: selected.EstadoPerson,
      })
      setUbicacion({
        PaisResidencia: selected.paisresidencia,
        DepartamentoResidencia: selected.departamentoresidencia,
        CiudadResidencia: selected.ciudadmunicipioresidencia
      })
      setRepresentacion({
        IdentificacionRep: selected.SelectTipoDocumentoRep
      })
    }
  }

  const toggle = () => setModal(!modal);

  const handleBackStep = targetStep => {
    if (step !== 7) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  const loadCountries = async () => {
    const res = await worldServices.getAllCountries();
    if(res.error) return;
    const parsedCountries = res.map(c => ({
      value: c.id,
      label: `${c.pais} - ${c.nombre}`,
      identificador: c.pais
    }))
    setCountries(parsedCountries);
  }

  const loadRoles = async () => {
    const res = await generalServices.getAllRoles();
    if(res.error) return;
    const parsedRoles = res.map(role => ({
      value: role.id,
      label: role.nombre,
    }))
    setRoles(parsedRoles);
  }


  const cambiarselectpais = data2 => {
    setIdentificacion({...objectIdentificacion, PaisExpedicion: data2})
  };

  const cambiarselectdepartamento = data3 => {
    setIdentificacion({...objectIdentificacion, DepartamentoExpedicion: data3})
  };

  const cambiarselectciudades = data4 => {
    setIdentificacion({...objectIdentificacion, CiudadExpedicion: data4})
  }

  const cambiarselectpais2 = data5 => {
    setIdentificacion({...objectIdentificacion, PaisNacimiento: data5})
  }

  const cambiarselectdepartamento2 = data6 => {
    setIdentificacion({...objectIdentificacion, DepartamentoNacimiento: data6})
  }

  const cambiarselectciudad2 = data7 => {
    setIdentificacion({...objectIdentificacion, CiudadNacimiento: data7})
  }

  const cambiarselect = data8 => {
    setIdentificacion({...objectIdentificacion, Identificacion: data8})
  }

  const changePersonState = value => {
    setIdentificacion({...objectIdentificacion, Estado: value})
  }

  const agregarcampo = data9 => {
    objectPropiedades.ArrayPropiedades = data9;
    setPropiedades(objectPropiedades)
    
  }

  const cambiarselectpais3 = data10 => {
    setUbicacion({...objectUbicacion, PaisResidencia: data10})
  }
  const cambiarselectdepartamento3 = data11 => {
    setUbicacion({...objectUbicacion, DepartamentoResidencia: data11})
  }
  const cambiarselectciudad3 = data12 => {
    setUbicacion({...objectUbicacion, CiudadResidencia: data12})
  }

  const cmabiarselectact1 = value => {
    setClasificacion({...objectClasificacion, ActividadPrincipal: value})
  }

  
  const cmabiarselectact2 = value => {
    setClasificacion({...objectClasificacion, ActividadSecundaria: value})
  }

  const agregarcampo2 = data13 => {
    objectClasificacion.ArrayClasificacion = data13;
    setClasificacion(objectClasificacion)
  }

  const cambiarselect2 = data14 => {
    setRepresentacion({...objectRepresentacion, IdentificacionRep: data14})
  }
  const agregarcampo3 = data15 => {
    objectFinanciera.ArrayFinanciera = data15;
    setFinanciera(objectFinanciera)
  }

  

  const limpiar = data16 => {
    objectIdentificacion.PaisExpedicion= "";
    objectIdentificacion.DepartamentoExpedicion= "";
    objectIdentificacion.CiudadExpedicion= "";
    objectIdentificacion.PaisNacimiento= "";
    objectIdentificacion.DepartamentoNacimiento= "";
    objectIdentificacion.CiudadNacimiento= "";
    objectIdentificacion.Identificacion= "";
    setIdentificacion(objectIdentificacion)
    objectPropiedades.ArrayPropiedades= "";
    setPropiedades(objectPropiedades)
    objectUbicacion.PaisResidencia = "";
    objectUbicacion.DepartamentoResidencia= "";
    objectUbicacion.CiudadResidencia= "";
    setUbicacion(objectUbicacion)
    objectClasificacion.ArrayClasificacion= "";
    setClasificacion(objectClasificacion)
    objectRepresentacion.IdentificacionRep= "";
    setRepresentacion(objectRepresentacion)
    objectFinanciera.ArrayFinanciera = "";
    setFinanciera(objectFinanciera)
  }
  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        {!editing && (
        <CardHeader className="bg-light">
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="user" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Identificación</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon={faBuilding} />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Roles</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 3,
                  active: step === 3
                })}
                onClick={() => handleBackStep(3)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="map-marker-alt" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Ubicación</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 4,
                  active: step === 4
                })}
                onClick={() => handleBackStep(4)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon={faSitemap} />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Clasificacion</span>
              </NavLink>
            </NavItem>
            {objectIdentificacion?.Identificacion === "NIT" && (
              <NavItem>
                <NavLink
                  className={classNames('font-weight-semi-bold', {
                    'done  cursor-pointer': step > 5,
                    active: step === 5 && objectIdentificacion?.Identificacion === "NIT"
                  })}
                  onClick={() => handleBackStep(5)}
                >
                  <span className="nav-item-circle-parent">
                    <span className="nav-item-circle">
                      <FontAwesomeIcon icon={faEye} />
                    </span>
                  </span>
                  <span className="d-none d-md-block mt-1 fs--1">Representacion</span>
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className={classNames('font-weight-semi-bold', {
                  'done  cursor-pointer': step > 6,
                  active: step === 6 || (objectIdentificacion?.Identificacion !== "NIT" && step === 5)
                })}
                onClick={() => handleBackStep(objectIdentificacion?.Identificacion === "NIT" ? 5 : 6)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="dollar-sign" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Financiera</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        )}
        <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
          {step === 1 && <FormIdentificacion register={register}
          countries={countries}
          cambiarselectdepartamento={(data) => cambiarselectdepartamento(data)}
          cambiarselectciudades={(data) => cambiarselectciudades(data)}
          cambiarselectpais={(data) => cambiarselectpais(data)} 
          cambiarselectpais2={(data) => cambiarselectpais2(data)}
          cambiarselectdepartamento2={(data) => cambiarselectdepartamento2(data)}
          cambiarselectciudad2={(data) => cambiarselectciudad2(data)}
          cambiarselect={(data) => cambiarselect(data)}
          DepartamentoExpedicion={objectIdentificacion.DepartamentoExpedicion}
          CiudadExpedicion={objectIdentificacion.CiudadExpedicion}
          PaisExpedicion={objectIdentificacion.PaisExpedicion} 
          PaisNacimiento={objectIdentificacion.PaisNacimiento}
          DepartamentoNacimiento={objectIdentificacion.DepartamentoNacimiento}
          CiudadNacimiento={objectIdentificacion.CiudadNacimiento}
          Identificacion ={objectIdentificacion.Identificacion}
          Estado={objectIdentificacion.Estado}
          errors={errors} 
          watch={watch}
          editing={editing}
          extraInfo={extraInfo}
          changePersonState={changePersonState}
          />}

          {step === 2 && <FormPropiedades
          agregarcampo={(data) => agregarcampo(data)}
          ArrayPropiedades={objectPropiedades.ArrayPropiedades}
          register={register} errors={errors} editing={editing}
          extraInfo={extraInfo} roles={roles} selected={selected} step={step} />}




          {step === 3 && <FormUbicacion
          countries={countries}
          cambiarselectdepartamento3={(data) => cambiarselectdepartamento3(data)}
          cambiarselectciudad3={(data) => cambiarselectciudad3(data)}
          cambiarselectpais3={(data) => cambiarselectpais3(data)} 
          PaisResidencia={objectUbicacion.PaisResidencia}
          DepartamentoResidencia={objectUbicacion.DepartamentoResidencia}
          CiudadResidencia={objectUbicacion.CiudadResidencia}
          register={register} errors={errors} editing={editing}
          extraInfo={extraInfo}  />}


          {step === 4 && <FormClasificacion 
          agregarcampo2={(data) => agregarcampo2(data)}
          ArrayClasificacion={objectClasificacion.ArrayClasificacion}
          cmabiarselectact1={cmabiarselectact1}
          cmabiarselectact2={cmabiarselectact2}
          ActividadPrincipal={objectClasificacion.ActividadPrincipal}
          ActividadSecundaria={objectClasificacion.ActividadSecundaria}
          register={register} errors={errors} setValue={setValue} editing={editing} selected={selected} extraInfo={extraInfo}  />}



          {((objectIdentificacion?.Identificacion === "NIT" && step === 5) || (editing && step === 5) ) && <FormRepresentacion 
          cambiarselect2 ={(data) => cambiarselect2(data)}
          IdentificacionRep={objectRepresentacion.IdentificacionRep}  
          register={register} errors={errors} />}

          {((objectIdentificacion?.Identificacion !== "NIT" && step === 5 && !editing ) || step === 6) && <FormFinanciera
          agregarcampo3 ={(data) => agregarcampo3(data)}
          ArrayFinanciera={objectFinanciera.ArrayFinanciera}
          register={register} errors={errors} editing={editing} selected={selected}
          objectIdentificacion={objectIdentificacion}/>}

        </CardBody>
        <CardFooter className={classNames('px-md-6 bg-light', { 'd-none': step === 7, ' d-flex': step < 7 })}>
          {!editing && (
            <ButtonIcon
              color="link"
              icon={isRTL ? 'chevron-right' : 'chevron-left'}
              iconAlign="left"
              transform="down-1 shrink-4"
              className={classNames('px-0 font-weight-semi-bold', { 'd-none': step === 1 })}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Volver
            </ButtonIcon>
          )}

          <ButtonIcon
            color="primary"
            className="ml-auto px-5"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
          >
            {step === 6 || editing ? "Guardar" : "Siguiente"}
          </ButtonIcon>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default PersonForm;
