import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonIcon from '../../components/common/ButtonIcon';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RecaudoInvoice from '../recaudar/invoice/RecaudoInvoice';

function ModalSuccess({ open, onOk, data: invoiceProps, egreso }) {

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader
        title="Operación exitosa"
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={onOk}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="text-center mt-4">
          <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" />
          <h6 className="fs-2 m-3">
            Completado!
          </h6>
          <p className="fs--1 mb-0"></p>
          {invoiceProps && invoiceProps.checkCash && (
            <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
              <PDFDownloadLink document={<RecaudoInvoice egreso title={`${egreso ? 'Comprobante de egreso' : 'Recibo de caja'}`} data={invoiceProps} />} fileName={`${egreso ? 'comprobante_egreso_': 'recibo_caja_'}${invoiceProps.serial}.pdf`} >
                {({ blob, url, loading, error }) =>
                  loading ? `Cargando ${egreso ? 'comprobante' : 'recibo'}...` : `Descargar ${egreso ? 'comprobante de egreso' : 'recibo de caja'}`
                }
              </PDFDownloadLink>
            </ButtonIcon>
          )}
        </div>
      </ModalBody >
      <ModalFooter className="no-border">
        <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
          Aceptar
        </Button>
      </ModalFooter>
    </Modal >
  )
}

export default ModalSuccess

