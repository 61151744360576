import React, { useState, useEffect } from 'react';
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import MainTable from '../../../components/tables/MainTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyFormat } from '../../../helpers/tableFormatters';


function ModalCredits({ open, title, toggle, data }) {

  const columns = [
    {
      dataField: 'prestamo',
      text: '#',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0 text-left',
      classes: 'border-0 py-2 text-left align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
    },
    {
      dataField: 'fecha',
      headerClasses: 'border-0 text-left',
      text: 'Fecha',
      classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'saldopro',
      headerClasses: 'border-0 text-right pr-3',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 pr-3 text-right align-middle',
    }
  ]

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div>{fieldSelected.credito}</div>
        <div className='text-primary' style={{fontSize: '0.8em'}}>{fieldSelected.nombreTercero}</div>
      </div>
    )
  }

  return (
    <Modal size="lg" isOpen={open} toggle={toggle}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={toggle}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        {title}
      </ModalHeader>
      <ModalBody className="p-0">
            <MainTable
            title="Créditos"
            data={data || []}
            columns={columns}
            keyField="prestamo"
            pageSize={12}
            className='mb-0 shadow-none'
            headerClassName='bg-light'
            noAdd
            noSearch
          />
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
            <Button onClick={toggle} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
                Aceptar
            </Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default ModalCredits
