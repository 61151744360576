import React, { useContext } from 'react';
import { Row, Spinner } from 'reactstrap';
import ContentWithAsideLayout from '../../../layouts/ContentWithAsideLayout';
// import CreditInfo from './sections/CreditInfo';
import ClientInfo from '../../recaudar/sections/ClientInfo';
import LawyerInfo from '../../recaudar/sections/LawyerInfo';
// import ObservationsForm from './sections/ObservationsForm';
// import GeneralConditionsInfo from './sections/GeneralConditionsInfo';
import { PrejuridicoContext } from './PrejuridicoContext';
import CostosSection from './sections/CostosSection';
import CreditCostInfo from './sections/CreditCostInfo';
import GeneralInfo from './sections/GeneralInfo';

const PrejuridicoDetails = ({ context: inputContext }) => {
  const context = useContext(inputContext);
  const { loading } = context;
  
  if(loading){
    return (
      <Row className="py-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </Row>
    )
  }

  return (
    <ContentWithAsideLayout aside={<CostosSection  context={inputContext}/>} isStickyAside={false}>
       <ClientInfo 
        context={inputContext} 
        title="Datos del Demandado" 
        btnText="Buscar Demandado"
        modalTitle="Buscar Demandado"
        terceros 
        noCancel  
      />
      <LawyerInfo 
        context={inputContext} 
        title="Datos del Abogado Apoderado" 
        btnText="Buscar Abogado" 
        modalTitle="Buscar Abogado"
        terceros
        noCancel  
      />
      <CreditCostInfo context={inputContext}  />
      <GeneralInfo context={inputContext} />
    </ContentWithAsideLayout>
  )
}

export default PrejuridicoDetails