import React, { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Row, CustomInput } from 'reactstrap';
import FormGroup from 'reactstrap/lib/FormGroup';
import { usePersonFilter } from '../GestionPersonaEmpresa/context';
import ButtonIcon from '../common/ButtonIcon';

const MainTableFilter = ({handleFilter}) => {
    const { register, setValue, getValues } = useForm();
    const { stateFilter, rolesFilter, metadataFilters, saveFilterState, saveFilterRoles } = usePersonFilter();

    useEffect(() => {
        setValue("stateFilter", stateFilter);
        setValue("rolesFilter", rolesFilter);
    }, [])

    const onSubmit = (e) => {
        const { stateFilter, rolesFilter } = e.currentTarget
        saveFilterState(stateFilter.value)
        saveFilterRoles(rolesFilter.value)
        handleFilter(stateFilter.value, rolesFilter.value)
    }

    return (
        <div className="filters-container">
            <form className="form-filters" onChange={(e) => onSubmit(e)}>
                <div className="filters-section filters-section-state">
                    <label className="filter-label" htmlFor="state-all">
                        <input
                            name="stateFilter"
                            type="radio"
                            value="1,0"
                            ref={register}
                            id="state-all"
                        />
                        <span className="inside-label states">
                            <span className="inside-label-title">estado</span>
                            <div className="inside-label-text">
                                Todos { metadataFilters && <span className="label-count">{metadataFilters.todos}</span> }
                            </div>
                        </span>
                    </label>

                    <label className="filter-label" htmlFor="state-actives">
                        <input
                            name="stateFilter"
                            type="radio"
                            value="1"
                            ref={register}
                            id="state-actives"
                        />
                        <span className="inside-label states">
                            <div className="inside-label-text">
                                Activos { metadataFilters && <span className="label-count">{metadataFilters.activos}</span> }
                            </div>
                        </span>
                    </label>

                    <label className="filter-label" htmlFor="state-inactives">
                        <input
                            name="stateFilter"
                            type="radio"
                            value="0"
                            ref={register}
                            id="state-inactives"
                        />
                        <span className="inside-label states">
                            <div className="inside-label-text">
                                Inactivos { metadataFilters && <span className="label-count">{metadataFilters.inactivos}</span> }
                            </div>
                        </span>
                    </label>
                </div>
                <div className="filters-section filters-section-roles">
                    <label className="filter-label" htmlFor="rol-all">
                        <input
                            name="rolesFilter"
                            type="radio"
                            value="*"
                            ref={register}
                            id="rol-all"
                        />
                        <span className="inside-label roles">
                            <span className="inside-label-title">rol</span>
                            <div className="inside-label-text">
                                Todos { metadataFilters && <span className="label-count">{
                                    getValues()["stateFilter"] === "1,0" ?
                                        metadataFilters.todos : 
                                        getValues()["stateFilter"] === "1" ?
                                            metadataFilters.activos : metadataFilters.inactivos
                                }</span> }
                            </div>
                        </span>
                    </label>

                    <label className="filter-label" htmlFor="rol-client">
                        <input
                            name="rolesFilter"
                            type="radio"
                            value="1"
                            ref={register}
                            id="rol-client"
                        />
                        <span className="inside-label roles">
                            <div className="inside-label-text">
                                Cliente { metadataFilters && <span className="label-count">{metadataFilters.cliente}</span> }
                            </div>
                        </span>
                    </label>

                    <label className="filter-label" htmlFor="rol-payroll">
                        <input
                            name="rolesFilter"
                            type="radio"
                            value="2"
                            ref={register}
                            id="rol-payroll"
                        />
                        <span className="inside-label roles">
                            <div className="inside-label-text">
                                Pagaduría { metadataFilters && <span className="label-count">{metadataFilters.pagaduria}</span> }
                            </div>
                        </span>
                    </label>

                    <label className="filter-label" htmlFor="rol-seller">
                        <input
                            name="rolesFilter"
                            type="radio"
                            value="3"
                            ref={register}
                            id="rol-seller"
                        />
                        <span className="inside-label roles">
                            <div className="inside-label-text">
                                Asesor { metadataFilters && <span className="label-count">{metadataFilters.vendedor}</span> }
                            </div>
                        </span>
                    </label>

                    <label className="filter-label" htmlFor="rol-provider">
                        <input
                            name="rolesFilter"
                            type="radio"
                            value="4"
                            ref={register}
                            id="rol-provider"
                        />
                        <span className="inside-label roles">
                            <div className="inside-label-text">
                                Proveedor { metadataFilters && <span className="label-count">{metadataFilters.proveedor}</span> }
                            </div>
                        </span>
                    </label>
                </div>
            </form>
        </div>
    )
}

export default MainTableFilter;
