import React, { useState, useEffect, useContext, Fragment } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  CustomInput,
  FormGroup
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CountUp from 'react-countup';
import ButtonIcon from '../../../components/common/ButtonIcon';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecaudoContext } from '../RecaudoContext';
import paymentsType from '../../../data/recaudo/paymentsType';
import { EnvironmentContext } from '../../../context/Context';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RecaudoInvoice from '../invoice/RecaudoInvoice';

function RecaudoLoteModal({ open, toggle, data, onSubmit, success, invoiceProps, onOk }) {
  const environment = useContext(EnvironmentContext);
  const context = useContext(RecaudoContext);
  const { pagSelected } = context;
  const { register, setValue, getValues, errors, watch } = useForm({
    defaultValues: {
      paymentType: 2
    }
  });

  const watchPaymentType = watch("paymentType");
  const [treasuries, setTreasuries] = useState([]);
  const [destAccounts, setDestAccounts] = useState([]);
  const [banks, setBanks] = useState([]);
  const [cashConcepts, setCashConcepts] = useState([]);

  useEffect(() => {
    loadBanks();
    loadTreasuries();
    loadCashConcepts();
    loadBankAccounts();
  }, [environment])

  const loadTreasuries = async () => {
    if(!environment.treasuries) return;
    const tempTreasuries = environment.treasuries.map(treasury => ({
      value: treasury.tesoreria,
      label: treasury.nombre
    }))
    setTreasuries(tempTreasuries);
  }

  const loadBankAccounts = async () => {
    if(!environment.bankAccounts) return;
    const tempDestAccounts = environment.bankAccounts.map(account => ({
      value: account.idCuenta,
      label: `${account.nroCuenta} - ${account.nombreBanco}`
    }))

    setDestAccounts(tempDestAccounts);
  }

  const loadBanks = async () => {
    if(!environment.banks) return;
    const tempBanks = environment.banks.map(bank => ({
      value: bank.id,
      label: bank.nombre
    }))
    setBanks(tempBanks);
  }

  const loadCashConcepts = async () => {
    if(!environment.cashLoteConcepts) return;
    const tempCashConcepts = environment.cashLoteConcepts.map(concept => ({
      value: concept.conceptotesoreria,
      label: concept.nombre
    }))
    setCashConcepts(tempCashConcepts);
  }

  const onChange = async e => {
    let { name, value } = e;
    setValue(name, value);
  }

  const onRecaudo = async () => {
    const generalData = getValues();

    if(!generalData.cashConcept){
      cogoToast.error("Por favor complete los campos requeridos",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    if(watchPaymentType == 1 && !generalData.cashSource){
      cogoToast.error("Por favor complete los campos requeridos para efectivo",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    if(watchPaymentType == 2 && !generalData.destAccount){
      cogoToast.error("Por favor selecciona una cuenta de destino",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    if(watchPaymentType == 3 && (!generalData.chequeBank || !generalData.numCheque )){
      cogoToast.error("Por favor completa la información del cheque",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      return;
    }

    const payload = {
      modalidadPago: watchPaymentType,
      idTesoreria: generalData.cashSource ? Number(generalData.cashSource) :  0,
      conceptoTesoreria: generalData.cashConcept ? Number(generalData.cashConcept) : 0,
      idCuentaDestino: generalData.destAccount ? Number(generalData.destAccount) : 0,
      idBancoCheque: generalData.chequeBank ? Number(generalData.chequeBank) : 0,
      numeroCheque: generalData.numCheque || '',
      observacion: generalData.observations || '',
    }

    onSubmit({...payload});
  }

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => toggle(false)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        <h5>Recaudo en Lote</h5>
        <span className='text-primary' style={{ fontSize: '0.8em' }}>{pagSelected ? pagSelected.nombreComercial : ''}</span>
      </ModalHeader>
      <ModalBody className="p-0">
        {success ? (
          <div className="text-center mt-4">
            <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" />
            <h6 className="fs-2 m-3">
              Recaudo Exitoso!
            </h6>
            <p className="fs--1 mb-0"></p>
            {invoiceProps && invoiceProps.checkCash && (
              <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
                <PDFDownloadLink document={<RecaudoInvoice title="Recibo de caja" data={invoiceProps} />} fileName={`recibo_caja_${invoiceProps.serial}_.pdf`} >
                  {({ blob, url, loading, error }) =>
                    loading ? 'Cargando recibo...' : 'Descargar recibo de caja'
                  }
                </PDFDownloadLink>
              </ButtonIcon>
            )}
          </div>
        ) : (
          <form className='p-3'>
            <Row className="m-0 w-100 justify-content-between p-2 rounded bg-light px-3">
              {paymentsType && paymentsType.filter(payment => payment.id != 1).map(paymentType => (
                <FormGroup
                  tag={CustomInput}
                  type="radio"
                  id={paymentType.name}
                  name="paymentType"
                  label={<strong>{paymentType.name}</strong>}
                  value={paymentType.id}
                  className="m-0"
                  innerRef={register({
                    required: true,
                  })}
                  checked={watchPaymentType == paymentType.id}
                  onChange={({ target }) => onChange(target)}
                >
                </FormGroup>
              ))}
            </Row>
            {watchPaymentType && watchPaymentType == 1 && (
              <Fragment>
              <DetailRow title="Tesorería" className="m-0 mt-4">
                <FormGroup>
                  <WizardInput
                    className="pl-2"
                    type="select"
                    tag={CustomInput}
                    placeholder="Seleccionar"
                    id="cashSource"
                    name="cashSource"
                    options={treasuries}
                    onChange={(e) => onChange({ name: 'cashSource', value: e.target.value })}
                    errors={errors}
                    innerRef={register({
                      required: true,
                    })}
                  />
                </FormGroup>
              </DetailRow>
              </Fragment>
            )}

            {watchPaymentType && watchPaymentType == 2 && (
              <>
                <DetailRow title="Cuenta y Banco Destino" className="m-0 mt-4">
                  <FormGroup>
                    <WizardInput
                      className="pl-2"
                      type="select"
                      tag={CustomInput}
                      placeholder="Seleccionar"
                      id="destAccount"
                      name="destAccount"
                      options={destAccounts}
                      onChange={(e) => onChange({ name: 'destAccount', value: e.target.value })}
                      errors={errors}
                      innerRef={register({
                        required: true,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
              </>
            )}
            {watchPaymentType && watchPaymentType == 3 && (
              <>
                <DetailRow title="Banco" className="m-0 mt-4">
                  <FormGroup>
                    <WizardInput
                      className="pl-2"
                      type="select"
                      tag={CustomInput}
                      placeholder="Seleccionar"
                      id="chequeBank"
                      name="chequeBank"
                      options={banks}
                      onChange={(e) => onChange({ name: 'chequeBank', value: e.target.value })}
                      errors={errors}
                      innerRef={register({
                        required: true,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
                <DetailRow title="Numero del Cheque" className="m-0">
                  <FormGroup>
                    <Input
                      id="numCheque"
                      name="numCheque"
                      onChange={(e) => onChange({ name: 'numCheque', value: e.target.value }, true)}
                      {...register("numCheque", {
                        required: true,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
              </>
            )}
            <DetailRow title="Concepto" className="m-0">
              <FormGroup>
                <WizardInput
                  className="pl-2"
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="cashConcept"
                  name="cashConcept"
                  options={cashConcepts}
                  onChange={(e) => onChange({ name: 'cashConcept', value: e.target.value })}
                  errors={errors}
                  innerRef={register({
                    required: true,
                  })}
                />
              </FormGroup>
            </DetailRow>
            <DetailRow title="Observaciones" className="mt-3 mx-2">
              <FormGroup>
                <Input
                  type="textarea"
                  id="observations"
                  maxLength={300}
                  onChange={(e) => onChange({ name: 'observations', value: e.target.value })}
                  name="observations"
                  {...register("observations", {
                  required: false,
                  })}
                />
              </FormGroup>
            </DetailRow>
          </form>
        )}
        
      </ModalBody>
      <ModalFooter className="no-border">
      {success ? (
          <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        ) : (
          <>
            <Col >
              <div className='w-100 d-flex flex-row justify-content-between'>
                <DetailRow title="Cantidad" className="mb-1">
                  <div className='display-4 fs-4 font-weight-normal text-center text-sans-serif text-info'>
                    {data.numRecaudos}
                  </div>
                </DetailRow>
                <DetailRow title="Total a recaudar" className="mb-1">
                  <div className='display-4 fs-4 font-weight-normal text-sans-serif text-info'>
                    <CountUp end={data.total} duration={1} prefix="$" separator="." decimal="," decimals={2} />  
                  </div>
                </DetailRow>
              </div>
            </Col>
            <Col className="text-right" >
                <Button onClick={() => toggle()} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
                    Cancelar
                </Button>
                <Button onClick={onRecaudo} transform="shrink-3 down-2" color="primary" >
                    Recaudar
                </Button>
            </Col>
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default RecaudoLoteModal

