import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { StateContext } from '../StateContext';
import { currencyFormat, percentageFormat } from '../../../helpers/tableFormatters';
import CountUp from 'react-countup';

const GeneralConditionsInfo = () => {
  const context = useContext(StateContext);
  const { loading, creditSelected: selected } = context;

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
        <Row className="text-right fs-3">
          <Col lg={12}>
            <DetailRow className="pb-3" textNormal title="Monto del Crédito">{selected.monto ? currencyFormat(selected.monto) : <span className='p-2'></span>}</DetailRow>
            <DetailRow className="pb-3" textNormal title="Tasa de Interés">
              {selected.tasa ? percentageFormat(selected.tasa) : <span className='p-2'></span>}
            </DetailRow>
            <Row className="ml-3 justify-content-between pb-0">
              <Col lg={4} className='d-flex flex-column p-0'>
                <div className='fs--2 text-left'>
                    Plazo
                </div>
                <div className='text-left'>
                  {selected.plazo || <span className='p-2'></span>}
                </div>
              </Col>
              <Col lg={8} className='d-flex flex-column p-0'>
                <div className='fs--2'>
                    Valor Cuota
                </div>
                <div>
                  <CountUp end={selected.valorCuota || 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default GeneralConditionsInfo;
