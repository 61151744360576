import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getAllRoles = () => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/rol/nom/${sesionToken}/*`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res.objResponse : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getBanks = () => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/banco/nom/${sesionToken}/*`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res.objResponse : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getBankAccount = () => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/cuentabanco/${sesionToken}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getPagadurias = (filter) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/pagaduria/nom/${sesionToken}/${filter || "*"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getTreasuries = () => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/tesoreria/nom/${sesionToken}/*`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getConcepts = (type) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/concepto/${sesionToken}/${type}/D`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getDesConcepts = (type) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/concepto/${sesionToken}/O/${type || 'C'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getCustomConcepts = (type) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/concepto/${sesionToken}/${type}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const generalServices = {
    getAllRoles,
    getBanks,
    getPagadurias,
    getTreasuries,
    getBankAccount,
    getConcepts,
    getDesConcepts,
    getCustomConcepts
}