import React, { Fragment } from 'react';

import GeneralDataAdviser from './contents/GeneralDataAdviser';
import GeneralDataClient from './contents/GeneralDataClient';
import PersonalReference from './contents/PesonalReference';

const SolicitudContent = () => {
  return (
    <Fragment>
      <GeneralDataAdviser />
      <GeneralDataClient />
      <PersonalReference />
    </Fragment>
  );
};

export default SolicitudContent;
