import React, { useState, useEffect, Fragment } from 'react';
import PageHeader from '../../components/common/PageHeader';
import { Card } from 'reactstrap';
import SimulatorForm from './SimulatorForm';
import SimulatorDisplay from './SimulatorDisplay';
import { simulatorServices } from '../../services';
import SimulatorPlanModal from './SimulatorPlanModal';

const SimulatorPage = (props) => {
  const [ formData, setFormData ] = useState({});
  const [ fee, setFee ] = useState();
  const [ bonus, setBonus ] = useState();
  const [ planDate, setPlanDate ] = useState();
  const [ paymentsPlan, setPaymentsPlan ] = useState();
  const [ showPlan, setShowPlan ] = useState();

  useEffect(() => {
    if(!fee || !planDate){
      setPaymentsPlan(null);
      return;
    };
    calculatePlan(planDate);
  }, [fee])

  const handleFormError = () => {
    // setFormData();
    if(!props.handleSubmit) return;
    props.handleSubmit();
  }

  const handleCalculate = async  data => {
    setFormData(data)
    const resp = await simulatorServices.calculateFee(data);
    if(resp.error) return;
    setFee(resp.objResponse.valorCuota);
    setBonus(resp.objResponse.valorPrima);
    if(!props.handleSubmit) return;
    props.handleSubmit({...data, cuota: resp.objResponse.valorCuota});
  }

  const handleCalculatePlan = date => {
    setPlanDate(date);
    if(props.onChangeDate){
      props.onChangeDate(date);
    }
    if(!fee) return;
    calculatePlan(date)
  }

  const calculatePlan = async date => {
    if(!date) return;
    const resp = await simulatorServices.getPaymentsPlan(formData, fee, date);
    if(resp.error) return;
    setPaymentsPlan(resp.objResponse)
  }

  const handleModal = (value) => {
    if(value && !planDate) return
    setShowPlan(value)
  }

  return (
    <Fragment>
    {paymentsPlan && (
      <SimulatorPlanModal plan={paymentsPlan} open={showPlan} collapseOneOpen={handleModal} />
    )}
    {!props.noHeader && (
      <PageHeader
        title="Simulador"
        className="mb-3"
      />
    )}
    <div className={`mb-3 w-100 ${props.noShadow ? 'shadow-none' : ''}`} style={{backgroundColor: `${props.bgColor || 'white'}`}}>
      <SimulatorForm 
        handleCalculate={handleCalculate}
        handleFormError={handleFormError}
        bonus={bonus} 
        vertical={!!props.vertical} 
        requiredMark={!!props.requiredMark}
        defaultData={props.defaultData}
        invisibleFields={props.invisibleFields}
      />
    </div>
    <SimulatorDisplay 
      fee={fee} 
      minDate={props.minDate}
      handleCalculatePlan={handleCalculatePlan} 
      handleClickPlan={handleModal}
      vertical={!!props.vertical}
      requiredMark={!!props.requiredMark}
    />
    </Fragment>
  )
    
}

export default SimulatorPage;
