import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@material-ui/core/IconButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import MainTable from '../../../components/tables/MainTable';
import ClientInfo from '../../recaudar/sections/ClientInfo';
import { RecaudoContext } from '../RecaudoContext';
import { refFormat } from '../../../helpers/tableFormatters';
import { currencyFormat } from '../../../helpers/tableFormatters';
import { formatDate } from '../../../helpers/dateFormatters';
import RecaudoClientModal from '../modals/RecaudoClientModal';

const RecaudoClient = () => {
  const context = useContext(RecaudoContext);
  const { loading, client, clientRecaudes, loadClientRecaudes, onDateRange } = context;
  const [showRecaudoModal, setShowRecaudoModal] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [recaudoSelected, setRecaudoSelected] = useState();

  useEffect(() => {
    if (!recaudoSelected) return;
    setModalTitle("Recaudo Crédito")
  })

  const columns = [
    {
      dataField: 'recaudo',
      text: 'Nro.',
      headerClasses: 'border-0',
      align: 'left',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (dataField) => refFormat(dataField),
    },
    {
      dataField: 'fecha',
      text: 'Fecha',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'credito',
      text: 'Crédito',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nombreModalidad',
      text: 'Modalidad',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nombreConcepto',
      text: 'Concepto',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nombreTipoRecaudo',
      text: 'Tipo de Recaudo',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'valorRecaudo',
      text: 'Valor Recaudo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: 'Acciones',
      classes: 'border-0 py-2 text-center',
      sort: false,
      formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  const actionsFormatter = (dataField, fieldSelected) => {
    if (!fieldSelected) return <></>
    return (
      <div className='pl-4'>
        <IconButton
          onClick={() => handleCLickAction(fieldSelected)}
        >
          <FontAwesomeIcon
            style={{ width: '16px' }}
            icon={faTrash}
            color="SteelBlue"
          />
        </IconButton>
      </div>
    )
  }

  const handleCLickAction = data => {
    setRecaudoSelected(data);
    setShowRecaudoModal(true);
  }

  const handleDateRange = (dates) => {
    if(!dates[0] || !dates[1]) return;
    const range = [];
    range[0] = dates[0] ? formatDate(dates[0]) : "";
    range[1] = dates[1] ? formatDate(dates[1]) : "";
    onDateRange(range);
  }

  const onCloseModal = value => {
    if (value) loadClientRecaudes();
    setShowRecaudoModal(!showRecaudoModal);
    setRecaudoSelected(null);
  }
      
  return (
    <>
      <RecaudoClientModal
        open={showRecaudoModal} 
        data={recaudoSelected}
        toggle={onCloseModal} 
      />
      <ClientInfo context={RecaudoContext} handleDateRange={handleDateRange} showDatePicker />
      <MainTable
        title="Recaudos Registrados"
        data={clientRecaudes}
        columns={columns}
        keyField="id"
        className="px-2"
        loading={loading}
        noSearch
        noAdd
      />
    </>
  )
}

export default RecaudoClient