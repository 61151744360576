import React from 'react';
import { Col } from 'reactstrap';
import DetailRow from './DetailRow';

const RepresentativeInfo = ({
    data
}) => {
    return (
      <>
        <Col lg className="col-xxl-5">
          <DetailRow title="Tipo de Identificación">{data.tipoIdentificacionRep}</DetailRow>
          <DetailRow title="Primer Nombre">{data.nombre1Rep}</DetailRow>
          <DetailRow title="Primer Apellido">{data.apellido1Rep}</DetailRow>
          <DetailRow title="Fecha de Inicio Representación">{data.fechaInicioRep}</DetailRow>
          <DetailRow title="Teléfono Celular">{data.telefono1Rep}</DetailRow>
        </Col>
        <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
          <DetailRow title="Número de Identificación">{data.identificacionRep}</DetailRow>
          <DetailRow title="Segundo Nombre">{data.nombre2Rep}</DetailRow>
          <DetailRow title="Segundo Apellido">{data.apellido2Rep}</DetailRow>
          <DetailRow title="Correo Electrónico">{data.correoRep}</DetailRow>
          <DetailRow title="Teléfono Alterno">{data.telefono2Rep}</DetailRow>
        </Col> 
      </>
    )
}

export default RepresentativeInfo;
