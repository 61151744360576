import React, { useState, useEffect } from 'react'
import { 
  Form, 
  FormGroup, 
  Label,
  Row,
  Col,
  Input,
  CustomInput,
  Button
} from 'reactstrap'
import CountUp from 'react-countup';
import { v4 as uuidv4 } from 'uuid';
import CurrencyInput from 'react-currency-input-field';
import RequiredMarked from '../../components/common/RequiredMarked'

function SimulatorFormDemo(
  { 
    handleFormError,
    handleCalculate, 
    bonus, 
    vertical, 
    requiredMark,
    defaultData,
    invisibleFields = {},
  }) {
    const [ type, setType ] = useState("1");
    const [ amount, setAmount ] = useState("");
    const [ rate, setRate ] = useState("");
    const [ term, setTerm ] = useState("");
    const [ bonusPercent, setBonusPercent ] = useState("");

  useEffect(() => {
    if(!defaultData) return;
    const { amount, rate, term, bonusPercent, type } = defaultData;
    if(amount) setAmount(amount);
    if(rate) setRate(rate);
    if(term) setTerm(term);
    if(bonusPercent) setBonusPercent(bonusPercent);
  }, [defaultData])

  useEffect(() => {
    const formData = {
      amount,
      rate,
      term,
      type,
      bonus: bonusPercent,
    };

    if(!amount || !term || !bonusPercent || !type){
      handleFormError();
      return;
    }

    if(!formData.rate) formData.rate = "0.00";

    formData.amount = formData.amount.replaceAll(",", ".")
    formData.rate = formData.rate.replaceAll(",", ".")
    formData.term = formData.term.replaceAll(",", ".")
    formData.bonus = formData.bonus.replaceAll(",", ".")
    
    handleCalculate(formData)

    }, [amount, rate, term, bonusPercent, type])

  return (
    <form>
      <div className={`${vertical ? '' : 'col'}`}>
        <Row>
          {!invisibleFields.amount && (
            <Col lg={vertical ? 12 : 6} xs={12} >
              <FormGroup>
                <Label for="amount">
                  {requiredMark && <RequiredMarked />}
                  Monto Solicitado
                </Label>
                <CurrencyInput
                  className="form-control"
                  style={vertical ? {
                    fontSize: '1.6em',
                    textAlign: 'right',
                  } : {}}
                  allowNegativeValue={false}
                  defaultValue={amount}
                  name="amount"
                  id="amount"
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => setAmount(v)}
                  decimalScale={2}
                  maxLength={20}
                  placeholder="$ 0.00" />
              </FormGroup>
            </Col>
          )}
          {!invisibleFields.rate && (
            <Col lg={vertical ? 12 : 6} xs={12}>
              <FormGroup>
                <Label for="rate">
                  Tasa de Interés
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  defaultValue={rate}
                  name="rate"
                  id="rate"
                  suffix='%'
                  onValueChange={(v) => setRate(v)}
                  decimalScale={2}
                  maxLength={5}
                  placeholder="0.00%" />
              </FormGroup>
            </Col>
          )}
        </Row>
        <Row>
        {!invisibleFields.term && (
          <Col lg={vertical ? 12 : 6} xs={12}>
            <FormGroup>
              <Label for="term">
                {requiredMark && <RequiredMarked />}
                Plazo en Meses
              </Label>
              <CurrencyInput
                className="form-control"
                allowNegativeValue={false}
                allowDecimals={false}
                defaultValue={term}
                name="term"
                id="term"
                onValueChange={(v) => setTerm(v)}
                decimalsLimit={0}
                maxLength={3}
                placeholder="0" />
            </FormGroup>
          </Col>
        )}
        {!invisibleFields.type && (
          <Col lg={vertical ? 12 : 6} xs={12}>
            <FormGroup>
              <Label for="type">
                {requiredMark && <RequiredMarked />}
                Tipo de Fórmula
              </Label>
              <CustomInput
                type="select"
                id="type"
                name="type"
                value={type}
                className="text-uppercase"
                onChange={e => setType(e.target.value)}
              >
                <option value="1" key={uuidv4()}>
                  Amortizable
                </option>
                <option value="2" key={uuidv4()}>
                  Fija
                </option>
              </CustomInput>
            </FormGroup>
          </Col>
        )}
        </Row>
        <Row className="align-items-end">
        {!invisibleFields.bonusPercent && (
          <Col lg={vertical ? 12 : 6} xs={12}>
            <FormGroup>
              <Label for="bonus">
                {requiredMark && <RequiredMarked />}
                Porcentaje de Prima
              </Label>
              <CurrencyInput
                className="form-control"
                allowNegativeValue={false}
                defaultValue={bonusPercent}
                name="bonus"
                id="bonus"
                suffix='%'
                onValueChange={(v) => setBonusPercent(v)}
                decimalScale={2}
                maxLength={5}
                placeholder="0.00%" />
            </FormGroup>
          </Col>
        )}
          <Col lg={vertical ? 12 : 6} xs={12}>
            <FormGroup>
              <Label>Valor Prima</Label>
              <div className={`display-4 ${vertical ? 'fs-2' : 'fs-4'} mb-2 pl-2 font-weight-normal text-sans-serif`}>
                <CountUp end={bonus ? bonus : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </form>
  )
}

export default SimulatorFormDemo
