import React, { useState, useContext } from 'react';
import { Col, Card, CardBody, Row, FormGroup, Input, Label, CustomInput,  Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SolicitudContext } from '../SolicitudContext';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import SimulatorPage from '../../simulator/SimulatorPage';

const GeneralConditions = () => {
    const { register, triggerValidation, errors, setValue, getValues } = useForm();
    const { 
      date,
      creditLinesOptions, 
      creditSublinesOptions, 
      onSave, 
      handleCreditForm, 
      handleCutDate, 
      handleCreditInfoForm, 
      loadCreditSublines } = useContext(SolicitudContext);

    const onChange = async e => {
      const { name, value } = e.target;
      if(name === 'creditLine'){
        loadCreditSublines(value);
        setValue('creditSubline', null)
      }
      setValue(name, value);
      const formCreditInfo = getValues();
      await triggerValidation();
      const isValid = Object.keys(errors).length < 1;
      handleCreditInfoForm(isValid ? formCreditInfo : null);
    }

    return (
        <Card className={`my-3`} style={{backgroundColor: "#1b97ebd1", color: "white"}} >
          <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
          <CardBody className="border-top">
          <form onChange={onChange}>
            <Row>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditLine">
                  <span className='mr-1 text-danger'>*</span>
                  Línea de Crédito
                </Label>
                <WizardInput
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="creditLine"
                  name="creditLine"
                  options={creditLinesOptions.map(item => (
                    {label: item.nombre, value: item.id}
                  ))}
                  errors={errors}
                  innerRef={register({
                    required: true,
                  })}
                />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditSubline">
                  <span className='mr-1 text-danger'>*</span>
                  Sublínea de Crédito
                </Label>
                <WizardInput
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="creditSubline"
                  name="creditSubline"
                  options={creditSublinesOptions.map(item => (
                    {label: item.nombre, value: item.id}
                  ))}
                  errors={errors}
                  innerRef={register({
                    required: true,
                  })}
                />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Nro. del Crédito
                </Label>
                <Input
                  id="creditNumber"
                  name="creditNumber"
                  {...register("creditNumber", {
                    required: true,
                  })}
                />
                </FormGroup>
              </Col>
            </Row>
            </form>
            <SimulatorPage 
              handleSubmit={handleCreditForm}
              onChangeDate={handleCutDate}
              minDate={date}
              bgColor="transparent"
              vertical
              noShadow
              noHeader
              requiredMark />
            <Row className="mt-4">
              <Col lg={6} xs={12}>
                  <Button className="w-100" transform="shrink-3 down-2" color="falcon-default" >
                      Cancelar
                  </Button>
              </Col>
              <Col lg={6} xs={12}>
                  <Button onClick={onSave} className="w-100" transform="shrink-3 down-2" color="primary" >
                      Guardar
                  </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
    )
};

export default GeneralConditions;
