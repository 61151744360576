import React from 'react';
import { CardBody } from 'reactstrap';
import ListItem from './LIstItem';

const OwnerInfo = ({
    data
}) => {

    return (
      <CardBody>
          {data && (
              data.map(p => (
                <ListItem title={p.infoRol} />
              ))
          )}
      </CardBody>
    )
}

export default OwnerInfo;
