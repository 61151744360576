import React, { useState, useEffect, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import cogoToast from 'cogo-toast';
import { RecaudoContext } from './RecaudoContext';
import PageHeader from '../../components/common/PageHeader';
import RecaudoClient from './childrens/RecaudoClient';
import RecaudoLote from './childrens/RecaudoLote';
import types from '../../data/recaudo/types';
import { recaudoServices } from '../../services';

const RecaudoPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [client, setClient] = useState();
  const [pagSelected, setPagSelected] = useState();
  const [clientRecaudes, setClientRecaudes] = useState([]);
  const [creditsPag, setCreditsPag] = useState([]);
  const [dateRange, setDateRange] = useState();

  const [showModalLote, setShowModalLote] = useState(false);

  const [loteSelected, setLoteSelected] = useState();
  const [recaudosLote, setRecaudosLote] = useState([]);

  useEffect(() => {
    if (!client || !dateRange) return;
    loadClientRecaudes();
  }, [client, dateRange])

  useEffect(() => {
    if(!loteSelected){
      setRecaudosLote([]);
      return;
    }

    loadRecaudosLote();
  }, [loteSelected])

  const toggleTab = id => {
    setActiveTab(id);
  }

  const loadClientRecaudes = async () => {
    if(!dateRange) return;
    setLoading(true);
    const response = await recaudoServices.getRecaudosByClientRange(client.id, dateRange[0], dateRange[1]);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los recaudos del cliente' });
      setLoading(false);
      return;
    }

    setClientRecaudes(response.objResponse);
    setLoading(false);
  }

  const loadCreditsByPag = async (pag) => {
    // if(!pag){
    //   setPagSelected(null);
    //   setCreditsPag([]);
    //   return;
    // }
    // setPagSelected(pag)
    // const response = await creditServices.getCreditsByPagaduria(pag.id);
    // if (!response || response.error) {
    //   cogoToast.error(response.msg,
    //     { position: 'bottom-left', heading: 'Error al cargar los créditos por lote' });
    //   return;
    // }

    // setCreditsPag(response.objResponse);
  }

  const loadRecaudosLote = async () => {
    const response = await recaudoServices.getRecaudosByLote({idLote: loteSelected.value});
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los Recaudos por lote' });
      return;
    }
    setRecaudosLote(response.objResponse);
  }

  const onClientSelect = data => {
    setClient(data)
  }

  const toggleModalLote = (value) => {
    if((value || !showModalLote) && !pagSelected) return;
    setShowModalLote(value || !showModalLote);
  }

  const handleSaveLote = () => {
    toggleModalLote(false);
    setPagSelected(null);
    setCreditsPag([]);
  }

  const onCancelLote = () => {
    setPagSelected(null);
    setCreditsPag([]);
  }

  const handleCleanClient = () => {
    setClient(null);
    setClientRecaudes([]);
  }

  const onDateRange = (dates) => {
    setDateRange(dates);
  }

  const handleChangePag = (pag) => {
    setPagSelected(pag);
  }

  const handleChangeLote = (lote) => {
    setLoteSelected(lote);
  }

  return (
    <RecaudoContext.Provider value={{
      loading,
      client,
      pagSelected,
      loteSelected,
      recaudosLote,
      clientRecaudes,
      creditsPag,
      onClientSelect,
      showModalLote,
      loadClientRecaudes,
      loadCreditsByPag,
      toggleModalLote,
      handleSaveLote,
      onCancelLote,
      handleCleanClient,
      handleChangePag,
      handleChangeLote,
      onDateRange
    }}>
      <Fragment>
        <PageHeader title="Consulta de Recaudo" className="mb-3" >
        </PageHeader>
        <div>
          <Nav tabs className="custom-nav">
            {types && types.map((type, idx) => (
              <NavItem className="flex-grow-1 text-center cursor-pointer">
                <NavLink onClick={() => toggleTab(type.id)} className={`p-3  ${activeTab == (idx+1) ? 'active' : '' }`}>
                  {type.altName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <RecaudoClient />
            </TabPane>
            <TabPane tabId="2">
              <RecaudoLote />
            </TabPane>
            <TabPane tabId="3">
            </TabPane>
          </TabContent>
        </div>
      </Fragment>
    </RecaudoContext.Provider>
  );
};

export default RecaudoPage
