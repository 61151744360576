import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  CustomInput,
  FormGroup,
  Label,
  Spinner
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import CountUp from 'react-countup';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import cogoToast from 'cogo-toast';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RecaudoContext } from '../RecaudoContext';
import { EnvironmentContext } from '../../../context/Context';
import ButtonIcon from '../../../components/common/ButtonIcon';
import IconButton from '@material-ui/core/IconButton';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import MainTable from '../../../components/tables/MainTable';
import paymentsType from '../../../data/recaudo/paymentsType';
import { desembolsoServices, generalServices, recaudoServices } from '../../../services';
import { getTodayStr } from '../../../helpers/dateFormatters';
import numToWord from '../../../helpers/numToWord';
import RecaudoInvoice from '../../recaudar/invoice/RecaudoInvoice';
import { refFormat } from '../../../helpers/tableFormatters';
import { currencyFormat as amountFormat, formatterId } from '../../../helpers/formatters'


function RecaudoClientModal({ open, toggle, data }) {
  const environment = useContext(EnvironmentContext);
  const context = useContext(RecaudoContext);
  const { client } = context;
  const { register, setValue, getValues, errors, watch } = useForm();

  const [requesting, setRequesting] = useState();
  const [invoiceProps, setInvoiceProps] = useState();
  const [success, setSuccess] = useState();

  useEffect(() => {
    if (!data) return;
    setSuccess(null);
    setInvoiceProps(null);
  }, [data])

  const onChange = async e => {
    let { name, value } = e.target;
    setValue(name, value);
  }

  const onRecaudo = async () => {
    setRequesting(true);
    const generalData = getValues();
    if (!data) {
      cogoToast.warn("Por favor complete los campos requeridos",
        { position: 'bottom-left', heading: 'Campos requeridos' });
      setRequesting(false);
      return;
    }

    const payload = {
      recaudo: data.recaudo,
      observacion: generalData.observations,
    }

    const response = await recaudoServices.cancelRecaudo(payload);
    if (!response || response.error) {
      cogoToast.error(response.msg || "No se logro completar la solicitud de anulación de recaudo",
        { position: 'bottom-left', heading: 'Error' });
      setRequesting(false);
      return;
    }

    handleSuccess({
      ...data,
      ...payload,
      consecutivo: response.objResponse.consecutivo,
      ciudad: response.objResponse.ciudad
    })

    // cogoToast.success("Recaudo anulado con éxito",
    //     { position: 'bottom-left', heading: 'Anulación Exitosa!' });
    // setRequesting(false);
    // setSuccess(true)
  }

  const handleSuccess = (payload) => {
    // toggle(true);
    createInvoiceProps(payload);
    setSuccess(true);
    setRequesting(false);
  }

  const onOk = () => {
    setSuccess(false);
    toggle(true);
  }

  const createInvoiceProps  = (payload) => {
    const montoWord = numToWord(payload.valorRecaudo)
    
    const tempOwnProps = {
      client: data.nombreCliente,
      clientTypeId: client.terceroInformacion.tipoIdentificacion,
      clientId: client.terceroInformacion.identificacion ? formatterId(Number(client.terceroInformacion.identificacion)) : "",
      serial: payload.consecutivo,
      ciudad: payload.ciudad,
      idCredit: data.credito,
      amount: amountFormat(payload.valorRecaudo || 0),
      concept: `REVERSION ${data.nombreConcepto}`,
      sumWord: montoWord || "",
      checkCash: payload.modalidadPago == 1,
      checkWireTransfer: payload.modalidadPago == 2,
      checkPSE: false,
      checkCheque: payload.modalidadPago == 3,
      date: getTodayStr().split('-'),
      observations: payload.observacion,
    }
    
    setInvoiceProps(tempOwnProps)
  }

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={onOk}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >
        <h5>{`Nota crédito al Recaudo Nro ${data ? data.credito : ''}`}</h5>
        <span className='text-primary' style={{ fontSize: '0.8em' }}>{data ? data.nombreConcepto : ''}</span>
      </ModalHeader>
      <ModalBody>
        {data && (
          <>
          {success ? (
            <div className="text-center mt-4">
              <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" />
              <h6 className="fs-2 m-3">
                Anulación Exitosa!
              </h6>
              <p className="fs--1 mb-0"></p>
              {invoiceProps && invoiceProps.checkCash && (
                <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
                  <PDFDownloadLink document={<RecaudoInvoice title="Nota Crédito" data={invoiceProps} />} fileName={`nota_credito_${invoiceProps.serial}.pdf`} >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Cargando...' : 'Descargar nota crédito'
                    }
                  </PDFDownloadLink>
                </ButtonIcon>
              )}
            </div>
          ) : (
            <>
              <Row className="mx-0">
                <Col lg className="col-xxl-5">
                  <DetailRow title="Crédito">{data.credito || <span className='p-2'></span>}</DetailRow>
                  <DetailRow textNormal title="Fecha Recaudo" childrenClasses="uppercase">
                    {data.fecha || <span className='p-2'></span>}
                  </DetailRow>
                  <DetailRow childrenClasses="text-uppercase" title="Modalidad">{data.nombreModalidad || <span className='p-2'></span>}</DetailRow>
                </Col>
                <Col lg className="col-xxl-5">
                  <DetailRow childrenClasses="text-uppercase" title="Cliente">{data.nombreCliente || <span className='p-2'></span>}</DetailRow>
                  <DetailRow title="Valor Recaudado">
                    <CountUp end={data.valorRecaudo} duration={1} prefix="$" separator="." decimal="," decimals={2} />
                  </DetailRow>
                  <DetailRow childrenClasses="text-uppercase" title="Tipo Recaudo">{data.nombreTipoRecaudo || <span className='p-2'></span>}</DetailRow>
                </Col>
              </Row>

              <Row className="mx-0 mt-3 pt-3 border-top">
                <Col lg className="col-xxl-5">
                  <DetailRow title="Registrado">{data.fechaCreacion || <span className='p-2'></span>}</DetailRow>
                </Col>
                <Col lg className="col-xxl-5">
                  <DetailRow childrenClasses="text-uppercase" title="Usuario">{data.correo || <span className='p-2'></span>}</DetailRow>
                </Col>
              </Row>
              <form onChange={onChange} className="mt-2">
                <FormGroup>
                  <Label for="observations">
                      Observaciones
                  </Label>
                  <Input
                      type="textarea"
                      id="observations"
                      invalid={errors['observations']}
                      maxLength={300}
                      name="observations"
                      {...register("observations", {
                      required: false,
                      })}
                  />
                </FormGroup>
              </form>
            </>
          )}
            
          </>
        )}
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
          {success ? (
            <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
              Aceptar
            </Button>
          ) : (
            <>
              <Button onClick={() => toggle()} className="mr-2" transform="shrink-3 down-2" color="falcon-default" >
                Cancelar
              </Button>
              <Button onClick={onRecaudo} transform="shrink-3 down-2" color="primary" >
                Anular
              </Button>
            </>
          )}
          
        </Col>
      </ModalFooter>
    </Modal >
  )
}

export default RecaudoClientModal

