import React from 'react';
import { Card, Input, Button, ModalBody, ModalFooter, Label, Form, CustomInput } from 'reactstrap';
import { urlApi } from '../../configuracion/serverConfig';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';

class GestionUsuariosForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: ''
    };
  }
  componentDidMount() {
    for (var key in this.props.data) {
      let value = this.props.data[key];
      this.state[key] = value;
    }
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    if (this.props.data) {
      let dataperfil = { value: this.props.data.perfil, label: this.props.data.nombrePerfil };
      this.setState(state => ({
        ...state,
        selectperfil: dataperfil
      }));

      let EstadoUsuarioTemporal = false;

      if (this.props.data.estado === '1') {
        EstadoUsuarioTemporal = true;
      } else if (this.props.data.estado === '0') {
        EstadoUsuarioTemporal = false;
      }
      this.setState(state => ({
        ...state,
        EstadoUsuario: EstadoUsuarioTemporal
      }));
    }

    fetch(urlApi + '/perfil/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        if (data.status === 0) {
          let array = [];
          for (let i = 0; i < data.objResponse.length; i++) {
            let element = {};
            element.value = data.objResponse[i].idPerfil;
            element.label = data.objResponse[i].nombre;
            array.push(element);
          }
          this.setState(state => ({
            ...state,
            ListData: array
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  }
  onClick = e => {
    this.props.MostrarFormulario('Cancelar');
  };

  MostarAlerta = data => {
    confirmAlert({
      title: 'Registro Guardado',
      message: 'Se ha guardado correctamente el registro',
      buttons: [
        {
          label: 'ACEPTAR',
          onClick: this.props.MostrarFormulario('Guardado')
        }
      ]
    });
  };
  onInputchange = data => {
    if (data) {
      let name = data.target.name;
      let value = data.target.value;
      this.setState(state => ({
        ...state,
        [name]: value
      }));
    }
  };
  selectperfil = data2 => {
    let dataperfil = { value: data2.value, label: data2.label };
    this.setState(state => ({
      ...state,
      selectperfil: dataperfil
    }));
  };

  agregar = e => {
    e.preventDefault();
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    let ObjUsuario = {};
    ObjUsuario.sesion = sesion;
    ObjUsuario.usuario = this.props.data.usuario;
    ObjUsuario.estado = this.state.EstadoUsuario;
    ObjUsuario.identificacion = this.state.identificacion;
    ObjUsuario.nombre = this.state.nombre;
    ObjUsuario.correo = this.state.correo;
    ObjUsuario.contrasena = ''
    ObjUsuario.nombrePerfil = this.state.selectperfil.label;
    ObjUsuario.perfil = this.state.selectperfil.value;

    if (ObjUsuario.estado === true) {
      ObjUsuario.estado = '1';
    } else if (ObjUsuario.estado === false) {
      ObjUsuario.estado = '0';
    }

    if (this.props.data.usuario) {
      // ObjUsuario.usuario = this.props.data.usuario;
      fetch(urlApi + '/usuario', {
        method: 'PUT',
        body: JSON.stringify(ObjUsuario),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            cogoToast.success("El usuario fue actualizado correctamente", { position: 'bottom-right', heading: 'Actualizado' });
            this.props.MostrarFormulario('Guardado');
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })
        .catch(err => console.log('err', err));
    } else {
      fetch(urlApi + '/usuario', {
        method: 'POST',
        body: JSON.stringify(ObjUsuario),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => data)
        .then(data => {
          if (data.status === 0) {
            cogoToast.success("El usuario fue agregado correctamente", { position: 'bottom-right', heading: 'Agregado' });
            this.props.MostrarFormulario('Guardado');
          } else {
            cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
          }
        })
        .catch(err => console.log('err', err));
    }
  };

  CheckedEstado = () => {
    this.setState({ EstadoUsuario: !this.state.EstadoUsuario });
  };

  render() {
    return (
      <>
        <div>
          <Card>
            <div className="right-panel roe-box-shadow">
              <div className="">
                <Form onSubmit={this.agregar}>
                  <ModalBody>
                    <div className="form-group">
                      <div className="row pt-3 ">
                        <div className="flex col-md-12">
                          {/* <Icon className="mr-3 mt-3">closed_caption</Icon> */}
                          <Label for="exampleName">Documento de identidad</Label>
                          <Input
                            type="text"
                            name="identificacion"
                            id="identificacion"
                            value={this.state.identificacion}
                            onChange={this.onInputchange}
                          />
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="flex col-md-12">
                          {/* <Icon className="mr-3 mt-3">edit</Icon> */}

                          <Label for="exampleName">Nombre completo</Label>
                          <Input
                            type="text"
                            name="nombre"
                            value={this.state.nombre}
                            onChange={this.onInputchange}
                            id="nombre"
                          />
                        </div>
                      </div>
                      <div className="row pt-3  ">
                        <div className="flex col-md-12">
                          {/* <Icon className="mr-3 mt-3">edit</Icon> */}

                          <Label for="exampleName">Correo Electrónico</Label>
                          <Input
                            type="email"
                            name="correo"
                            value={this.state.correo}
                            onChange={this.onInputchange}
                            id="correo"
                          />
                        </div>
                      </div>


                      <div className="row pt-3 ">
                        <div className="flex col-md-12">
                          <Label for="exampleName">Perfil</Label>
                          <Select
                            className=""
                            style="fullWidth-select"
                            id="selectperfil"
                            onChange={this.selectperfil}
                            value={this.state.selectperfil}
                            options={this.state.ListData}
                          />
                        </div>
                      </div>

                    </div>
                  </ModalBody>

                  <ModalFooter className="no-border">
                    <div className="row col-md-12">
                      <div className="col-md-2 text-left">
                          <CustomInput
                            type="switch"
                            name="estado"
                            id="exampleCheck1"
                            checked={this.state.EstadoUsuario}
                            onChange={() => this.CheckedEstado()}
                          />
                          <Label for="exampleCheck1" check>
                            Activo
                          </Label>
                      </div>

                    <div className="col-md-10 text-right">
                      <Button
                        className="mr-1" color="primary" size="sm" outline
						            style={{'height': '36px'}}
                        type="submit"
                      >
						              <i className="mr-1">
                          {' '}
                          <FontAwesomeIcon icon={faSave} />
                        </i>
                        Guardar
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        className="mr-1" color="primary" size="sm" outline
						            style={{'height': '36px'}}
                        onClick={this.onClick}
                      >
						              <i className="mr-1">
                          {' '}
                          <FontAwesomeIcon icon={faUndo} />
                        </i>
                        Cancelar
                      </Button>
                    </div>
                    </div>
                  </ModalFooter>

                </Form>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  }
}
export default GestionUsuariosForm;
