import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import MainTable from '../../components/tables/MainTable';
import CurrencyFormat from 'react-currency-format';
import { IconButton } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currencyFormat } from '../../helpers/tableFormatters';


function SimulatorPlanModal({ plan, open, collapseOneOpen}) {

  const columns = [
    {
        dataField: 'periodo',
        text: 'Cuota',
        headerClasses: 'border-0 text-left',
        classes: 'border-0 py-2 text-left align-middle',
    },
    {
        dataField: 'fecha',
        headerClasses: 'border-0 text-left',
        text: 'Fecha',
        classes: 'border-0 text-left py-2 align-middle',
    },
    {
      dataField: 'saldoInicial',
      headerClasses: 'border-0 text-right',
      text: 'Saldo Inicial',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'interes',
      headerClasses: 'border-0 text-right',
      text: 'Interés',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'capital',
      headerClasses: 'border-0 text-right',
      text: 'Capital',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'cuota',
      headerClasses: 'border-0 text-right',
      text: 'Valor Cuota',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle',
    },
    {
      dataField: 'saldo',
      headerClasses: 'border-0 text-right',
      text: 'Saldo',
      formatter: (value) => currencyFormat(value),
      classes: 'border-0 py-2 text-right align-middle font-weight-bold',
    },
  ]

  return (
    <Modal size="xl" isOpen={open} toggle={() => collapseOneOpen(!open)}>
      <ModalHeader
        close={
          <div className='d-flex justify-content-center'>
            <FontAwesomeIcon
              onClick={() => collapseOneOpen(!open)}
              style={{ cursor: 'pointer' }}
              size='lg'
              icon="times"
            />
          </div>}
      >Plan de Pagos</ModalHeader>
      <ModalBody className="p-0 px-3">
        <MainTable 
          data={plan}
          columns={columns}
          keyField="periodo"
          pageSize={12}
          className='mb-0 shadow-none'
          noSearch
          noAdd
        />
      </ModalBody>
    </Modal>
  )
}

export default SimulatorPlanModal
