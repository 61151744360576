import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getCobrosPrejuridicos = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/prestamo/jur/${sesionToken}/1/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getProcesosPrejuridicos = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/juridico/${sesionToken}/1/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getDespachos = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/juzgado/nom/${sesionToken}/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const savePrejuridico = data => {
    if(!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken;
    return fetch(`${urlApi}/juridico`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const liquidar = data => {
    if(!data) return;
    const sesionToken = authSesion();
    data.sesion = sesionToken;
    return fetch(`${urlApi}/juridico/liq`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getProcesosJuridicos = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/juridico/${sesionToken}/2/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}



export const juridicoServices = {
    getCobrosPrejuridicos,
    getProcesosJuridicos,
    getProcesosPrejuridicos,
    getDespachos,
    savePrejuridico,
    liquidar
}