import React, { Fragment } from 'react';
import WizardInput from './WizardInput';
import { Col, CustomInput, Row, Button } from 'reactstrap';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import './App.css';
const options2 = ['CC', 'NIT'];
const options = [
  {
    label: 'CC-Cédula de Ciudadania',
    value: 'CC'
  },
  { label: 'CE-Cédula de Extranjería', value: 'CE' },
  { label: 'PS-Pasaporte', value: 'PS' },
  { label: 'NIT', value: 'NIT' }
];

class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: '',
      ListaPaises: [],
      Listadpto: [],
      Listadpto2: [],
      ListaCiudad: [],
      ListaCiudad2: [],
      SelectTipoDocumento: [{ label: '', value: '' }],
      paisexpedicion: '',
      departamentoexpedicion: '',
      ciudadmunicipioexpedicion: '',
      paisnacimiento: '',
    };
  }
  componentDidMount() {
    this.ConsultarPaises();
    if (this.props.PaisExpedicion) {
      this.setState(state => ({
        ...state,
        paisexpedicion: this.props.PaisExpedicion.value
      }));
    }
    if (this.props.DepartamentoExpedicion) {
      this.ConsultarDept(this.props.PaisExpedicion)
      this.setState(state => ({
        ...state,
        departamentoexpedicion: Number(this.props.DepartamentoExpedicion.value)
      }));
    }
    if (this.props.CiudadExpedicion) {
      this.Consultarciudad(this.props.DepartamentoExpedicion)
      this.setState(state => ({
        ...state,
        ciudadmunicipioexpedicion: Number(this.props.CiudadExpedicion.value)
      }));
    }
    if (this.props.PaisNacimiento) {
      this.setState(state => ({
        ...state,
        paisnacimiento: this.props.PaisNacimiento.value
      }));
    }
    if (this.props.DepartamentoNacimiento) {
      this.ConsultarDept2(this.props.PaisNacimiento)
      this.setState(state => ({
        ...state,
        departamentonacimiento: Number(this.props.DepartamentoNacimiento.value)
      }));
    }
    if (this.props.CiudadNacimiento) {
      this.Consultarciudad2(this.props.DepartamentoNacimiento)
      this.setState(state => ({
        ...state,
        ciudadmunicipionacimiento: Number(this.props.CiudadNacimiento.value)
      }));
    }
    if(this.props.Identificacion) {
      this.setState(state => ({
        ...state,
        SelectTipoDocumento: this.props.Identificacion
      }));
    }
  }

  cambiarselect = data9 => {
    let datac = data9.target.value;
    this.setState(state => ({
      ...state,
      SelectTipoDocumento: datac
    }));
    this.props.cambiarselect(datac);
  };

  cambiarselectpais = data2 => {
    if (this.state.paisexpedicion) {
      var variable = data2.target.value;
    }

    if (variable != data2.target.value) {
      this.state.departamentoexpedicion = '';
      this.state.ciudadmunicipioexpedicion = '';
    }
    
    var paises = this.state.ListaPaises;
    let objectPaises = {};
    for (let i = 0; i < paises.length; i++) {
      if (Number(data2.target.value) === paises[i].value) {
        objectPaises = paises[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      paisexpedicion: objectPaises.value
    }));
    this.ConsultarDept(objectPaises);
    this.props.cambiarselectpais(objectPaises);
  };

  cambiarselectpais2 = data5 => {
    if (this.state.paisnacimiento) {
      var variable2 = data5.target.value;
    }

    if (variable2 != data5.target.value) {
      this.state.departamentonacimiento = '';
      this.state.ciudadmunicipionacimiento = '';
    }
    
    var paises2 = this.state.ListaPaises;
    let objectPaises2 = {};
    for (let i = 0; i < paises2.length; i++) {
      if (Number(data5.target.value) === paises2[i].value) {
        objectPaises2 = paises2[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      paisnacimiento: objectPaises2.value
    }));
    this.ConsultarDept2(objectPaises2);
    this.props.cambiarselectpais2(objectPaises2);

    
  }

  cambiarselectciudad2 = data6 => {
    var ciudades2 = this.state.ListaCiudad2;
    let objectCiudades2 = {};
    for (let i = 0; i < ciudades2.length; i++) {
      if (Number(data6.target.value) === Number(ciudades2[i].value)) {
        objectCiudades2 = ciudades2[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      ciudadmunicipionacimiento: objectCiudades2.value
    }));
    this.props.cambiarselectciudad2(objectCiudades2)
  };

  cambiarselectciudad = data4 => {

    var ciudades = this.state.ListaCiudad;
    let objectCiudades = {};
    for (let i = 0; i < ciudades.length; i++) {
      if (Number(data4.target.value) === Number(ciudades[i].value)) {
        objectCiudades = ciudades[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      ciudadmunicipioexpedicion: objectCiudades.value
    }));
    this.props.cambiarselectciudades(objectCiudades)
  };

  cambiarselectdepartamento = data3 => {


    var departamentos = this.state.Listadpto;
    let objectDepartamentos = {};
    for (let i = 0; i < departamentos.length; i++) {
      if (Number(data3.target.value) === Number(departamentos[i].value)) {
        objectDepartamentos = departamentos[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      departamentoexpedicion: objectDepartamentos.value
    }));
    this.Consultarciudad(objectDepartamentos);
    this.props.cambiarselectdepartamento(objectDepartamentos)
  };

  cambiarselectdepartamento2 = data7 => {
    var departamentos2 = this.state.Listadpto2;
    let objectDepartamentos2 = {};
    for (let i = 0; i < departamentos2.length; i++) {
      if (Number(data7.target.value) === Number(departamentos2[i].value)) {
        objectDepartamentos2 = departamentos2[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      departamentonacimiento: objectDepartamentos2.value
    }));
    this.Consultarciudad2(objectDepartamentos2);
    this.props.cambiarselectdepartamento2(objectDepartamentos2)
    
  };


   
  

  ConsultarPaises = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/pais/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].pais + ' - ' + lstDatos[i].nombre;
          element.identificador = lstDatos[i].pais;
          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaPaises: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  selectciudad = data => {
    this.Consultarciudad(data);
  };

  ConsultarDept = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/dpto/pais/' + sesion + '/' + data.identificador, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            Listadpto: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  ConsultarDept2 = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/dpto/pais/' + sesion + '/' + data.identificador, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            Listadpto2: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  }

  Consultarciudad = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/municipio/dpto/' + sesion + '/' + data.identificador + '/1/100/', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].estado;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaCiudad: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  Consultarciudad2 = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/municipio/dpto/' + sesion + '/' + data.identificador + '/1/100/', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].estado;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaCiudad2: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  MostrarFormulario = () => {
    this.state.EstadoForm = false;
  };
  render() {
    return (
      <>
        <Fragment>
          <Row form>
            <Col>
              <label>Tipo de Identificacion*</label>
              <WizardInput
                    type="select"
                    tag={CustomInput}
                    placeholder="Seleccionar"
                    id="SelectTipoDocumento"
                    name="SelectTipoDocumento"
                    value={this.state.SelectTipoDocumento}
                    errors={this.props.errors}
                    options={options}
                    onChange={this.cambiarselect}
                    innerRef={this.props.register({
                      required: 'requerido',
                    })}
                  />
            </Col>

            <Col>
              <WizardInput
                label="Numero de Identificacion*"
                placeholder=""
                id="NumeroIdentificacion"
                name="NumeroIdentiicacion"
                innerRef={this.props.register({
                  required: 'requerido',
                  minLength: {
                    value: 2,
                    message: 'Proporcione una identificacion valida'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
          {this.state.SelectTipoDocumento === 'NIT' ? (
            <div>
              <Row form>
                <Col>
                  <WizardInput
                    type="text"
                    label="Razon Social*"
                    // placeholder="Password"
                    id="RazonSocial"
                    name="RazonSocial"
                    innerRef={this.props.register({
                      required: 'requerido',
                     
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                  <WizardInput
                    type="text"
                    label="Nombre Comercial*"
                    id="NombreComercial"
                    name="NombreComercial"
                    innerRef={this.props.register({
                      required: 'requerido',
                    })}
                    errors={this.props.errors}
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <Row form>
                <Col>
                  <WizardInput
                    type="text"
                    label="Primer Nombre*"
                    id="PrimerNombre"
                    name="PrimerNombre"
                    innerRef={this.props.register({
                      required: 'requerido',
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                  <WizardInput
                    type="text"
                    label="Segundo Nombre"
                    id="SegundoNombre"
                    name="SegundoNombre"
                    innerRef={this.props.register()}
                    errors={this.props.errors}
                  />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <WizardInput
                    type="text"
                    label="Primer Apellido*"
                    id="PrimerApellido"
                    name="PrimerApellido"
                    innerRef={this.props.register({
                      required: 'requerido'
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                  <WizardInput
                    type="text"
                    label="Segundo Apellido*"
                    id="SegundoApellido"
                    name="SegundoApellido"
                    innerRef={this.props.register({
                      required: 'requerido',
                    })}
                    errors={this.props.errors}
                  />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <WizardInput
                    type="Date"
                    label="Fecha Expedición"
                    id="FechaExpedicion"
                    name="FechaExpedicion"
                    innerRef={this.props.register({
                      required: 'requerido',
                      validate: value => {
                        const selectedDate = new Date(value)
                        const today = new Date()
                        return selectedDate < today
                      }
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                  <label>Pais Expedición</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="z7"
                    id="paisexpedicion"
                    placeholder="Seleccionar"
                    name="paisexpedicion"
                    onChange={this.cambiarselectpais}
                    value={this.state.paisexpedicion}
                    options={this.state.ListaPaises}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                </Col>
              </Row>
              <Row form>
                <Col>
                  <label>Departamento de Expedición*</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="z7"
                    id="departamentoexpedicion"
                    placeholder="Seleccionar"
                    name="departamentoexpedicion"
                    onChange={this.cambiarselectdepartamento}
                    value={this.state.departamentoexpedicion}
                    options={this.state.Listadpto}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                  <label>Ciudad / Municipio de Expedición*</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="z6"
                    id="ciudadmunicipioexpedicion"
                    placeholder="Seleccionar"
                    name="ciudadmunicipioexpedicion"
                    onChange={this.cambiarselectciudad}
                    value={this.state.ciudadmunicipioexpedicion}
                    options={this.state.ListaCiudad}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                  
                </Col>
              </Row>
              <Row form>
                <Col>
                  <WizardInput
                    type="Date"
                    label="Fecha de Nacimiento*"
                    id="FechaNacimiento"
                    name="FechaNacimiento"
                    innerRef={this.props.register({
                      required: 'Fecha de Nacimiento requerida',
                      validate: value => {
                        const selectedDate = new Date(value)
                        const today = new Date()
                        return selectedDate < today
                      }
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                <label>Pais de Nacimiento</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="z5"
                    id="paisnacimiento"
                    placeholder="Seleccionar"
                    name="paisnacimiento"
                    onChange={this.cambiarselectpais2}
                    value={this.state.paisnacimiento}
                    options={this.state.ListaPaises}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                
              </Row>
              <Row form>
                <Col>
                  <label>Departamento de Nacimiento*</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="superponer2"
                    id="departamentonacimiento"
                    placeholder="Seleccionar"
                    name="departamentonacimiento"
                    onChange={this.cambiarselectdepartamento2}
                    value={this.state.departamentonacimiento}
                    options={this.state.Listadpto2}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                </Col>
                <Col>
                  <label>Ciudad / Municipio Nacimiento*</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="superponer2"
                    id="ciudadmunicipionacimiento"
                    placeholder="Seleccionar"
                    name="ciudadmunicipionacimiento"
                    onChange={this.cambiarselectciudad2}
                    value={this.state.ciudadmunicipionacimiento}
                    options={this.state.ListaCiudad2}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
               
                </Col>
              </Row>
              <br />
              <WizardInput
                type="checkbox"
                id="agreeToTerms"
                tag={CustomInput}
                label={<Fragment>Activo</Fragment>}
                name="agreeToTerms"
                innerRef={this.props.register()}
                errors={this.props.errors}
              />
            </div>
          )}
        </Fragment>
      </>
    );
  }
}

export default BasicUserForm;
