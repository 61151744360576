import React, { useState, useEffect, Fragment } from 'react';
import cogoToast from 'cogo-toast';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PrejuridicoContext } from './PrejuridicoContext';
import PageHeader from '../../../components/common/PageHeader';
import { juridicoServices } from '../../../services';
import { currencyFormat, idFormat, refFormat } from '../../../helpers/tableFormatters'
import MainTable from '../../../components/tables/MainTable';
import PrejuridicoDetails from './PrejuridicoDetails';
import ButtonIcon from '../../../components/common/ButtonIcon';
import ConfirmModal from '../../../components/modals/confirmModal';

const Prejuridico = () => {
  const [loading, setLoading] = useState(false);

  const [searchKey, setSearchKey] = useState();

  const [cobrosList, setCobrosList] = useState([]);
  const [selectedCredits, setSelectedCredits] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const [clientSelected, setClientSelected] = useState();
  const [lawyerSelected, setLawyerSelected] = useState();
  const [dataCost, setDataCost] = useState();
  const [totalCost, setTotalCost] = useState(0);
  const [total, setTotal] = useState(0);
  const [generalInfo, setGeneralInfo] = useState();

  const [saveId, setSaveId] = useState();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    loadCobros();
  }, [])

  useEffect(() => {
    if(!dataCost) return
    const tempTotalCost = Object.keys(dataCost).map(item => 
      dataCost[item] 
        ? dataCost[item]
        : 0).reduce((a, b) => 
          Number(a) + Number(b))
    setTotalCost(tempTotalCost)
  }, [dataCost])

  useEffect(() => {
    if(!selectedCredits || !selectedCredits[0]) return;
    const totalCredits = selectedCredits.map(item => item.monto || 0).reduce((a,b) => a + b)
    const tempTotal = totalCost + totalCredits;
    setTotal(tempTotal)
  }, [totalCost, selectedCredits])

  useEffect(() => {
    loadCobros();
  }, [searchKey])

  const columns = [
    {
        dataField: 'prestamo',
        text: 'Nro. Solicitud',
        headerClasses: 'border-0',
        align: 'left',
        classes: 'border-0 py-2 align-middle',
        sort: true,
        formatter: (dataField) => refFormat(dataField),
    },
    {
        dataField: 'fecha',
        text: 'Fecha',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
      dataField: 'credito',
      text: 'Nro. Crédito',
      headerClasses: 'border-0',
      align: 'center',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'nomCliente',
      text: 'Cliente',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      formatter: (dataField, selected) => clientFormatter(dataField, selected),
      sort: true
    },
    {
      dataField: 'nomPagaduria',
      text: 'Pagaduría',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
      sort: true
    },
    {
      dataField: 'monto',
      text: 'Saldo',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: 'edad',
      text: 'Edad',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      sort: true,
    },
    {
        dataField: '',
        headerClasses: 'border-0 text-center',
        text: 'Acción',
        classes: 'border-0 py-2',
        sort: false,
        formatter: (dataField, selected) => actionsFormatter(dataField, selected),
    },
  ];

  const clientFormatter = (dataField, fieldSelected) => {
    return (
      <div>
        <div className='text-primary'>{fieldSelected.nomCliente}</div>
        <div>{`${fieldSelected.tipoIdCliente}`} {idFormat(fieldSelected.idCliente)}</div>
      </div>
    )
  }

  const actionsFormatter = (dataField, fieldSelected) => {
    if(!fieldSelected) return <></>
    return (
      <div className='pl-4'>
      <IconButton
        onClick={() => handleShowDetails(fieldSelected)}
      >
        <FontAwesomeIcon
          style={{ width: '16px' }}
          icon={faGavel}
          color="SteelBlue"
        />
      </IconButton>
      </div>
    )
  }

  const loadCobros = async () => {
    // setLoading(true);
    const response = await juridicoServices.getCobrosPrejuridicos(searchKey);
    if(!response || response.error){
      // setLoading(false);
      cogoToast.error(response.msg, 
        { position: 'bottom-left', heading: 'Error al cargar la información' });
      return;
    }
    setCobrosList(response.objResponse);
    // setLoading(false);
  }

  const handleSearch = value => {
    setSearchKey(value);
  }

  const handleSelectCheckbox = (data, isSelect) => {
    data.tipo = 1;
    let tempSelecetdCredits;
    if (isSelect) {
      tempSelecetdCredits = [...selectedCredits];
      tempSelecetdCredits.push(data);
    } else {
      tempSelecetdCredits = selectedCredits.filter(credit => credit.prestamo != data.prestamo);
    }

    setSelectedCredits(tempSelecetdCredits)
  }

  const handleShowDetails = (selected) => {
    if(selected) {
      setClientSelected({
        id: selected.cliente,
        terceroInformacion: {
          identificacion: selected.idCliente,
          tipoIdentificacion: selected.tipoIdCliente,
          nombreCompleto: selected.nomCliente,
        },
        terceroUbicacion: {
          correo: selected.correoDemandado,
          telefono1: selected.telefonoDemandado,
        }
      })
      setSelectedCredits([selected])
    }
    setShowDetails(true);
  }

  const onClientSelect = (client) => {
    setClientSelected(client)
  }

  const onSelectLawyer = (lawyer) => {
    setLawyerSelected(lawyer)
  }

  const handleBack = () => {
    loadCobros();
    setShowDetails(false);
    setSelectedCredits([]);
    setClientSelected(null);
    setLawyerSelected(null);
    setSaveId(null);
  }

  const handleChangeCost = (values) => {
    setDataCost(values);
  }

  const handleGeneralInfoChange = (values) => {
    setGeneralInfo(values);
  }

  const onSave = ({ noBack }) => {
    setLoading(true)
    return new Promise(async (resolve, reject) => {
      const payload = {
        "procesoJuridico": saveId || 0,
        "demandado": clientSelected && clientSelected.id,
        "abogado": lawyerSelected && lawyerSelected.id,
        "fechaLiquidacion": "",
        "juzgado": generalInfo && generalInfo.despacho,
        "numeroProceso": generalInfo && generalInfo.numRef,
        "fechaNotificacion": generalInfo && generalInfo.dateNotification,
        "valorEmbargo": total,
        "honorarioAbogado": dataCost ? Number(dataCost.costLawyer || 0) : 0,
        "honorarioCurador": dataCost ? Number(dataCost.costCurador || 0) : 0,
        "emplazamiento": dataCost ? Number(dataCost.emplazamiento || 0) : 0,
        "citatorio": dataCost ? Number(dataCost.citatory || 0) : 0,
        "notificacion": dataCost ? Number(dataCost.notificationAdvise || 0) : 0,
        "viaticos": dataCost ? Number(dataCost.viaticos || 0) : 0,
        "otrosGastos": dataCost ? Number(dataCost.costOther || 0) : 0,
        "intereses": dataCost ? Number(dataCost.costIntereses || 0) : 0,
        "estado": 1,
        "prestamos": selectedCredits && selectedCredits.map(item => ({ prestamo: item.prestamo }))
      }

      const response = await juridicoServices.savePrejuridico(payload);


      if (!response || response.error || !response.objResponse) {
        cogoToast.error(response.msg || "No se logro completar la operación de Guardar",
          { position: 'bottom-left', heading: 'Error' });
        setLoading(false);
        resolve(false);
      }

      const id = response.objResponse.procesoJuridico;

      setSaveId({ id });
      setLoading(false);
      cogoToast.success("Información guardada con éxito",
          { position: 'bottom-left', heading: 'Exitoso' });
      if(!noBack) handleBack();
      resolve({ id });
    })
  }

  const liquidar = async () => {
    const onSaveResponse = await onSave({noBack: true})
    if(!onSaveResponse || !onSaveResponse?.id) return;
    const payload = {
      "procesoJuridico": onSaveResponse.id,
    }
    const response = await juridicoServices.liquidar(payload);


    if (!response || response.error || !response.objResponse) {
      cogoToast.error(response.msg || "No se logro completar la operación de Liquidación",
        { position: 'bottom-left', heading: 'Error' });
      setLoading(false);
      return;
    }
    setLoading(false);
    cogoToast.success("Operación completada",
        { position: 'bottom-left', heading: 'Exitoso' });
    handleBack();
  }

  const onLiquidar = () => {
    setShowModal(true);
  }

  return (
    <PrejuridicoContext.Provider value={{
      loading,
      client: clientSelected,
      lawyer: lawyerSelected,
      credits: selectedCredits,
      totalCost,
      total,
      onClientSelect,
      onSelectLawyer,
      handleChangeCost,
      handleGeneralInfoChange,
      onSave,
      onLiquidar
    }}>
      <Fragment>
        <ConfirmModal 
          open={showModal} 
          onCancel={() => setShowModal(false)} 
          onOk={() => {
            setShowModal(false)
            liquidar()
          }} 
          title="Confirmar Liquidación"
          text="Confirmar"
          body="Por favor confirme para realizar la operación"
        />
        <PageHeader title={showDetails ? "Proceso Jurídico" : "Cobros Pre-Jurídicos"} className="mb-3" >
        {showDetails && <ButtonIcon className="mt-3" onClick={handleBack} icon={faArrowLeft} transform="shrink-3 down-2" color="falcon-default" size="sm">
          Atrás
        </ButtonIcon>}
        </PageHeader>
        {showDetails ? (
          <PrejuridicoDetails context={PrejuridicoContext} />
        ) : (
          <MainTable 
            data={cobrosList}
            currentSearch={searchKey}
            columns={columns} 
            keyField="prestamo"
            search={handleSearch}
            handleSelect={{
              mode: 'checkbox',
              clickToSelect: true,
              selected: selectedCredits.map(credit => credit.prestamo),
              onSelect: handleSelectCheckbox,
              hideSelectAll: true,
              selectColumnPosition: 'left',
              selectColumnStyle: { paddingLeft: '20px', verticalAlign: 'middle' }
            }}
            className="px-2"
            loading={loading}
            customActionTitle=""
            customActionIcon={faGavel}
            handleCustomAction={() => handleShowDetails()}
            customAction={selectedCredits && selectedCredits.length > 1}
            noAdd
          />
        )}
      </Fragment>
    </PrejuridicoContext.Provider>
  );
};

export default Prejuridico
