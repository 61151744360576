import React, { useContext } from 'react';
import { Row, Spinner } from 'reactstrap';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import CreditInfo from './sections/CreditInfo';
import ClientInfo from './sections/ClientInfo';
import ObservationsForm from './sections/ObservationsForm';
import GeneralConditionsInfo from './sections/GeneralConditionsInfo';
import { PreliquidacionContext } from './PreliquidacionContext';

const SolicitudDetails = () => {
  const context = useContext(PreliquidacionContext);
  const { loading } = context;
  
  if(loading){
    return (
      <Row className="py-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </Row>
    )
  }

  return (
    <ContentWithAsideLayout aside={<GeneralConditionsInfo />} isStickyAside={false}>
      <CreditInfo />
      <ClientInfo />
      <ObservationsForm />
    </ContentWithAsideLayout>
  )
}

export default SolicitudDetails