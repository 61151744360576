import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import AuthProvider from './context/AuthProvider';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

const App = () => {
  return (
    <AuthProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </AuthProvider>
  );
};

export default App;
