import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const createSolicitud = data => {
    const sesionToken = authSesion();
    data.sesion = sesionToken;
    return fetch(`${urlApi}/solicitud`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getSolicitudes = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/solicitud/pend/${sesionToken}/1/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getLiqSolicitudes = (searchKey) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/solicitud/pend/${sesionToken}/2/${searchKey || '*'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getSolicitud = (id) => {
    if(!id) return;
    const sesionToken = authSesion();
    return fetch(`${urlApi}/solicitud/id/${sesionToken}/${id}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const preliquidarSolicitude = data => {
    const sesionToken = authSesion();
    data.sesion = sesionToken;
    return fetch(`${urlApi}/solicitud/pre`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const liquidarSolicitude = data => {
    const sesionToken = authSesion();
    data.sesion = sesionToken;
    return fetch(`${urlApi}/prestamo`, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}


const getCreditLines = (id) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/lineacredito/id/${sesionToken}/${id || '0'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getCreditSublines = (id) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/lineacredito/sub/${sesionToken}/0/${id || '0'}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const solicitudServices = {
  createSolicitud,
  getSolicitudes,
  getLiqSolicitudes,
  getSolicitud,
  preliquidarSolicitude,
  liquidarSolicitude,
  getCreditLines,
  getCreditSublines
}