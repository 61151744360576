import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const add = (reqBody) => 
    fetch(`${urlApi}/tercero`, {
        method: 'POST',
        headers,
        body: JSON.stringify(reqBody)
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });

const edit = (reqBody) => 
    fetch(`${urlApi}/tercero`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(reqBody)
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });


const getAll = ({
    keysearch,
    state,
    roles,
    page,
    size,
}) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/tercero/nom/${sesionToken}/${keysearch || "*"}/${state|| "1,0"}/${roles || "*"}/${page || "1"}/${size || "25"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getByPattern = ({pattern, roles}) => {
    if(!pattern) return;
    const sesionToken = authSesion();
    return fetch(`${urlApi}/tercero/find/${sesionToken}/${pattern}/${roles || "*"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(res => res)
    .then(res => {
        return res.status === 0 ? res : {error: true, msg: res.objError ? res.objError.msgError : res.message}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}


export const personsServices = {
    add,
    edit,
    getAll,
    getByPattern
}