import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import RequiredMarked from '../../../components/common/RequiredMarked';
import { FORM_DATA_CATEGORY } from '../RefinancePage';

const ObservationsSection = ({ handleForm, handleSubmit }) => {
  const { register, triggerValidation, errors, setValue, getValues } = useForm();

  const onChange = async e => {
    const { name, value } = e.target;
    setValue(name, value);
    const formData = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleForm(isValid ? formData : null, FORM_DATA_CATEGORY.OBSERVATIONS);
  }
  

  return (
    <Card className={`my-3`} >
      <CardBody className="bg-light border-top">
        <form onChange={onChange}>
        <Row>
        <Col lg={12} xs={12} >
        <FormGroup>
          <Label for="observations">
              Anotaciones
          </Label>
          <Input
              type="textarea"
              id="observations"
              invalid={errors['observations']}
              maxLength={300}
              name="observations"
              {...register("observations", {
              required: false,
              })}
          />
        </FormGroup>
        </Col>
        </Row>
        </form>
        <Col lg={12} xs={12} className="text-right p-0" >
        <Button onClick={handleSubmit} className="ml-2" transform="shrink-3 down-2" color="primary" >
          Refinanciar
        </Button>
        </Col>
      </CardBody>
    </Card>
  )
}

export default ObservationsSection