import React, {useState, useEffect} from 'react';
import GestionPersonaEmpresaForm from './GestionPersonaEmpresaForm';
import { urlApi } from '../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton } from '@material-ui/core';
import { personsServices } from '../../services';
import PersonDetails from './PersonDetails';
import Badge from 'reactstrap/es/Badge';
import PageHeader from '../common/PageHeader';
import MainTable from '../tables/MainTable';
import { usePersonFilter } from './context';

const GestionUsuarioEmpresa = () => {
  const pageSize = 25;
  const [initialdata] = useState({
    Nombre: '',
    Identificacion: '',
    Estado: true
  })
  const [loading, setLoading] = useState();
  const [ListData, setListData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialdata);
  const [currentModelAction, setCurrentModelAction] = useState("");
  const [editedContent, setEditedContent] = useState();
  const [currentSearch, setCurrentSearch] = useState("");
  const [pagesLoaded, setPagesLoaded] = useState([1]);
  const [listDataPag, setListDataPag] = useState({});
  const { stateFilter, rolesFilter, saveFiltersMetadata } = usePersonFilter();

  const columns = [
    {
        dataField: 'terceroInformacion.nombre1',
        text: 'Nombre/Razón social',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        formatter: (name, row) =>  nameFormatter(name, row),
        sort: true
    },
    {
        dataField: 'terceroInformacion.tipoIdentificacion',
        headerClasses: 'border-0',
        text: 'Tipo Id',
        classes: 'border-0 py-2 align-middle',
        sort: false
    },
    {
        dataField: 'terceroInformacion.identificacion',
        headerClasses: 'border-0',
        text: 'Identificación',
        classes: 'border-0 py-2 align-middle',
        sort: false
    },
    {
        dataField: 'terceroUbicacion.correo',
        headerClasses: 'border-0',
        text: 'Correo',
        classes: 'border-0 py-2 align-middle',
        sort: false
    },
    {
        dataField: 'terceroUbicacion.telefono1',
        headerClasses: 'border-0',
        text: 'Teléfono',
        classes: 'border-0 py-2 align-middle',
        sort: false
    },
    {
        dataField: 'terceroInformacion.estado',
        headerClasses: 'border-0',
        text: 'Estado',
        formatter: (estado) => stateFormatter(estado),
        classes: 'border-0 py-2 align-middle',
    },
    {
        dataField: '',
        headerClasses: 'border-0',
        text: 'Acciones',
        classes: 'border-0 py-2 align-middle',
        sort: false,
        formatter: (dataField, selected) => actionsFormatter(dataField, selected),
        align: 'center',
        headerAlign: 'center'
    },
  ];

  useEffect(() => {
    load();
  }, [])

  const nameFormatter = (name, data) => {
    if(!data) return <></>
    const {nombre2, apellido1, apellido2, razonSocial} = data.terceroInformacion;
    if(data.terceroInformacion.tipoIdentificacion !== "NIT"){
      return <span>{`${name} ${nombre2} ${apellido1} ${apellido2}`}</span>
    }
    return <span>{razonSocial}</span>
  } 

  const stateFormatter = status => {
    let color = '';
    let text = '';
    switch (status) {
      case '1':
        color = 'success';
        text = 'Activo';
        break;
      case '0':
        color = 'secondary';
        text = 'Inactivo';
        break;
      default:
        color = 'warning';
        text = 'Pendiente';
    }
    return (
      <div>
      <Badge color={`soft-${color}`} className="rounded-capsule">
        {text}
      </Badge>
      </div>
    );
  };

  const actionsFormatter = (dataField, selected) => 
    <div>
      <Tooltip title="Ver">
        <IconButton
          onClick={() => actiononContact('edit', selected)}
        >
          <FontAwesomeIcon
            style={{ width: '20px' }}
            icon={faEye}
            color="SteelBlue"
          />
        </IconButton>
      </Tooltip>
  </div>

  const load = async (props = {}) => {
    setLoading(true);
    const resp = await personsServices.getAll({
        keysearch: currentSearch,
        state: stateFilter,
        roles: rolesFilter,
        size: pageSize,
        ...props
      })
    if(resp.error){
      cogoToast.error(resp.error.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      setLoading(false);
      return
    }
    setTotalData(resp.metadata.todos);
    setListData(resp.objResponse);
    const tempListDataPag = {};
    tempListDataPag['1'] = resp.objResponse; 
    setListDataPag(tempListDataPag);
    saveFiltersMetadata(resp.metadata);
    setLoading(false);
  }

  const MostrarFormulario = (e, newSelected) => {
    setEstadoForm(false);
    if (e === 'Nuevo') {
      setEditedContent([]);
    } else if (e === 'Cancelar') {
      setCurrentModelAction(null)
      setDataEdict(initialdata);
    } else if (e === 'Cargar') {
      setCurrentModelAction(null)
      load();
    } else if (e === 'Guardado') {
      setDataEdict(initialdata);
      load();
    } else if (e === 'Editado') {
      setEditedContent(newSelected)
    } else if (e.perfil) {
      setDataEdict(e);
    }
  }

  const onFilter = (valueState, valueRoles, search) => {
    const sf = search === "" ? "*" : search;
    Consultar(sf, valueState, valueRoles );
  }

  const Consultar = (data, inState, inRoles) => {
    setPagesLoaded([1]);
    const filterState = inState || stateFilter;
    const filterRoles = inRoles || rolesFilter;
    let filtro = !data ? "*" : data != "*" ? data : currentSearch || data;

    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    setCurrentSearch(filtro === "*" ? "" : filtro)
 
    if (filtro === "*" && filterState == "1,0" && filterRoles == "*"){
      load({
        keysearch: filtro,
        state: filterState,
        roles: filterRoles
      });
    }else{
    fetch(urlApi + '/tercero/nom/' + sesion + '/' + filtro + '/'+filterState+'/'+filterRoles+'/1/' + pageSize, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        if (data.status === 0) {
          setTotalData(data.metadata.paginas*pageSize)
          setListData(data.objResponse);
          const tempListDataPag = {};
          tempListDataPag['1'] = data.objResponse; 
          setListDataPag(tempListDataPag);
          saveFiltersMetadata(data.metadata);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
    };
  }

  const actiononContact = async (action, e = null) => {
    if (action === 'add') {
      setEstadoForm(!EstadoForm );
      setCurrentModelAction('add');
      setEditedContent([]);
    } else if (action === 'edit') {
      setCurrentModelAction('edit');
      setEditedContent(e)
    }
  };

  const handlePagination = async page => {
    if(pagesLoaded.includes(page) || ListData.length < pageSize) return;
    setLoading(true);
    setPagesLoaded([...pagesLoaded, page]);
    const resp = await personsServices.getAll(
      { 
        keysearch: currentSearch,
        state: stateFilter,
        roles: rolesFilter,
        page: page,
      }
    )
    if(resp.error){
      cogoToast.error(resp.error.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      setLoading(false);
      return
    }
    const temp = [];
    const tempListDataPag = listDataPag;
    tempListDataPag[String(page)] = resp.objResponse;

    const maxPageLoaded = [...pagesLoaded, page].sort((a,b) => b - a)[0];
    Array.from({length: maxPageLoaded}, (_, i) => i + 1).map(p => {

      if(tempListDataPag[String(p)]){
        temp.push(...tempListDataPag[String(p)])
      } else {
        temp.push(...Array.from({length: pageSize}, (_, i) => ""))
      }
    })

    setListData(temp);
    setListDataPag(tempListDataPag);
    setLoading(false);
  }
  
  return (
    <>
    {EstadoForm ? (
      <GestionPersonaEmpresaForm
        MostrarFormulario={data => MostrarFormulario(data)}
        data={DataEdict}
        actiononContact={(action, data) => actiononContact(action, data)}
      />
    ) : (
      currentModelAction !== 'edit' ? 
      <div>
        <PageHeader
          title="Persona - Empresa"
          className="mb-3"
        />
        {ListData && (
            <MainTable 
              data={ListData} 
              loading={loading}
              totalData={totalData}
              currentSearch={currentSearch}
              columns={columns} 
              keyField="id" 
              handleAddNew={actiononContact}
              search={Consultar}
              onFilter={onFilter}
              handlePagination={handlePagination}
              noModal
              className="px-2"
              filters
              customPagination
            />
        )}
      </div>
      : <PersonDetails handleAction={MostrarFormulario} selected={editedContent} />
    )}
    </>
  )
}

export default GestionUsuarioEmpresa;
