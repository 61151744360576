import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
//import DashboardAlt from '../components/dashboard-alt/DashboardAlt';
import NavbarTop from '../components/navbar/NavbarTop';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
//import loadable from '@loadable/component';
import AppContext from '../context/Context';
import ProductProvider from '../components/e-commerce/ProductProvider';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';

import GestionPersonaEmpresa from '../components/GestionPersonaEmpresa';
import useAuth from '../hooks/useAuth';

import EnvironmentProvider from '../EnvironmentProvider';

// Pages
import HomePage from '../pages/home/HomePage';
import UsersPage from '../pages/users/UsersPage';
import ProfilesPage from '../pages/profiles/ProfilesPage';
import SessionsPage from '../pages/sessions/SessionsPage';
import AdvisersPage from '../pages/advisers/AdvisersPage';
import SimulatorPage from '../pages/simulator/SimulatorPage';
import SolicitudPage from '../pages/solicitud/SolicitudPage';
import PreliquidacionPage from '../pages/preliquidacion/PreliquidacionPage';
import DesembolsoPage from '../pages/desembolso/DesembolsoPage';
import RecaudarPage from '../pages/recaudar/RecaudarPage';
import StatePage from '../pages/state/StatePage';
import RecaudoPage from '../pages/recaudo/RecaudoPage';
import MovesPage from '../pages/moves/MovesPage';
import Prejuridico from '../pages/juridico/cobro/Prejuridico';
import PrejuridicoProcess from '../pages/juridico/guardados/PrejuridicoProcess';
import JuridicoProcess from '../pages/juridico/liquidados/JuridicoProcess';
import InitCreditPage from '../pages/init-credit/InitCreditPage';

const DashboardLayout = ({ location }) => {
  const { isFluid, isVertical, navbarStyle } = useContext(AppContext);
  const isKanban = getPageName('kanban');
  const [auth] = useAuth();
  var arrayRutas = JSON.parse(sessionStorage.getItem('rutas'));
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    if(arrayRutas){
      if(!arrayRutas.includes(location.pathname)){
        history.push('/errors/NoAutorizado');
      }
    }
    
  }, [location.pathname]);

  // useEffect(() => {
  //   DashboardRoutes.preload();
  // }, []);

  if(!auth) return <Redirect to="/authentication/basic/login" />;

  return (
    <EnvironmentProvider>
      <div className={isFluid || isKanban ? 'container-fluid' : 'container'}>
        {isVertical && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
        <ProductProvider>
          <div className="content">
            <NavbarTop />
            <Switch>

                {/* Home */}
                <Route path="/" exact component={HomePage} />

                {/* Security Section */}
                <Route path="/GestionUsuarios" exact component={UsersPage} />
                <Route path="/GestionPerfiles" exact component={ProfilesPage} />
                <Route path="/GestionSesiones" exact component={SessionsPage} />
                
                {/* Config Section */}
                <Route path="/GestionPersonaEmpresa" exact component={GestionPersonaEmpresa} />
                <Route path="/GestionAsesores" exact component={AdvisersPage} />

                {/* Operations Section */}
                <Route path="/Simulador" exact component={SimulatorPage} />
                <Route path="/Solicitud" exact component={SolicitudPage} />
                <Route path="/Preliquidacion" exact component={PreliquidacionPage} />
                <Route path="/Liquidacion" exact component={() => <PreliquidacionPage liquidacion />} />
                <Route path="/Desembolso" exact component={DesembolsoPage} />
                <Route path="/Recaudo" exact component={RecaudarPage} />
                <Route path="/EstadoCuenta" exact component={StatePage} />
                <Route path="/ConsultaRecaudo" exact component={RecaudoPage} />
                <Route path="/InicializarCredito" exact component={InitCreditPage} />

                <Route path="/MovTesoreria" exact component={MovesPage} />

                {/* Jurídico */}
                <Route path="/Prejuridico" exact component={Prejuridico} />
                <Route path="/Proc-Prejuridico" exact component={PrejuridicoProcess} />
                <Route path="/Proc-Juridico" exact component={JuridicoProcess} />
                
                {/* 404 */}
                <Redirect to="/errors/404" />
                
            </Switch>

            {!isKanban && <Footer />}
          </div>
          <SidePanelModal path={location.pathname} />
        </ProductProvider>
      </div>
    </EnvironmentProvider>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
