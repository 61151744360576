import React, { useState, useContext } from 'react';
import { Col, Card, CardBody, Row, FormGroup, Input, Label, CustomInput,  Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { StateContext } from '../../state/StateContext';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import SimulatorPage from '../../simulator/SimulatorPage';

import { FORM_DATA_CATEGORY } from '../RefinancePage';

const GeneralConditions = ({ handleForm }) => {
    const { register, triggerValidation, errors, setValue, getValues } = useForm();
    const context = useContext(StateContext);
    const { creditSelected: selected } = context;

    const onChange = async e => {
      const { name, value } = e.target;
      setValue(name, value);
      const formCreditInfo = getValues();
      await triggerValidation();
      const isValid = Object.keys(errors).length < 1;
      handleForm(isValid ? formCreditInfo : null, FORM_DATA_CATEGORY.EXTRA_DATA);
    }

    const handleCutDate = (dateline) => {
      handleForm(dateline, FORM_DATA_CATEGORY.DATELINE)
    }

    const handleSubmit = (formData) => {
      handleForm(formData, FORM_DATA_CATEGORY.GENERAL)
    }

    return (
        <Card className={`my-3`} style={{backgroundColor: "#1b97ebd1", color: "white"}} >
          <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
          <CardBody className="border-top">
          <form onChange={onChange}>
            <Row>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Nro. del Crédito
                </Label>
                <Input
                  id="creditNumber"
                  name="creditNumber"
                  {...register("creditNumber", {
                    required: true,
                  })}
                />
                </FormGroup>
              </Col>
            </Row>
            </form>
            <SimulatorPage 
              handleSubmit={handleSubmit}
              onChangeDate={handleCutDate}
              defaultData={{ amount: `${selected.saldoProyectado}`}}
              invisibleFields={{amount: true}}
              bgColor="transparent"
              vertical
              noShadow
              noHeader
              requiredMark />
          </CardBody>
        </Card>
    )
};

export default GeneralConditions;
