const formatter = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
})

export const formatterId = num => {
    return new Intl.NumberFormat('es-Es').format(num)
} 

export const currencyFormat = num => {
    return num ? formatter.format(num) : num
}