import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import ButtonIcon from '../../../components/common/ButtonIcon';
import Select from 'react-select';
import { SolicitudContext } from '../SolicitudContext';
import RequiredMarked from '../../../components/common/RequiredMarked';

const GeneralDataClient = () => {
  const context = useContext(SolicitudContext);
  const { client, adviser, onOpenModal, onSelectPagaduria } = context;
  
  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Datos Generales del Cliente">
        <ButtonIcon onClick={onOpenModal} icon="user" transform="shrink-3 down-2" color="falcon-default" size="sm">
            Buscar
        </ButtonIcon>
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        <>
        {adviser && (
          <Row>
            <Col lg={12} className="p-0" >
            <FormGroup>
              <Label for="pagaduria">
                <RequiredMarked />
                Pagaduria
              </Label>  
              <Select
                placeholder="Seleccionar"
                style="fullWidth-select"
                name="Pagaduria"
                onChange={(item) => onSelectPagaduria(item.value)}
                options={adviser.asesorPagaduria.map(pag => ({ label: pag.infoPagaduria, value: pag.idPagaduria }))}
              />
            </FormGroup>
            </Col>
          </Row>
        )}
        {client ? (
        <Row>
          <Col lg className="col-xxl-5">
            <DetailRow title="Identificación">{client.terceroInformacion.identificacion || <span className='p-2'></span>}</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Telefono">{client.terceroUbicacion.telefono1 || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            <DetailRow childrenClasses="text-uppercase" title="Razon Social o Nombre del Cliente">{
              `${client.terceroInformacion.nombre1} ${client.terceroInformacion.nombre2}
              ${client.terceroInformacion.apellido1} ${client.terceroInformacion.apellido2}` || 
              <span className='p-2'></span>
            }</DetailRow>
            <DetailRow childrenClasses="text-uppercase" title="Correo">{client.terceroUbicacion.correo || <span className='p-2'></span>}</DetailRow>
          </Col>
          <Col lg={12}>
          <DetailRow childrenClasses="text-uppercase" title="Dirección">{`${client.terceroUbicacion.direccionRes} / ${client.terceroUbicacion.infoMunicipioRes} / ${client.terceroUbicacion.infoDepartamentoRes} / ${client.terceroUbicacion.infoPaisRes}` || <span className='p-2'></span>}</DetailRow>
          </Col>
        </Row>
        ) : <Label for="pagaduria" className="py-4">
              <RequiredMarked />
              Por favor <a className='fw-bold pointer' onClick={onOpenModal}>seleccione</a> un cliente.
            </Label>  }
        </>
      </CardBody>
    </Card>
  );
};

export default GeneralDataClient;
