import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
//import routes from '../../routes';
import Flex from '../common/Flex';
import Logo from './Logo';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import { urlApi } from '../../configuracion/serverConfig';
import { toast } from 'react-toastify';

const NavbarVertical = ({ navbarStyle }) => {
  const navBarRef = useRef(null);
  const [rutas, setRutas] = useState(null);

  const {
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    isCombo,
    setShowBurgerMenu,
    setNavbarCollapsed
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };

  const getMenu = () => {

    let objSesion = JSON.parse(sessionStorage.getItem('Usuario'));
    
    if(objSesion !== null){
      fetch( urlApi + '/menu/' + objSesion.sesionId, {
        method: 'GET',
        body: JSON.stringify(),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Accept: 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          if (data.status === 0) {
            let rutasArray = ['/'];
            data.objResponse.forEach(rutaPadre => {
                rutaPadre.tareas.forEach(rutasHijas => {
                  rutasArray.push(rutasHijas.formulario.ruta);
                });               
            });
            sessionStorage.setItem('rutas',JSON.stringify(rutasArray));
            setRutas(data.objResponse);
          } else {
            toast.error(data.objError.msgError);
          }
        })
        .catch(err => console.log('err', err));
    }
  }

  useEffect(() => {
    getMenu();
  }, [])
  
    return (
      <Navbar
        expand={navbarBreakPoint}
        className={classNames('navbar-vertical navbar-glass  navbar-vibrant navbar navbar-expand-xl navbar-light', {
          [`navbar-${navbarStyle}`]: navbarStyle !== 'vibrant'
        })}
        light
      >
        <Flex align="center">
          <ToggleButton
            isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
            setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
          />
          <Logo at="navbar-vertical" width={140} />
        </Flex>

        <Collapse
          style={{ backgroundImage: 'linear-gradient(-45deg,  rgba(0, 160, 255, 0.86)  ,  rgb(0, 72, 162))' }}
          navbar
          isOpen={showBurgerMenu}
          className="scrollbar"
          innerRef={navBarRef}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={() => {
            clearTimeout(time);
            HTMLClassList.remove('navbar-vertical-collapsed-hover');
          }}
        >
          <Nav navbar vertical>
            {rutas &&  <NavbarVerticalMenu routes={rutas} /> }
          </Nav>
          <div className="settings px-3 px-xl-0">
            {isCombo && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
                <Nav navbar>
                  <NavbarTopDropDownMenus
                    setNavbarCollapsed={setNavbarCollapsed}
                    setShowBurgerMenu={setShowBurgerMenu}
                  />
                </Nav>
              </div>
            )}
            {/* <div className="navbar-vertical-divider">
            <hr className="navbar-vertical-hr my-2" />
          </div>
          */}
          </div>
        </Collapse>
      </Navbar>
    );
  
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'vibrant'
};

export default NavbarVertical;
