import React, { Fragment } from 'react';
import WizardInput from './WizardInput';
import {
  Card,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Table,
  CustomInput,
  Form,
  Col,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import { urlApi } from '../../../configuracion/serverConfig';
import ContactListComponent from '../TablaFormPropiedades';
const options = ['Cliente', 'Pagaduria', 'Vendedor', 'Proveedor'];
const Cuentas = [{label:'Ahorro', value: 'AH'}, {label:'Corriente', value: 'CO'}];
class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: '',
      DataGrilla: [],
    };
  }
  componentDidMount = () => {
    this.ConsultarBancos();
    if(this.props.ArrayFinanciera){
      this.setState((state) => ({
        ...state,
        DataGrilla: this.props.ArrayFinanciera,
      }));
    }
  };
  onInputchange = (data) => {
    if (data) {
      let name = data.target.name;
      let value = data.target.value;
      this.setState((state) => ({
        ...state,
        [name]: value,
      }));
      if (name === "ValorGlosado2") {
        this.setState((state) => ({
          ...state,
          ValorGlosado1: value,
        }));
      }
    }
  };

  ConsultarBancos = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    fetch(urlApi + '/banco/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.state = lstDatos[i].stado;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaBancos: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };


  cambiarselectbanco = data2 => {
    let databanco = { value: data2.value, label: data2.label };
    this.setState(state => ({
      ...state,
      Banco: databanco
    }));
  };

  cambiarselecttipocuenta = data3 => {
    let datatipocuenta = { value: data3.value, label: data3.label };
    this.setState(state => ({
      ...state,
      TipoCuenta: datatipocuenta
    }));
  };
  
  quitarCampo = (data) => {
    for (let i = 0; i < this.state.DataGrilla.length; i++) {
      if (data.IdBanco === this.state.DataGrilla[i].IdBanco && data.IdTipoCuenta === this.state.DataGrilla[i].IdTipoCuenta) {
        this.state.DataGrilla.splice(i, 1);
        break;
      }
    }
    this.setState((state) => ({
      ...state,
      arrayList2: this.state.arrayList2,
    }));
  };

  agregarCampo = () => {
    if(this.state.Banco){
    let ObjRegistro = {};
    let EstadoID = false;
    ObjRegistro.Banco = this.state.Banco.label;
    ObjRegistro.IdBanco = this.state.Banco.value;
    ObjRegistro.TipoCuenta = this.state.TipoCuenta.label;
    ObjRegistro.IdTipoCuenta = this.state.TipoCuenta.value;
    ObjRegistro.NumeroCuenta = this.state.NumeroCuenta;
    // ObjRegistro.EstadoID = this.state.propiedades
    if (this.state.DataGrilla.length > 0) {
      for (let i = 0; i < this.state.DataGrilla.length; i++) {
        if (this.state.DataGrilla[i].IdBanco == this.state.Banco.value && this.state.DataGrilla[i].IdTipoCuenta == this.state.TipoCuenta.value ) {
          this.state.DataGrilla[i].Banco = this.state.Banco.label;
          this.state.DataGrilla[i].TipoCuenta = this.state.TipoCuenta.label;
          this.state.DataGrilla[i].NumeroCuenta = this.state.NumeroCuenta;
          EstadoID = true;
        }
      }
    } else {
      this.setState(state => ({
        ...state,
        DataGrilla: [ObjRegistro]
      }));
      this.props.agregarcampo3(this.state.DataGrilla)
    }
    if (EstadoID == false) {
      let ObjRegistro2 = {};
      ObjRegistro2.Banco = this.state.Banco.label;
      ObjRegistro2.IdBanco = this.state.Banco.value;
      ObjRegistro2.TipoCuenta = this.state.TipoCuenta.label;
      ObjRegistro2.IdTipoCuenta = this.state.TipoCuenta.value;
      ObjRegistro2.NumeroCuenta = this.state.NumeroCuenta
      this.state.DataGrilla.push(ObjRegistro2);
      this.setState(state => ({
        ...state,
        DataGrilla: this.state.DataGrilla
      }));
      this.props.agregarcampo3(this.state.DataGrilla)

      // this.limpiar();

      // this.limpiar();
    }
  }
  };


  render() {
    return (
      <>
        <Fragment>
          <Row form>
            <Col>
              <label>Banco*</label>
              <Select
                className="z7"
                style="fullWidth-select"
                name="Banco"
                onChange={this.cambiarselectbanco}
                value={this.state.Banco}
                options={this.state.ListaBancos}
                {...this.props.register("terceroFinanciera")}
              />
            </Col>
            <Col>
              <label>Tipo de Cuenta*</label>
              <Select
                className="z7"
                style="fullWidth-select"
                name="TipoCuenta"
                onChange={this.cambiarselecttipocuenta}
                value={this.state.TipoCuenta}
                options={Cuentas}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Numero de Cuenta*"
                value={this.state.NumeroCuenta}
                onChange={this.onInputchange}
                // placeholder="Name"
                name="NumeroCuenta"
                // innerRef={register({
                //   required: 'Name is required',
                //   minLength: {
                //     value: 2,
                //     message: 'Min length 2'
                //   }
                // })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
          <Row>
            <Col>
            <div>
          <Button onClick={this.agregarCampo}>
            <i className="mr-1">
              {' '}
              <FontAwesomeIcon icon={faPlus} />
            </i>
            Agregar
          </Button>
        </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
            <div style={{ border: 'outset', borderRadius: '10px' }}>
                <div className="contact-table">
                  <ContactListComponent
                    panel={this.props.panel}
                    searchInput={this.props.searchInput}
                    contactlists={this.state.DataGrilla}
                    headerLista={["","Banco","Tipo de cuenta", "Numero de cuenta"]}
                    bodyLista={["Banco", "TipoCuenta", "NumeroCuenta"]}
                    quitarCampo={data => this.quitarCampo(data)}
                    NombreMaestro={'Maestro Registro Glosas'}
                    Acciones={true}
                    Consultar={data => this.Consultar(data)}
                    selectValue={data => this.selectValue(data)}
                    handleSearch={data => this.handleSearch(data)}
                    deleteSelected={() => this.deleteSelected}
                    actiononContact={(action, data) => this.actiononContact(action, data)}
                  />
                </div>
              </div>
              
            </Col>
          </Row>
        </Fragment>
      </>
    );
  }
}

export default BasicUserForm;
