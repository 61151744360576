import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import AppContext from '../../context/Context';
import { createCookie, getCookieValue } from '../../helpers/utils';

const SidePanelModal = ({ autoShow, showOnce, autoShowDelay, cookieExpireTime }) => {
  const {
    setIsOpenSidePanel,

  } = useContext(AppContext);

  useEffect(() => {
    let modalStatus = getCookieValue('modalClose');

    if (modalStatus === null && autoShow) {
      setTimeout(() => {
        setIsOpenSidePanel(prev => !prev);
        showOnce && createCookie('modalClose', false, cookieExpireTime);
      }, autoShowDelay);
    }
  }, [autoShow, showOnce, setIsOpenSidePanel, autoShowDelay, cookieExpireTime]);
  return (
    <div></div>
  );
};

SidePanelModal.propTypes = {
  autoShow: PropTypes.bool,
  showOnce: PropTypes.bool,
  autoShowDelay: PropTypes.number,
  cookieExpireTime: PropTypes.number
};

SidePanelModal.defaultProps = {
  autoShow: true,
  showOnce: true,
  autoShowDelay: 3000,
  cookieExpireTime: 7200000
};

export default SidePanelModal;
