import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  CustomInput,
  FormGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import DesembolsoInvoice from './invoice/DesembolsoInvoice';
import RecaudoInvoice from '../recaudar/invoice/RecaudoInvoice';
import { DesembolsoContext } from './DesembolsoContext';
import ButtonIcon from '../../components/common/ButtonIcon';
import numToWord from '../../helpers/numToWord';
import { currencyFormat } from '../../helpers/formatters';
import { formatterId } from '../../helpers/formatters';

function SuccessModal({ open, onOk }) {
  const context = useContext(DesembolsoContext);
  const { selected, payload, conceptosList } = context;

  const [ownProps, setOwnProps] = useState();

  useEffect(() => {
    if (!payload || !selected) return
    createProps()
  }, [selected, payload])

  const createProps  = () => {
    const cashAmount = payload.cashData ? payload.cashData.cashValue : 0
    const selectedConcept = conceptosList.filter(concept => concept.value == payload.conceptoTesoreria)
    const montoWord = numToWord(cashAmount || 0)
    
    const tempOwnProps = {
      client: selected.nomCliente,
      clientTypeId: selected.tipoIdCliente,
      clientId: selected.idCliente ? formatterId(Number(selected.idCliente)) : "",
      serial: payload.consecutivo,
      ciudad: payload.ciudad,
      idCredit: selected.idCredito,
      amount: currencyFormat(cashAmount || 0),
      concept: selectedConcept[0] ? selectedConcept[0].label : "",
      sumWord: montoWord || "",
      checkCash: payload.isCash,
      checkWireTransfer: payload.isWireTransfer,
      checkPSE: false,
      checkCheque: payload.isCheque,
      date: payload.fecha.split('-'),
      observations: payload.observaciones
    }

    setOwnProps(tempOwnProps)
  }

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader>
        <h5>Desembolso</h5>
        <span className='text-primary' style={{ fontSize: '0.8em' }}>{`Crédito ${ownProps ? ownProps.idCredit : ''}`}</span>
      </ModalHeader>
      <ModalBody className="p-3">
        <div className="text-center mt-4">
          <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" />
          <h6 className="fs-2 m-3">
            Desembolso Exitoso!
          </h6>
          <p className="fs--1 mb-0"></p>
          {ownProps && ownProps.checkCash && (
            <ButtonIcon color="falcon-default" size="sm" icon="arrow-down" className="mr-2 mb-2 mb-sm-0 p-1 border-none shadow-none">
              <PDFDownloadLink document={<RecaudoInvoice title="Comprobante Egreso" data={ownProps} egreso  />} fileName={`comprobante_egreso_${selected.idCredito}.pdf`} >
                {({ blob, url, loading, error }) =>
                  loading ? 'Cargando comprobante...' : 'Descargar comprobante de egreso'
                }
              </PDFDownloadLink>
            </ButtonIcon>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
            <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
                Aceptar
            </Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default SuccessModal

