import React, { useState, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { MovesPageContext } from './MovesPageContext';
import PageHeader from '../../components/common/PageHeader';
import MovesCaja from './sections/MovesCaja';

const types = [
  {id: '1', name: 'Recibo de Caja', altName: 'Recibo de Caja'},
  {id: '2', name: 'Comprobante de egreso', altName: 'Comprobante de egreso'},
]

const MovesPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [client, setClient] = useState();

  const toggleTab = id => {
    setActiveTab(id);
  }

  const onClientSelect = data => {
    setClient(data)
  }

  const handleCleanClient = () => {
    setClient(null);
  }

  return (
    <MovesPageContext.Provider value={{
      loading,
      client,
      onClientSelect,
      handleCleanClient,
      setLoading,
    }}>
      <Fragment>
        <PageHeader title="Movimiento de Tesorería" className="mb-3" >
        </PageHeader>
        <div>
          <Nav tabs className="custom-nav">
            {types && types.map((type, idx) => (
              <NavItem className="flex-grow-1 text-center cursor-pointer">
                <NavLink onClick={() => toggleTab(type.id)} className={`p-3  ${activeTab == (idx+1) ? 'active' : '' }`}>
                  {type.altName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <MovesCaja title="Movimiento de Recibo de Caja" />
            </TabPane>
            <TabPane tabId="2">
            <MovesCaja title="Movimiento de Comprobante de egreso" egreso />
            </TabPane>
          </TabContent>
        </div>
      </Fragment>
    </MovesPageContext.Provider>
  );
};

export default MovesPage
