import React, { createRef, Fragment, useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Spinner
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPaginationArray } from '../../helpers/utils';
import MainTableFilter from '../filters/MainTableFilter';

const MainTable = ({
    title,
    data,
    totalData,
    currentSearch = "",
    columns,
    keyField,
    handleAddNew,
    search,
    onFilter,
    className = "",
    headerClassName="",
    rowClasses,
    loading = false,
    pageSize = false,
    handlePagination,
    filters,
    noSearch,
    noAdd,
    customPagination,
    handleSelect,
    customAction,
    handleCustomAction,
    customActionTitle,
    customActionIcon,
    noPag,
    noHead,
    iconClassName
}) => {
    let table = createRef();
    const [searchValue, setSearchValue] = useState("");

    const [options, setOptions] = useState({
        custom: true,
        sizePerPage: pageSize || 25,
        totalSize: 0
    }) 
    const handleNextPage = ({ page, onPageChange }) => () => {
        onPageChange(page + 1);
        if(customPagination){
            setOptions({
                ...options,
                page: page + 1
            })
            handlePagination(page + 1);
        }
    };

    const handlePrevPage = ({ page, onPageChange }) => () => {
        onPageChange(page - 1);
    };
    
    const handleSearch = (arg, value) => {
        search(value ? value.keyword : searchValue);
    }

    const handleChangeFilter = (valuesState, valuesRoles) => {
        onFilter(valuesState, valuesRoles, searchValue);
    }

    useEffect(() => {
        if(data.length > 0){
            const extraData = data.length <= options.sizePerPage && options.sizePerPage !== 0 ? {page: 1} : {};
            setOptions({
                ...options,
                totalSize: totalData || data.length,
                ...extraData
            })
        }
    }, [data])

    return (
        <>
        {filters && (
            <MainTableFilter handleFilter={handleChangeFilter} />
        )}
        {data && columns && (
        <Card className={`main-table w-100 ${className} table__placeholder ${filters && "border-radius-top-0"}`}>
            {!noHead && (
                <>
                    {!noSearch ? (
                        <div className="main-table-header">
                            <div className="main-table-search-bar">
                                <Input
                                    placeholder="Buscar "
                                    defaultValue={currentSearch}
                                    className="react-form-search-input"
                                    onKeyPress={(e) => e.key === "Enter" && searchValue.trim() !== "" && handleSearch(null) }
                                    onChange={(e) => {
                                        setSearchValue(e.target.value)
                                        if(e.target.value === ""){
                                            handleSearch(null, {keyword: ""})
                                        }
                                    }}
                                />
                                <ButtonIcon onClick={handleSearch} icon="search" transform="shrink-3 down-2" color="falcon-default" size="sm">
                                    Buscar
                                </ButtonIcon>
                            </div>
                            {!noAdd && (
                                <div className="d-flex">
                                    <ButtonIcon onClick={() => handleAddNew('add', null)} icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
                                        Agregar
                                    </ButtonIcon>
                                </div>
                            )}
                            {customAction && (
                                <ButtonIcon onClick={handleCustomAction} icon={customActionIcon || "trash"} transform="shrink-3 down-2" color="falcon-default" size="sm">
                                    {customActionTitle}
                                </ButtonIcon>
                            )}
                        </div>
                    ) : (
                        !noAdd ? (
                            <FalconCardHeader title={title || ''} light={false} className={headerClassName}>
                                <Fragment>
                                    <ButtonIcon onClick={() => handleAddNew('add', null)} icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
                                        Agregar
                                    </ButtonIcon>
                                </Fragment>
                            </FalconCardHeader>
                        ) : (
                            <FalconCardHeader title={title || ''} light={false} className={headerClassName}>
                                {customAction && (
                                    <ButtonIcon onClick={handleCustomAction} iconClassName={iconClassName} icon={customActionIcon || "trash"} transform="shrink-3 down-2" color="falcon-default" size="sm">
                                        {customActionTitle}
                                    </ButtonIcon>
                                )}
                            </FalconCardHeader>
                        )
                    )}
                </>
            )}
                <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => {
                    const lastIndex = paginationProps.page * paginationProps.sizePerPage;
                    const customProps = {};
                    if(handleSelect) customProps.selectRow = handleSelect
                    return (
                        <Fragment>
                        {!loading ? (
                            <div className="table-responsive">
                                <BootstrapTable
                                    ref={table}
                                    bootstrap4
                                    keyField={keyField}
                                    data={data}
                                    columns={columns}
                                    bordered={false}
                                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                                    rowClasses={`btn-reveal-trigger border-top border-200 ${rowClasses}`}
                                    rowStyle={{textTransform: "uppercase"}}
                                    headerClasses="text-900 border-y border-200 main-table-head-tr"
                                    {...paginationTableProps}
                                    {...customProps}
                                />
                            </div>
                        ) : (
                            <Row className="py-4 d-flex justify-content-center">
                                <Spinner color="primary" />
                            </Row>

                        )}
                        {!noPag && 
                            <Row noGutters className="px-1 py-3 flex-center">
                                <Col xs="auto">
                                <Button
                                    color="falcon-default"
                                    size="sm"
                                    onClick={handlePrevPage(paginationProps)}
                                    disabled={paginationProps.page === 1}
                                >
                                    <FontAwesomeIcon icon="chevron-left" />
                                </Button>
                                {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
                                    <Button
                                    color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                                    size="sm"
                                    // disabled={!(paginationProps.page + 1 === pageNo)}
                                    className="ml-2"
                                    onClick={() => {
                                        if(customPagination){
                                            handlePagination(pageNo);
                                        }
                                        setOptions({
                                            ...options,
                                            page: pageNo
                                        })
                                        paginationProps.onPageChange(pageNo)
                                    }}
                                    key={pageNo}
                                    >
                                    {pageNo}
                                    </Button>
                                ))}
                                <Button
                                    color="falcon-default"
                                    size="sm"
                                    className="ml-2"
                                    onClick={handleNextPage(paginationProps)}
                                    disabled={lastIndex >= paginationProps.totalSize}
                                >
                                    <FontAwesomeIcon icon="chevron-right" />
                                </Button>
                                </Col>
                            </Row>
                        }
                        </Fragment>
                    );
                    }}
                </PaginationProvider>
        </Card>
        )}
        </>
    );
};

export default MainTable;
