import React from 'react';
import { Col } from 'reactstrap';
import DetailRow from './DetailRow';

const UbicationInfo = ({
    data
}) => {
    return (
      <>
        <Col lg className="col-xxl-5">
          <DetailRow title="País de Residencia">{data.infoPaisRes.split("|")[1]}</DetailRow>
          <DetailRow title="Ciudad/Municipio de Residencia">{data.infoMunicipioRes.split("|")[1]}</DetailRow>
          <DetailRow title="Correo Electrónico">{data.correo}</DetailRow>
          <DetailRow title="Teléfono Fijo">{data.telefono2}</DetailRow>
        </Col>
        <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
          <DetailRow title="Departamento de Residencia">{data.infoDepartamentoRes.split("|")[1]}</DetailRow>
          <DetailRow title="Dirección de Residencia">{data.direccionRes}</DetailRow>
          <DetailRow title="Teléfono Celular">{data.telefono1}</DetailRow>
          <DetailRow title="Teléfono Alterno">{data.telefono3}</DetailRow>
        </Col> 
      </>
    )
}

export default UbicationInfo;
