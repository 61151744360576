import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import { currencyFormat, percentageFormat } from '../../../helpers/tableFormatters';
import CountUp from 'react-countup';

const GeneralConditionsInfo = () => {
  const context = useContext(DesembolsoContext);
  const { loading, selected } = context;

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Condiciones Generales"></FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
        <Row className="text-right fs-3">
          <Col lg={12}>
            <DetailRow textNormal title="Monto Liquidado">{selected.monto ? currencyFormat(selected.monto) : <span className='p-2'></span>}</DetailRow>
            <DetailRow textNormal title="Tasa de Interés">
              {selected.tasa ? percentageFormat(selected.tasa) : <span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Plazo en Meses" childrenClasses="uppercase">
              {selected.plazo || <span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Fecha de Primer Corte" childrenClasses="uppercase">
              {selected.fechaCorte ? selected.fechaCorte :<span className='p-2'></span>}
            </DetailRow>
            <DetailRow textNormal title="Cuota Mensual" childrenClasses="text-primary fs-5">
              <CountUp end={selected.valorCuota || 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />
            </DetailRow>
          </Col>
        </Row>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default GeneralConditionsInfo;
