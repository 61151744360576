import React, { useState, useEffect, Fragment } from 'react';
import WizardInput from '../../auth/wizard/WizardInput';
import {
  Card,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Table,
  CustomInput,
  Form,
  Col,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import cogoToast from 'cogo-toast';
import WizardError from '../../auth/wizard/WizardError';
import Select from 'react-select';
import ButtonIcon from '../../common/ButtonIcon';
import { urlApi } from '../../../configuracion/serverConfig';
import ContactListComponent from '../../auth/TablaFormPropiedades';
const options = ['Cliente', 'Pagaduria', 'Vendedor', 'Proveedor'];
const Cuentas = [{label:'Ahorro', value: 'AH'}, {label:'Corriente', value: 'CO'}];

const FormFinancial = (props) => {
  const [initialData, setInitialData] = useState({
    Codigo: '',
    Procedimiento: ''
  });
  const [ListData, setListData] = useState([]);
  const [ListaBancos, setListaBancos] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialData);
  const [EstadoUsuario, setEstadoUsuario] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [DataGrilla, setDataGrilla] = useState([]);
  const [Banco, setBanco] = useState();
  const [TipoCuenta, setTipoCuenta] = useState();
  const [NumeroCuenta, setNumeroCuenta] = useState();


  useEffect(() => {
    ConsultarBancos();
    
    if(props.editing && props.selected){
      setDataGrilla(props.selected.terceroFinanciera)
    } else if(props.ArrayFinanciera) {
      setDataGrilla(props.ArrayFinanciera)
    }
  }, []);

  const onInputchange = (data) => {
    if (data) {
      let value = data.target.value;
      setNumeroCuenta(value);
    }
  };

  const ConsultarBancos = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    fetch(urlApi + '/banco/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.state = lstDatos[i].stado;

          items.push(element);
        }
        if (data.status === 0) {
          setListaBancos(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };


  const cambiarselectbanco = data2 => {
    let databanco = { value: data2.value, label: data2.label };
    setBanco(databanco);
  };

  const cambiarselecttipocuenta = data3 => {
    let datatipocuenta = { value: data3.value, label: data3.label };
    setTipoCuenta(datatipocuenta);
  };

  const quitarCampo = data => {
    const newData = DataGrilla.filter(d => d.NumeroCuenta !== data.NumeroCuenta ? true : d.IdBanco !== data.IdBanco )
    props.agregarcampo3(newData);
    setDataGrilla(newData);
  };

  const agregarCampo = () => {
    if(Banco){
    let ObjRegistro = {};
    let EstadoID = false;
    ObjRegistro.Banco = Banco.label;
    ObjRegistro.IdBanco = Banco.value;
    ObjRegistro.TipoCuenta = TipoCuenta.label;
    ObjRegistro.IdTipoCuenta = TipoCuenta.value;
    ObjRegistro.NumeroCuenta = NumeroCuenta;
    // ObjRegistro.EstadoID = propiedades
    if (DataGrilla.length > 0) {
      for (let i = 0; i < DataGrilla.length; i++) {
        if (DataGrilla[i].IdBanco == Banco.value && DataGrilla[i].IdTipoCuenta == TipoCuenta.value && DataGrilla[i].NumeroCuenta === NumeroCuenta ) {
          DataGrilla[i].Banco = Banco.label;
          DataGrilla[i].TipoCuenta = TipoCuenta.label;
          DataGrilla[i].NumeroCuenta = NumeroCuenta;
          EstadoID = true;
          console.log('ya se encuentra el registro');
        }
      }
    } else {
      setDataGrilla([ObjRegistro]);
      props.agregarcampo3([ObjRegistro])
    }
    if (EstadoID == false) {
      let ObjRegistro2 = {};
      ObjRegistro2.Banco = Banco.label;
      ObjRegistro2.IdBanco = Banco.value;
      ObjRegistro2.TipoCuenta = TipoCuenta.label;
      ObjRegistro2.IdTipoCuenta = TipoCuenta.value;
      ObjRegistro2.NumeroCuenta = NumeroCuenta

      const newData = [...DataGrilla]
      newData.push(ObjRegistro2)
      setDataGrilla(newData);
      props.agregarcampo3(newData)
    }
  }
  };

  return (
    <>
      <Fragment>
        <Row form>
          <Col>
            <label>Banco</label>
            <Select
              className="z7"
              style="fullWidth-select"
              name="Banco"
              placeholder="Seleccionar"
              onChange={cambiarselectbanco}
              options={ListaBancos}
            />
          </Col>
          <Col>
            <label>Tipo de Cuenta</label>
            <Select
              className="z7"
              placeholder="Seleccionar"
              style="fullWidth-select"
              name="TipoCuenta"
              onChange={cambiarselecttipocuenta}
              options={Cuentas}
            />
          </Col>
          <Col>
            <WizardInput
              type="text"
              label="Numero de Cuenta"
              onChange={onInputchange}
              name="NumeroCuenta"
              errors={props.errors}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonIcon className="mt-2" onClick={agregarCampo} icon="plus" transform="shrink-3 down-2" color="falcon-default" size="sm">
                Agregar
            </ButtonIcon>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
          <div style={{ border: "1px solid hsl(0,0%,80%)", borderRadius: '5px' }}>
              <div className="contact-table">
                <ContactListComponent
                  panel={props.panel}
                  searchInput={props.searchInput}
                  contactlists={DataGrilla}
                  headerLista={["","Banco","Tipo de cuenta", "Numero de cuenta"]}
                  bodyLista={["Banco", "TipoCuenta", "NumeroCuenta"]}
                  quitarCampo={data => quitarCampo(data)}
                  NombreMaestro={'Maestro Registro Glosas'}
                  Acciones={true}
                  Consultar={data => this.Consultar(data)}
                  selectValue={data => this.selectValue(data)}
                  handleSearch={data => this.handleSearch(data)}
                  deleteSelected={() => this.deleteSelected}
                  actiononContact={(action, data) => this.actiononContact(action, data)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Fragment>
    </>
  );
}

export default FormFinancial;
