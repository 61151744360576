import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  CustomInput,
  FormGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ConfirmModal({ open, onOk, onCancel, title, subtitle, text, body }) {

  return (
    <Modal size="lg" isOpen={open}>
      <ModalHeader>
        <h5>{title}</h5>
        <span className='text-primary' style={{ fontSize: '0.8em' }}>{subtitle}</span>
      </ModalHeader>
      <ModalBody className="p-3">
        <div className="text-center mt-4">
          {/* <FontAwesomeIcon icon="check" transform="shrink-2" className="text-success mr-1 fs-8" /> */}
          <h6 className="fs-2 m-3">
            {text}
          </h6>
          <p className="fs--1 mb-0">{body}</p>
        </div>
      </ModalBody>
      <ModalFooter className="no-border">
        <Col className="text-right" >
          <Button onClick={onCancel} className="mr-2" transform="shrink-3 down-2" >
            Cancelar
          </Button>
          <Button onClick={onOk} transform="shrink-3 down-2" color="primary" >
            Aceptar
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal

