import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const getProfiles = (filter) => {
    const sesionToken = authSesion();
    return fetch(`${urlApi}/perfil/nom/${sesionToken}/${filter || "*"}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const profilesServices = {
  getProfiles,
}