import React, { useState, useContext } from 'react';
import { Col, Card, CardBody, Row, FormGroup, Input, Label, CustomInput,  Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import DetailRow from '../../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import CountUp from 'react-countup';
// import WizardInput from '../../../../components/auth/wizard/WizardInput';
import FalconCardHeader from '../../../../components/common/FalconCardHeader';
import CurrencyInput from 'react-currency-input-field';
import CardSummary from '../../../../components/dashboard/CardSummary';

const CostosSection = ({ context: inputContext }) => {
    const { loading, dataCost, totalCost, handleChangeCost, onSave, onLiquidar: liquidar } = useContext(inputContext);
    const { register, triggerValidation, errors, setValue, getValues } = useForm({
      defaultValues: {
        ...dataCost
      }
    });

    const onChange = async e => {
      let { name, value } = e;
      value = value ? value.replaceAll('.', '').replaceAll(',', '.') : value
      setValue(name, value);
      const formCreditInfo = getValues();
      await triggerValidation();
      const isValid = Object.keys(errors).length < 1;
      handleChangeCost(formCreditInfo);
    }

    const onLiquidar = () => {
      if(!liquidar) return;
      liquidar();
    }

    return (
        <Card className={`my-3`} style={{backgroundColor: "#1b97ebd1", color: "white"}} >
          <FalconCardHeader title="Resumen Costas Procesales"></FalconCardHeader>
          <CardBody className="border-top">
          <form>
            <Row>
              <Col xl={12}>
                <FormGroup>
                <Label for="costLawyer">
                  <span className='mr-1 text-danger'>*</span>
                  Honorarios Abogado
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="costLawyer"
                  id="costLawyer"
                  defaultValue={dataCost ? dataCost.costLawyer : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "costLawyer", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("costLawyer", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="costCurador">
                  <span className='mr-1 text-danger'>*</span>
                  Honorarios Curador
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="costCurador"
                  id="costCurador"
                  defaultValue={dataCost ? dataCost.costCurador : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "costCurador", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("costCurador", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="emplazamiento">
                  <span className='mr-1 text-danger'>*</span>
                  Emplazamiento
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="emplazamiento"
                  id="emplazamiento"
                  defaultValue={dataCost ? dataCost.emplazamiento : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "emplazamiento", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("emplazamiento", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="citatory">
                  <span className='mr-1 text-danger'>*</span>
                  Citatorio
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="citatory"
                  id="citatory"
                  defaultValue={dataCost ? dataCost.citatory : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "citatory", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("citatory", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Notificación por Aviso
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="notificationAdvise"
                  id="notificationAdvise"
                  defaultValue={dataCost ? dataCost.notificationAdvise : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "notificationAdvise", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("notificationAdvise", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Viáticos
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="viaticos"
                  id="viaticos"
                  defaultValue={dataCost ? dataCost.viaticos : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "viaticos", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("viaticos", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Otros Gastos
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="costOther"
                  id="costOther"
                  defaultValue={dataCost ? dataCost.costOther : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "costOther", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("costOther", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <FormGroup>
                <Label for="creditNumber">
                  <span className='mr-1 text-danger'>*</span>
                  Intereses
                </Label>
                <CurrencyInput
                  className="form-control"
                  allowNegativeValue={false}
                  name="costIntereses"
                  id="costIntereses"
                  defaultValue={dataCost ? dataCost.costIntereses : 0}
                  prefix='$'
                  intlConfig={{ locale: 'es-CO', currency: 'COP' }}
                  onValueChange={(v) => onChange({ name: "costIntereses", value: v })}
                  decimalScale={2}
                  maxLength={20}
                  {...register("costIntereses", {
                    required: true,
                  })}
                  placeholder="$ 0.00" />
                </FormGroup>
              </Col>
              <Col xl={12}>
                <CardSummary noLink content={totalCost || "0"} title="Costas Procesales e Intereses" color="info" rate="">
                  {<CountUp end={totalCost ? totalCost : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} />}
                </CardSummary>
              </Col>
            </Row>
            </form>
            <Row className="mt-4">
              <Col lg={6} xs={12}>
                  <Button onClick={onSave} className="w-100" transform="shrink-3 down-2" color="falcon-default" >
                      Guardar
                  </Button>
              </Col>
              <Col lg={6} xs={12}>
                  <Button onClick={onLiquidar} className="w-100" transform="shrink-3 down-2" color="primary" >
                      Liquidar
                  </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
    )
};

export default CostosSection;
