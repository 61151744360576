import React, { Fragment } from 'react';
import WizardInput from './WizardInput';
import {
  Card,
  Input,
  Button,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Table,
  CustomInput,
  Form,
  Col,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUndo } from '@fortawesome/free-solid-svg-icons';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import ContactListComponent from '../TablaFormPropiedades';
const options = ['Cliente', 'Pagaduria', 'Vendedor', 'Proveedor'];

class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: '',
      ListaPropiedades: [],
      DataGrilla: []
    };
  }

  componentDidMount = () => {
    this.ConsultarPropiedades();
    if (this.props.ArrayPropiedades) {
      this.setState(state => ({
        ...state,
        DataGrilla: this.props.ArrayPropiedades
      }));
    }
  };

  cambiarselect = data => {
    let value = data.target.value;
    this.setState(state => ({
      ...state,
      SelectTipoDocumento: value
    }));
  };

  cambiarselectpropiedades = data2 => {
    let datapropiedades = { value: data2.value, label: data2.label };
    this.setState(state => ({
      ...state,
      propiedades: datapropiedades
    }));
  };

  ConsultarPropiedades = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/rol/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaPropiedades: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  quitarCampo = data => {
    for (let i = 0; i < this.state.DataGrilla.length; i++) {
      if (data.IdPropiedad === this.state.DataGrilla[i].IdPropiedad) {
        this.state.DataGrilla.splice(i, 1);
        break;
      }
    }
    this.setState(state => ({
      ...state,
      arrayList2: this.state.arrayList2
    }));
  };

  agregarCampo = () => {
    if (this.state.propiedades) {
      let ObjRegistro = {};
      let EstadoID = false;
      ObjRegistro.Propiedad = this.state.propiedades.label;
      ObjRegistro.IdPropiedad = this.state.propiedades.value;
      if (this.state.DataGrilla.length > 0) {
        for (let i = 0; i < this.state.DataGrilla.length; i++) {
          if (this.state.DataGrilla[i].IdPropiedad == this.state.propiedades.value) {
            this.state.DataGrilla[i].Propiedad = this.state.propiedades.label;
            EstadoID = true;
          }
        }
      } else {
        this.setState(state => ({
          ...state,
          DataGrilla: [ObjRegistro]
        }));
        this.props.agregarcampo(this.state.DataGrilla);
        // this.limpiar();
      }
      if (EstadoID == false) {
        let ObjRegistro2 = {};
        ObjRegistro2.Propiedad = this.state.propiedades.label;
        ObjRegistro2.IdPropiedad = this.state.propiedades.value;

        this.state.DataGrilla.push(ObjRegistro2);
        this.setState(state => ({
          ...state,
          DataGrilla: this.state.DataGrilla
        }));
        this.props.agregarcampo(this.state.DataGrilla);

        // this.limpiar();

        // this.limpiar();
      }
    }
  };

  render() {
    return (
      <>
        <Fragment>
          <Row form>
            <Col>
              <label>Propiedad de la Persona*</label>
              <Select
                className="superponer2"
                id="propiedades"
                style="fullWidth-select"
                name="propiedades"
                {...this.props.register("propiedades")}
                onChange={this.cambiarselectpropiedades}
                value={this.state.propiedades}
                options={this.state.ListaPropiedades}
              />
            </Col>

            <Col>
              <Label>Propiedades</Label>
              <div style={{ border: 'outset', borderRadius: '10px' }}>
                <div className="contact-table">
                  <ContactListComponent
                    panel={this.props.panel}
                    searchInput={this.props.searchInput}
                    contactlists={this.state.DataGrilla}
                    headerLista={['', 'Propiedad', '']}
                    bodyLista={['Propiedad', '']}
                    quitarCampo={data => this.quitarCampo(data)}
                    NombreMaestro={'Maestro Registro Glosas'}
                    Acciones={true}
                    Consultar={data => this.Consultar(data)}
                    selectValue={data => this.selectValue(data)}
                    handleSearch={data => this.handleSearch(data)}
                    deleteSelected={() => this.deleteSelected}
                    actiononContact={(action, data) => this.actiononContact(action, data)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col />
          </Row>
        </Fragment>
        <div>
          <Button onClick={this.agregarCampo}>
            <i className="mr-1">
              {' '}
              <FontAwesomeIcon icon={faPlus} />
            </i>
            Agregar
          </Button>
        </div>
      </>
    );
  }
}

export default BasicUserForm;
