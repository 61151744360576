import React, { Fragment } from 'react';
import WizardInput from './WizardInput';
import { Col, CustomInput, Row } from 'reactstrap';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
import Select from 'react-select';
import './App.css';
const options = ['CC', 'NIT'];

class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: '',
      ListaPaises: [],
      Listadpto: [],
      ListaCiudad: [],
    };
  }
  componentDidMount() {
    this.ConsultarPaises();
    if (this.props.PaisResidencia) {
      this.setState(state => ({
        ...state,
        paisresidencia: this.props.PaisResidencia.value
      }));
    }
    if (this.props.DepartamentoResidencia) {
      this.ConsultarDept(this.props.PaisResidencia)
      this.setState(state => ({
        ...state,
        departamentoresidencia: Number(this.props.DepartamentoResidencia.value)
      }));
    }
    if (this.props.CiudadResidencia) {
      this.Consultarciudad(this.props.DepartamentoResidencia)
      this.setState(state => ({
        ...state,
        ciudadmunicipioresidencia: Number(this.props.CiudadResidencia.value)
      }));
    }
  }

  cambiarselect = data => {
    let value = data.target.value;
    this.setState(state => ({
      ...state,
      SelectTipoDocumento: value
    }));
  };

  ConsultarPaises = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/pais/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].pais + ' - ' + lstDatos[i].nombre;
          element.identificador = lstDatos[i].pais;
          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaPaises: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };


  cambiarselectpais = data2 => {
    if (this.state.paisresidencia) {
      var variable = data2.target.value;
    }

    if (variable != data2.target.value) {
      this.state.departamentoresidencia = "";
        this.state.ciudadmunicipioresidencia = "";
    }
    
    var paises = this.state.ListaPaises;
    let objectPaises = {};
    for (let i = 0; i < paises.length; i++) {
      if (Number(data2.target.value) === paises[i].value) {
        objectPaises = paises[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      paisresidencia: objectPaises.value
    }));
    this.ConsultarDept(objectPaises);
    this.props.cambiarselectpais3(objectPaises);
  };


  cambiarselectciudad = data4 => {

    var ciudades = this.state.ListaCiudad;
    let objectCiudades = {};
    for (let i = 0; i < ciudades.length; i++) {
      if (Number(data4.target.value) === Number(ciudades[i].value)) {
        objectCiudades = ciudades[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      ciudadmunicipioresidencia: objectCiudades.value
    }));
    this.props.cambiarselectciudad3(objectCiudades)

  }

   cambiarselectdepartamento = data3 => {


    var departamentos = this.state.Listadpto;
    let objectDepartamentos = {};
    for (let i = 0; i < departamentos.length; i++) {
      if (Number(data3.target.value) === Number(departamentos[i].value)) {
        objectDepartamentos = departamentos[i]; 
      }    
    }
    this.setState(state => ({
      ...state,
      departamentoresidencia: objectDepartamentos.value
    }));
    this.Consultarciudad(objectDepartamentos);
    this.props.cambiarselectdepartamento3(objectDepartamentos)



    
    
  
  }

  ConsultarDept = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/dpto/pais/' + sesion + '/' + data.identificador, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            Listadpto: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  Consultarciudad = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/municipio/dpto/' + sesion + '/' + data.identificador + '/1/100/', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].estado;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          this.setState(state => ({
            ...state,
            ListaCiudad: items
          }));
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };
  render() {
    return (
      <>
        <Fragment>
          <Row form>
          <Col>
          <label>Pais de Residencia*</label>
                  <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="z7"
                    id="paisresidencia"
                    placeholder="Seleccionar Pais"
                    name="paisresidencia"
                    onChange={this.cambiarselectpais}
                    value={this.state.paisresidencia}
                    options={this.state.ListaPaises}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                </Col>
            <Col>
            <label>Departamento de Residencia*</label>
            <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="z7"
                    id="departamentoresidencia"
                    placeholder="Seleccionar Departamento"
                    name="departamentoresidencia"
                    onChange={this.cambiarselectdepartamento}
                    value={this.state.departamentoresidencia}
                    options={this.state.Listadpto}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                  
              
            </Col>
          </Row>
          <Row form>
            <Col>
            <label>Ciudad / Municipio de Residencia*</label>
            <WizardInput
                  type="select"
                  tag={CustomInput}
                    className="superponer2"
                    id="ciudadmunicipioresidencia"
                    placeholder="Seleccionar Departamento"
                    name="ciudadmunicipioresidencia"
                    onChange={this.cambiarselectciudad}
                    value={this.state.ciudadmunicipioresidencia}
                    options={this.state.ListaCiudad}
                    innerRef={this.props.register({
                      required: true
                    })}
                    errors={this.props.errors}
                  />
                
            
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Dirección de Residencia*"
                value={this.state.DireccionResidencia}
                // placeholder="Name"
                name="DireccionResidencia"
                id="DireccionResidencia"
                innerRef={this.props.register({
                  required: 'Direccion de residencia es requerido',

                  // validate: value => value === watch('password') || 'The password do not match'
                })}
                errors={this.props.errors}
              
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="email"
                label="Correo Electrónico*"
                value={this.state.CorreoElectronico}
                // placeholder="Name"
                name="CorreoElectronico"
                id="CorreoElectronico"
                innerRef={this.props.register({
                  required: 'Correo requerido',

                  // validate: value => value === watch('password') || 'The password do not match'
                })}
                errors={this.props.errors}
              />
            </Col>
            <Col>
              <WizardInput
                type="number"
                label="Telefono Celular*"
                value={this.state.TelefonoCelular}
                // placeholder="Name"
                name="TelefonoCelular"
                id="TelefonoCelular"
                innerRef={this.props.register({
                  required: 'Telefono celular requerido',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                errors={this.props.errors}
              
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="text"
                label="Teléfono Fijo*"
                value={this.state.TelefonoFijo}
                // placeholder="Name"
                name="TelefonoFijo"
                id="TelefonoFijo"
                innerRef={this.props.register({
                  required: 'Telefono es requerido',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Télefono Alterno*"
                value={this.state.TelefonoAlterno}
                // placeholder="Name"
                name="TelefonoAlterno"
                id="TelefonoAlterno"
                innerRef={this.props.register({
                  required: 'Telefono alterno es requerido',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                errors={this.props.errors}
              
              />
            </Col>
          </Row>
        
        
        </Fragment>
      </>
    );
  }
}

export default BasicUserForm;
