import React from 'react';
import MainTable from '../../tables/MainTable';

const FinancialInfo = ({
    data
}) => {

    const columns = [
      {
          dataField: 'infoBanco',
          text: 'Banco',
          headerClasses: 'border-0',
          classes: 'border-0 py-2 align-middle',
      },
      {
          dataField: 'tipoCuenta',
          headerClasses: 'border-0',
          text: 'Tipo de Cuenta',
          classes: 'border-0 py-2 align-middle',
      },
      {
          dataField: 'numeroCuenta',
          headerClasses: 'border-0',
          text: 'Número de Cuenta',
          classes: 'border-0 py-2 align-middle',
      }
    ]

    return (
      <MainTable 
        data={data}
        columns={columns}
        keyField="numeroCuenta"
        noSearch
        noAdd
      />
    )
}

export default FinancialInfo;
