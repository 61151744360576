import { StyleSheet, Font } from '@react-pdf/renderer';
import regular from '../../../assets/fonts/OpenSans-Regular.ttf'
import bold from '../../../assets/fonts/OpenSans-Bold.ttf'
Font.register({ family: 'Poppins', fonts: [
  { src: regular },
  { src: bold }
]});

const styles = StyleSheet.create({
  body: {
    fontFamily: 'Poppins',
    paddingTop: 15,
    paddingBottom: 15,
    paddingHorizontal: 60,
  },
  strong: {
    fontWeight: 'bold'
  },
  header: {
    display: 'flex',
    padding: '1pt 0',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '65pt'
  },
  headerCol: {
    height: '100%'
  },
  logoContainer: {
    display: 'flex',
    height: '60pt',
    width: '30%',
    justifyContent: 'center',
  },
  logo: {
    width: '180px'
  },
  companyInfo: {
    height: '100%',
    margin: '0 1%',
    width: '30%',
    fontSize: '10pt',
    textAlign: 'right',
  },
  serialWrap: {
    fontSize: '14px',
    borderWidth: '2pt',
    borderStyle: 'solid',
    borderColor: 'black',
    borderRadius: '5pt',
    textAlign: 'center'
  },
  serialContainer: {
    width: '38%'
  },
  serialHeader: {
    backgroundColor: 'black',
    color: 'white',
    padding: '2pt 10pt',
    fontWeight: 'bold'
  },
  serialBody: {
    padding: '8pt 10pt',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  infoTable: {
    width: '100%',
    fontSize: '13px',
    marginTop: '20pt',
    borderWidth: '1pt',
    borderStyle: 'solid',
    borderColor: 'black',
    borderRadius: '5pt'
  },
  infoRow: {
    height: '25pt',
    width: '100%',
    borderBottomWidth: '1pt',
    borderStyle: 'solid',
    borderColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: '10px'
  },
  infoRowSum: {
    height: '65pt',
    width: '100%',
    paddingTop: '5px',
    paddingLeft: '10px',
  },
  infoRowCol: {
    width: '36%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  infoRowColStrech: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '28%',
    height: '100%',
    marginRight: '4px',
  },
  infoLabelStrech: {
    backgroundColor: 'black',
    height: '100%',
    color: 'white',
    width: '25%',
    paddingTop: '3px',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  dateContent: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: '1',
    height: '100%',
  },
  dateColumn: {
    flexGrow: '1',
    borderRight: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dateMark: {
    fontWeight: 'bold',
    position: 'absolute',
    left: '2',
    top: '2',
    fontSize: '8px'
  },
  infoLabel: {
    marginRight: '10px',
    fontWeight: 'bold'
  },
  infoLabelSum: {
    marginBottom: '4px',
    fontWeight: 'bold'
  },
  detailTable: {
    width: '100%',
    fontSize: '13px',
    marginTop: '5px',
    borderWidth: '1pt',
    borderStyle: 'solid',
    borderColor: 'black',
    borderRadius: '5pt',
    height: '230px'
  },
  detailRow: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap'
  },
  detailCol: {
    height: '100%',
  },
  detailColLeft: {
    width: '60%',
  },
  detailColRight: {
    width: '40%',
  },
  detailRowCount: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '60%',
  },
  countTable: {
    width: '70%',
    height: '100%',
    borderRight: '1px solid black'
  },
  countHeader: {
    height: '18px',
    width: '100%',
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  checks: {
    width: '30%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid black'
  },
  checkRow: {
    width: '80%',
    height: '20px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '10px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  check: {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    border: '1px solid black',
    borderRadius: '2px',
  },
  detailRowCountBottom: {
    width: '100%',
    height: '40%',
    borderTop: '1px solid black',
    display: 'flex',
    flexDirection: 'row'
  },
  detailBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: '1',
    borderRight: '1px solid black',
    padding: '0 4'
  },
  checked: {
    backgroundColor: '#3cd42e',
    
  },
  sign: {
    height: '70%',
    textAlign: 'center',
    paddingTop: '5px'
  },
  signIdContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '16%',
    alignItems: 'center',
    borderTop: '1px solid black'
  },
  signBox: {
    marginLeft: '5px',
    display: 'flex',
    flexDirection: 'row'
  },
  signCheck: {
    width: '20px',
    height: '16px',
    borderRadius: '2px',
    marginLeft: '5px',
    border: '1px solid black'
  },
  receiverInfo: {
    height: '20%',
    borderTop: '1px solid black'
  },
  receiverLabel: {
    width: '45%',
    height: '100%',
    backgroundColor: 'black',
    color: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textContent: {
    fontSize: '12px',
    textTransform: 'uppercase'
  },
  detailRowObs: {
    height: '79px',
    border: '1px solid black',
    borderRadius: '5px',
    marginTop: '5px',
    fontSize: '12px',
    padding: '5px',
  },
  noBorder: {
    border: 'none'
  },
  serial: {
    fontWeight: 'bold',
    color: 'red'
  },
  monto: {
    width: '100%',
    paddingRight: '5%',
    fontSize: '14px',
    textAlign: 'right'
  },
  countRow: {
	  width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  countCell: {
    width: '33.33%',
    borderRight: '1px solid black',
    borderBottom: '1px solid black',
    height: '17pt',
    color: 'black',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '10px'
  },

});

export default styles;