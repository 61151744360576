import React, { useState, useEffect, Fragment } from 'react';
import WizardInput from '../../auth/wizard/WizardInput';
import { Col, CustomInput, Row, Button } from 'reactstrap';
import { urlApi } from '../../../configuracion/serverConfig';
import cogoToast from 'cogo-toast';
const options2 = ['CC', 'NIT'];
const options = [
  {
    label: 'Cédula de Ciudadania',
    value: 'CC'
  },
  { label: 'Cédula de Extranjería', value: 'CE' },
  { label: 'Pasaporte', value: 'PS' },
  { label: 'NIT', value: 'NIT' }
];

const FormIdentificaction = (props) => {

  const [initialData, setInitialData] = useState({
    Codigo: '',
    Procedimiento: ''
  });
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialData);
  const [EstadoUsuario, setEstadoUsuario] = useState(false);
  const [identificacion, setIdentificacion] = useState('');
  const [ListaPaises, setListaPaises] = useState([]);
  const [Listadpto, setListadpto] = useState([]);
  const [Listadpto2, setListadpto2] = useState([]);
  const [ListaCiudad, setListaCiudad] = useState([]);
  const [ListaCiudad2, setListaCiudad2] = useState([]);
  const [SelectTipoDocumento, setSelectTipoDocumento] = useState([{ label: '', value: '' }]);
  const [paisexpedicion, setPaisexpedicion] = useState('');
  const [departamentoexpedicion, setDepartamentoexpedicion] = useState('');
  const [departamentonacimiento, setDepartamentonacimiento] = useState('');
  const [ciudadmunicipioexpedicion, setCiudadmunicipioexpedicion] = useState('');
  const [ciudadmunicipionacimiento, setCiudadmunicipionacimiento] = useState('');
  const [paisnacimiento, setPaisnacimiento] = useState('');

  useEffect(() => {
    ConsultarPaises();
    if (props.PaisExpedicion) {
      setPaisexpedicion(props.PaisExpedicion);
    }
    if (props.DepartamentoExpedicion) {
      ConsultarDept({id: props.PaisExpedicion})
      setDepartamentoexpedicion(Number(props.DepartamentoExpedicion.value));
    }
    if (props.CiudadExpedicion) {
      Consultarciudad(props.DepartamentoExpedicion)
      setCiudadmunicipioexpedicion(Number(props.CiudadExpedicion.value));
    }
    if (props.PaisNacimiento) {
      setPaisnacimiento(props.PaisNacimiento.value);
    }
    if (props.DepartamentoNacimiento) {
      ConsultarDept2({id: props.PaisNacimiento})
      setDepartamentonacimiento(Number(props.DepartamentoNacimiento.value));
    }
    if (props.CiudadNacimiento) {
      Consultarciudad2(props.DepartamentoNacimiento)
      setCiudadmunicipionacimiento(Number(props.CiudadNacimiento.value));
    }
    if(props.Identificacion) {
      setSelectTipoDocumento(props.Identificacion);
    }
  }, []);

  useEffect(() => {
    if(!props.PaisExpedicion || !props.editing) return;
    ConsultarDept({id: props.PaisExpedicion})
    Consultarciudad({identificador: props.extraInfo.terceroInformacion.infoDepartamentoExp.split('|')[0]})
  }, [props.PaisExpedicion])

  useEffect(() => {
    if(!props.PaisNacimiento || !props.editing) return;
    ConsultarDept2({id: props.PaisNacimiento})
    Consultarciudad2({identificador: props.extraInfo.terceroInformacion.infoDepartamentoNac.split('|')[0]})
  }, [props.PaisNacimiento])

  const cambiarselect = data9 => {
    let datac = data9;
    setSelectTipoDocumento(datac);
    props.cambiarselect(datac);
  };

  const cambiarselectpais = data2 => {
    if (paisexpedicion) {
      var variable = data2;
    }

    if (variable != data2) {
      setDepartamentoexpedicion('');
      setCiudadmunicipioexpedicion('');
    }
    
    var paises = props.countries;
    let objectPaises = {};
    for (let i = 0; i < paises.length; i++) {
      if (Number(data2) === paises[i].value) {
        objectPaises = paises[i]; 
      }    
    }
    setPaisexpedicion(objectPaises.value);
    ConsultarDept(objectPaises);
    props.cambiarselectpais(objectPaises.value);
  };

  const cambiarselectpais2 = data5 => {
    if (paisnacimiento) {
      var variable2 = data5;
    }

    if (variable2 != data5) {
      setDepartamentonacimiento('');
      setCiudadmunicipionacimiento('');
    }
    
    var paises2 = props.countries;
    let objectPaises2 = {};
    for (let i = 0; i < paises2.length; i++) {
      if (Number(data5) === paises2[i].value) {
        objectPaises2 = paises2[i]; 
      }    
    }
    setPaisnacimiento(objectPaises2.value);
    ConsultarDept2(objectPaises2);
    props.cambiarselectpais2(objectPaises2.value);
  }

  const cambiarselectciudad2 = data6 => {
    var ciudades2 = ListaCiudad2;
    let objectCiudades2 = {};
    for (let i = 0; i < ciudades2.length; i++) {
      if (Number(data6) === Number(ciudades2[i].value)) {
        objectCiudades2 = ciudades2[i]; 
      }    
    }
    setCiudadmunicipionacimiento(objectCiudades2.value);
    props.cambiarselectciudad2(objectCiudades2.value)
  };

  const cambiarselectciudad = data4 => {

    var ciudades = ListaCiudad;
    let objectCiudades = {};
    for (let i = 0; i < ciudades.length; i++) {
      if (Number(data4) === Number(ciudades[i].value)) {
        objectCiudades = ciudades[i]; 
      }    
    }
    setCiudadmunicipioexpedicion(objectCiudades.value);
    props.cambiarselectciudades(objectCiudades.value)
  };

  const cambiarselectdepartamento = data3 => {
    var departamentos = Listadpto;
    let objectDepartamentos = {};
    for (let i = 0; i < departamentos.length; i++) {
      if (Number(data3) === Number(departamentos[i].value)) {
        objectDepartamentos = departamentos[i]; 
      }    
    }
    setDepartamentoexpedicion(objectDepartamentos.value);
    Consultarciudad(objectDepartamentos);
    props.cambiarselectdepartamento(objectDepartamentos)
  };

  const cambiarselectdepartamento2 = data7 => {
    var departamentos2 = Listadpto2;
    let objectDepartamentos2 = {};
    for (let i = 0; i < departamentos2.length; i++) {
      if (Number(data7) === Number(departamentos2[i].value)) {
        objectDepartamentos2 = departamentos2[i]; 
      }    
    }
    setDepartamentonacimiento(objectDepartamentos2.value);
    Consultarciudad2(objectDepartamentos2);
    props.cambiarselectdepartamento2(objectDepartamentos2)
    
  };

  const ConsultarPaises = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/pais/nom/' + sesion + '/*', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].pais + ' - ' + lstDatos[i].nombre;
          element.identificador = lstDatos[i].pais;
          items.push(element);
        }
        if (data.status === 0) {
          setListaPaises(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const ConsultarDept = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    if(!data.identificador){
      data.identificador = props.countries.filter(p => p.value === data.id)[0].identificador
    }

    fetch(urlApi + '/dpto/pais/' + sesion + '/' + data.identificador, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          setListadpto(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const ConsultarDept2 = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;
    
    if(!data.identificador){
      data.identificador = props.countries.filter(p => p.value === data.id)[0].identificador
    }

    fetch(urlApi + '/dpto/pais/' + sesion + '/' + data.identificador, {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].nombre;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          setListadpto2(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  }

  const Consultarciudad = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/municipio/dpto/' + sesion + '/' + data.identificador + '/1/100/', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].estado;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          setListaCiudad(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const Consultarciudad2 = data => {
    var objsesion = JSON.parse(sessionStorage.getItem('Usuario'));
    let sesion = objsesion.sesionId;

    fetch(urlApi + '/municipio/dpto/' + sesion + '/' + data.identificador + '/1/100/', {
      method: 'GET',
      body: JSON.stringify(),
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Accept: 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => data)
      .then(data => {
        let items = [];
        let lstDatos = data.objResponse;
        for (let i = 0; i < lstDatos.length; i++) {
          let element = {};
          element.value = lstDatos[i].id;
          element.label = lstDatos[i].estado;
          element.identificador = lstDatos[i].identificacion;

          items.push(element);
        }
        if (data.status === 0) {
          setListaCiudad2(items);
        } else {
          cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
        }
      })
      .catch(err => console.log('err', err));
  };

  const MostrarFormulario = () => {
    EstadoForm = false;
  };

  return (
    <>
      <Fragment>
        <Row form>
          <Col>
            <label>Tipo de Identificacion*</label>
            <WizardInput
                  type="select"
                  tag={CustomInput}
                  placeholder="Seleccionar"
                  id="SelectTipoDocumento"
                  name="SelectTipoDocumento"
                  value={props.Identificacion}
                  errors={props.errors}
                  options={options}
                  onChange={(input) => cambiarselect(input.target.value)}
                  innerRef={props.register({
                    required: 'requerido',
                  })}
                />
          </Col>

          <Col>
            <WizardInput
              label="Numero de Identificacion*"
              placeholder=""
              id="NumeroIdentificacion"
              name="NumeroIdentiicacion"
              innerRef={props.register({
                required: 'requerido',
                minLength: {
                  value: 2,
                  message: 'Proporcione una identificacion valida'
                }
              })}
              errors={props.errors}
            />
          </Col>
        </Row>
        {props.Identificacion === 'NIT' ? (
          <div>
            <Row form>
              <Col>
                <WizardInput
                  type="text"
                  label="Razon Social*"
                  // placeholder="Password"
                  id="RazonSocial"
                  name="RazonSocial"
                  innerRef={props.register({
                    required: 'requerido',
                    
                  })}
                  errors={props.errors}
                />
              </Col>
              <Col>
                <WizardInput
                  type="text"
                  label="Nombre Comercial"
                  id="NombreComercial"
                  name="NombreComercial"
                  innerRef={props.register({
                    required: false,
                  })}
                  errors={props.errors}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row form>
              <Col>
                <WizardInput
                  type="text"
                  label="Primer Nombre*"
                  id="PrimerNombre"
                  name="PrimerNombre"
                  innerRef={props.register({
                    required: 'requerido',
                  })}
                  errors={props.errors}
                />
              </Col>
              <Col>
                <WizardInput
                  type="text"
                  label="Segundo Nombre"
                  id="SegundoNombre"
                  name="SegundoNombre"
                  innerRef={props.register({
                    required: false,
                  })}
                  errors={props.errors}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <WizardInput
                  type="text"
                  label="Primer Apellido*"
                  id="PrimerApellido"
                  name="PrimerApellido"
                  innerRef={props.register({
                    required: 'requerido'
                  })}
                  errors={props.errors}
                />
              </Col>
              <Col>
                <WizardInput
                  type="text"
                  label="Segundo Apellido*"
                  id="SegundoApellido"
                  name="SegundoApellido"
                  innerRef={props.register({
                    required: 'requerido',
                  })}
                  errors={props.errors}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <WizardInput
                  type="Date"
                  label="Fecha Expedición"
                  id="FechaExpedicion"
                  name="FechaExpedicion"
                  innerRef={props.register({
                    required: 'requerido',
                    validate: value => {
                      const selectedDate = new Date(value)
                      const maxDate = new Date()
                      const minDate = new Date(new Date().setMonth(new Date().getMonth() - 1800))
                      return selectedDate <= maxDate && selectedDate >= minDate
                    }
                  })}
                  max={new Date().toISOString().split('T')[0]}
                  min={new Date(new Date().setMonth(new Date().getMonth() - 1800)).toISOString().split('T')[0]}
                  errors={props.errors}
                />
              </Col>
              <Col>
                <label>Pais Expedición</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="z7"
                  id="paisexpedicion"
                  placeholder="Seleccionar"
                  name="paisexpedicion"
                  onChange={(input) => cambiarselectpais(input.target.value)}
                  value={props.PaisExpedicion}
                  options={props.countries}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <label>Departamento de Expedición*</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="z7"
                  id="departamentoexpedicion"
                  placeholder="Seleccionar"
                  name="departamentoexpedicion"
                  onChange={(input) => cambiarselectdepartamento(input.target.value)}
                  value={props.DepartamentoExpedicion?.value}
                  options={Listadpto}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              </Col>
              <Col>
                <label>Ciudad / Municipio de Expedición*</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="z6"
                  id="ciudadmunicipioexpedicion"
                  placeholder="Seleccionar"
                  name="ciudadmunicipioexpedicion"
                  onChange={(input) => cambiarselectciudad(input.target.value)}
                  value={props.CiudadExpedicion}
                  options={ListaCiudad}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
                
              </Col>
            </Row>
            <Row form>
              <Col>
                <WizardInput
                  type="Date"
                  label="Fecha de Nacimiento*"
                  id="FechaNacimiento"
                  name="FechaNacimiento"
                  innerRef={props.register({
                    required: 'requerido',
                    validate: value => {
                      const selectedDate = new Date(value)
                      let maxDate = new Date()
                      maxDate.setMonth(maxDate.getMonth() - 216)
                      const minDate = new Date(new Date().setMonth(new Date().getMonth() - 1800))
                      return selectedDate <= maxDate && selectedDate >= minDate;
                    }
                  })}
                  max={new Date(new Date().setMonth(new Date().getMonth() - 216)).toISOString().split('T')[0]}
                  min={new Date(new Date().setMonth(new Date().getMonth() - 1800)).toISOString().split('T')[0]}
                  errors={props.errors}
                />
              </Col>
              <Col>
              <label>Pais de Nacimiento</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="z5"
                  id="paisnacimiento"
                  placeholder="Seleccionar"
                  name="paisnacimiento"
                  onChange={(input) => cambiarselectpais2(input.target.value)}
                  value={props.PaisNacimiento}
                  options={props.countries}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              </Col>
              
            </Row>
            <Row form>
              <Col>
                <label>Departamento de Nacimiento*</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="superponer2"
                  id="departamentonacimiento"
                  placeholder="Seleccionar"
                  name="departamentonacimiento"
                  onChange={(input) => cambiarselectdepartamento2(input.target.value)}
                  value={props.DepartamentoNacimiento?.value}
                  options={Listadpto2}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              </Col>
              <Col>
                <label>Ciudad / Municipio Nacimiento*</label>
                <WizardInput
                type="select"
                tag={CustomInput}
                  className="superponer2"
                  id="ciudadmunicipionacimiento"
                  placeholder="Seleccionar"
                  name="ciudadmunicipionacimiento"
                  onChange={(input) => cambiarselectciudad2(input.target.value)}
                  value={props.CiudadNacimiento}
                  options={ListaCiudad2}
                  innerRef={props.register({
                    required: "requerido"
                  })}
                  errors={props.errors}
                />
              
              </Col>
            </Row>
            <br />
          </div>
        )}
        {props.editing && (
          <WizardInput
            type="checkbox"
            id="EstadoPerson"
            checked={props.Estado === "1"}
            tag={CustomInput}
            label={<Fragment>Activo</Fragment>}
            innerRef={props.register({
              required: false
            })}
            name="EstadoPerson"
            onChange={(input) => {
              props.changePersonState(input.target.checked ? "1" : "0")
            }}
            errors={props.errors}
          />
        )}          
      </Fragment>
    </>
  );
}

export default FormIdentificaction;
