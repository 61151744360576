import { urlApi } from '../configuracion/serverConfig';
import { authSesion, headers } from "../helpers/authSesion";

const calculateFee = data => {
    const sesionToken = authSesion();
    const { type, amount, rate, term, bonus } = data;
    return fetch(`${urlApi}/cuota/comp/${sesionToken}/${type}/${amount}/${rate}/${term}/${bonus}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

const getPaymentsPlan = (feeData, fee, date) => {
    const sesionToken = authSesion();
    const { type, amount, rate, term } = feeData;
    return fetch(`${urlApi}/proyeccion/${sesionToken}/${fee}/${rate}/${term}/${date}/${amount}/${type}`, {
        method: 'GET',
        headers,
    })  
    .then(res => res.json())
    .then(data => data)
    .then(data => {
        return data.status === 0 ? data : {error: true, msg: data.objError.msgError}
    })
    .catch(err => {
        console.log("Error", err);
        return {error: true, msg: "ERROR"}
    });
}

export const simulatorServices = {
  calculateFee,
  getPaymentsPlan
}