import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import Spinner from 'reactstrap/lib/Spinner';
import cogoToast from 'cogo-toast';
import { StateContext } from '../state/StateContext';
import ContentWithAsideLayout from '../../layouts/ContentWithAsideLayout';
import PageHeader from '../../components/common/PageHeader';
import GeneralConditions from './children/GeneralConditions';
import CreditInfo from './children/CreditInfo';
import ClientInfo from './children/ClientInfo';
import ObservationsSection from './children/ObservationsSection'
import { solicitudServices, advisersServices } from '../../services';
import { getTodayStr } from '../../helpers/dateFormatters';
import { getItemFromStore } from '../../helpers/utils';

export const FORM_DATA_CATEGORY = {
  GENERAL: "GENERAL",
  DATELINE: "DATELINE",
  EXTRA_DATA: "EXTRA_DATA",
  OBSERVATIONS: "OBSERVATIONS"
}

const RefinancePage = () => {
  const context = useContext(StateContext);
  const { loading, creditSelected: selected, client, updateLoading, handleSuccessRefinance } = context;

  const [refinanceFormData, setRefinanceFormData] = useState();
  const [dateLine, setDateLine] = useState();
  const [extraFormData, setExtraFormData] = useState();
  const [observations, setObservations] = useState();

  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
  }, selected)

  useEffect(() => {
    getCurrentUser();
  }, [])

  const getCurrentUser = async () => {
    updateLoading(true);
    const sesion = await getItemFromStore('Usuario', '', sessionStorage);
    const { identificacion } = sesion;

    const response = await advisersServices.getAdviserById(identificacion);
    if(!response || response.error){
      updateLoading(false);
      return;
    }

    setCurrentUser(response.objResponse[0]);
    updateLoading(false);
  }

  

  const handleFormData = (formData, category) => {
    switch (category) {
      case FORM_DATA_CATEGORY.GENERAL:
        setRefinanceFormData(formData)
        break;
      case FORM_DATA_CATEGORY.DATELINE:
        setDateLine(formData)
        break;
      case FORM_DATA_CATEGORY.EXTRA_DATA:
        setExtraFormData(formData)
        break
      case FORM_DATA_CATEGORY.OBSERVATIONS:
        setObservations(formData)
        break
      default:
        break;
    }
  }

  const onRefinance = async () => {
    if(!refinanceFormData || !dateLine || !extraFormData){
      cogoToast.warn("Por favor completa lso campos requeridos", 
      { position: "bottom-left", heading: "Campos Requeridos" });
      return;
    }

    if(!currentUser){
      cogoToast.warn("Por favor verifica que cuentas con los permisos para realizar esta operación", 
      { position: "bottom-left", heading: "Sesión Invalida" });
      return;
    }
    updateLoading(true);

    const today = getTodayStr();

    const payload = {
      credito: extraFormData.creditNumber,
      fecha: today,
      idPagaduria: selected.idPagaduria,
      idLinea: selected.idLinea,
      idSubLinea: selected.idSubLinea,
      idCliente: client.id,
      idAsesor: currentUser.idAsesor,
      comision: 0,
      monto: selected.saldoProyectado,
      plazo: refinanceFormData.term,
      tasa: refinanceFormData.rate,
      tipoFormula: refinanceFormData.type,
      tasaPrima: refinanceFormData.bonus,
      fechaCorte: dateLine,
      valorCuota: refinanceFormData.cuota,
      estado: 1,
      idModalidad: 4,
      prestamos: [
          {
              prestamo: selected.prestamo,
              tipo: 3
          }
      ],
      refSolicitud: {
        identificacion: "",
        nombre: "",
        domicilio: "",
        telefono: "",
        correo: "",
      }
    }

    const response = await solicitudServices.createSolicitud(payload);
    if(!response || response.error){
      updateLoading(false);
      cogoToast.error(response.msg, 
      { position: 'bottom-left', heading: 'Error en solicitud' });
      return;
    };

    
    resetData();
    updateLoading(false);
    cogoToast.success("Su solicitud ha sido creada con éxito", 
      { position: 'bottom-left', heading: 'Refinanciación' });
    handleSuccessRefinance();
  }

  const resetData = () => {
    setRefinanceFormData();
    setExtraFormData();
    setDateLine()
  }
  

  return (
    <Fragment> 
      {loading ? (
        <div className='container-fluid text-center p-4'>
          <Spinner color="primary" />
        </div>
      ) : (
        <ContentWithAsideLayout aside={<GeneralConditions handleForm={handleFormData} />} isStickyAside={false}>
          <CreditInfo />
          <ClientInfo />
          <ObservationsSection handleForm={handleFormData} handleSubmit={onRefinance}/>
        </ContentWithAsideLayout>
      )}
    </Fragment>
  );
};

export default RefinancePage
