import React, { useState, useContext, forwardRef, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import DatePicker from 'react-datepicker';
import DetailRow from '../../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../../components/common/FalconCardHeader';
import MainTable from '../../../../components/tables/MainTable';
// import ButtonIcon from '../../../components/common/ButtonIcon';
// import { RecaudoContext } from '../RecaudoContext';
// import RequiredMarked from '../../../components/common/RequiredMarked';
// import ModalSearchClient from '../../solicitud/ModalSearchClient';
// import { formatterId } from '../../../helpers/formatters';
import { currencyFormat } from '../../../../helpers/tableFormatters';


const CreditCostInfo = ({ context: sourceContext, handleDateRange, showDatePicker, terceros, title, btnText, noCancel }) => {
  const context = useContext(sourceContext);
  const { credits } = context;

  const columns = [
    {
        dataField: 'nomPagaduria',
        text: 'Pagaduria',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'credito',
      text: 'Crédito',
      headerClasses: 'border-0',
      classes: 'border-0 py-2 align-middle',
    },
    {
      dataField: 'monto',
      text: 'Saldo a fecha de Corte',
      headerClasses: 'border-0 text-right',
      align: 'right',
      classes: 'border-0 py-2 align-middle',
      formatter: (value) => currencyFormat(value),
    },
    {
      dataField: '',
      headerClasses: 'border-0 text-center',
      text: '',
      classes: 'border-0 py-2',
      sort: false,
    },
  ]
  
  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Datos Generales del Credito">
      </FalconCardHeader>
      <CardBody className="bg-light border-top px-4">
          <Row>
            <MainTable 
              data={credits}
              columns={columns}
              keyField="credito"
              noSearch
              noAdd
              noPag
              noHead
            />
        </Row>
      </CardBody>
    </Card>
  );
};

export default CreditCostInfo;
