import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import Section from '../../components/common/Section';
import Logo from '../../components/navbar/Logo';

const HomePage = () => {

  return (
    <Section className="py-0">
      <Row className="flex-center py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Card className="text-center">
          <CardBody className="p-5">
            <Logo width={'80%'} />
          </CardBody>
        </Card>
        </Col>
      </Row>
    </Section>
  )
}

export default HomePage
