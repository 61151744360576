export const authSesion = () => {
    const user = JSON.parse(sessionStorage.getItem('Usuario'));
    if (user) {
        return user.sesionId;
    }   
}

export const headers = {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json'
}