import React, { useContext, useEffect } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { StateContext } from '../../state/StateContext';
import { idFormat } from '../../../helpers/tableFormatters';
import ThemeBackground from '../../../components/common/ThemeBackground';
import { getTodayStr } from '../../../helpers/dateFormatters';
import { CREDIT_MODE_ID } from '../../../data/constans';
import { currencyFormat, refFormat } from '../../../helpers/tableFormatters';
import ButtonIcon from '../../../components/common/ButtonIcon';

const CreditInfo = () => {
  const context = useContext(StateContext);
  const { creditSelected: selected, handleRefinance } = context;

  return (
    <Card className="my-3" >
      <ThemeBackground color='info' />
      <FalconCardHeader title="Información del Crédito"></FalconCardHeader>
      <CardBody className="border-top px-4">
        {selected && (
        <Row>
          <Col lg={4} xs={4}>
            <DetailRow title="Nro. de Solicitud">{selected.idSolicitud ? refFormat(selected.idSolicitud) : <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Línea de Crédito">{selected.nomLinea || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Modalidad de Crédito">REFINANCIACION</DetailRow>
          </Col>

          <Col lg={4} xs={4}>
            <DetailRow title="Fecha Liquidación">{selected.fecha || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Sublínea de Crédito">{selected.nomSubLinea || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Monto Refinanciado">{selected.saldoProyectado ? currencyFormat(selected.saldoProyectado) : <span className='p-2'></span>}</DetailRow>
          </Col>

          <Col lg={4} xs={4} className="text-right">
            <DetailRow title="Fecha de Desembolso">{selected.fechaDesembolso  || <span className='p-2'></span>}</DetailRow>
            <DetailRow title="Nro. de Crédito">{selected.idCredito || <span className='p-2'></span>}</DetailRow>
          </Col>

        </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default CreditInfo;
