import React, { useState, useEffect, Fragment } from 'react';
import { Modal , ModalHeader, Badge } from 'reactstrap';
import cogoToast from 'cogo-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEdit } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GestionUsuariosForm from './UsersForm';
import { userServices } from '../../services';
import PageHeader from '../../components/common/PageHeader';
import MainTable from '../../components/tables/MainTable';

const UsersPage = () => {
  const [initialdata] = useState({
    Nombre: '',
    Identificacion: '',
    Estado: true
  })
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ListData, setListData] = useState([]);
  const [EstadoForm, setEstadoForm] = useState(false);
  const [DataEdict, setDataEdict] = useState(initialdata);
  const [currentSearch, setCurrentSearch] = useState("");

  useEffect(() => {
    load();
  }, [])

  const columns = [
    {
        dataField: 'nombre',
        text: 'Nombre',
        headerClasses: 'border-0',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
        dataField: 'correo',
        headerClasses: 'border-0',
        text: 'Correo',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
        dataField: 'identificacion',
        headerClasses: 'border-0',
        text: 'Identificacion',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
        dataField: 'nombrePerfil',
        headerClasses: 'border-0',
        text: 'Perfil',
        classes: 'border-0 py-2 align-middle',
        sort: true
    },
    {
        dataField: 'estado',
        headerClasses: 'border-0',
        text: 'Estado',
        formatter: (estado) => stateFormatter(estado),
        classes: 'border-0 py-2 align-middle',
    },
    {
        dataField: '',
        headerClasses: 'border-0',
        text: 'Acciones',
        classes: 'border-0 py-2 align-middle',
        sort: false,
        formatter: (dataField, selected) => actionsFormatter(dataField, selected),
        align: 'center',
        headerAlign: 'center'
    },
  ];

  const stateFormatter = status => {
    let color = '';
    let icon = '';
    let text = '';
    switch (status) {
      case '1':
        color = 'success';
        icon = 'check';
        text = 'Activo';
        break;
      case '0':
        color = 'secondary';
        icon = 'ban';
        text = 'Inactivo';
        break;
      default:
        color = 'warning';
        icon = 'stream';
        text = 'Pendiente';
    }
    return (
      <Badge color={`soft-${color}`} className="rounded-capsule">
        {text}
      </Badge>
    );
  };
  

  const actionsFormatter = (dataField, selected) => {
    if(!selected) return <></>
    return (
      <div>
        {selected.estado === '2' ? (
          <Tooltip title="Reenviar">
            <IconButton>
              <FontAwesomeIcon style={{ width: '20px' }} icon={faEnvelope} color="SteelBlue" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Reenviar">
            <IconButton disabled>
              <FontAwesomeIcon style={{ width: '20px' }} icon={faEnvelope} color="LightGray" />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Editar">
          <IconButton
            onClick={() => actiononContact('edit', selected)}
          >
            <FontAwesomeIcon
              style={{ width: '20px' }}
              icon={faEdit}
              color="SteelBlue"
            />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const load = async () => {
    setLoading(true);
    const response = await userServices.getUsers(currentSearch)

    if(response.error){
      cogoToast.error(response.msg, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    setListData(response.objResponse);
    setLoading(false);
  }

  const MostrarFormulario = (e) => {
    setShowModal(false);
    setEstadoForm(EstadoForm);
    if (e === 'Nuevo') {
    } else if (e === 'Cancelar') {
      setDataEdict(initialdata);
    } else if (e === 'Cargar') {
      load();
    } else if (e === 'Guardado') {
      setDataEdict(initialdata);
      load();
    } else if (e.perfil) {
      setDataEdict(e);
    }
  }

  const Consultar = async data => {
    let filter = data;
    setCurrentSearch(filter);

    const response = await userServices.getUsers(filter)

    if(response.error){
      cogoToast.error(data.objError.msgError, { position: 'bottom-right', heading: 'Error en la respuesta' });
      return;
    }

    setListData(response.objResponse);
  }
  
  const actiononContact = async (action, e = null) => {
    setShowModal(true);
    setEstadoForm(!EstadoForm);
    if (action === 'add') {
    } else if (action === 'edit') {
      setDataEdict(e);
    }
  };

  return (
    <Fragment>
      <Modal isOpen={showModal} size="md" toggle={() => setShowModal(false)}>
        <ModalHeader>
            <div className="row pt-3 ">
                <div className="flex col-md-12">
                <h3>Usuario</h3>
                </div>
            </div>
        </ModalHeader>
        <GestionUsuariosForm
          MostrarFormulario={data => MostrarFormulario(data)}
          data={DataEdict}
          actiononContact={(action, data) => actiononContact(action, data)}
        />
      </Modal>
      <PageHeader
        title="Usuarios"
        className="mb-3"
      />
      <MainTable 
        data={ListData} 
        columns={columns} 
        keyField="usuario"
        handleAddNew={actiononContact}
        search={Consultar}
        className="px-2"
        loading={loading}
      />
    </Fragment>
  )
    
}

export default UsersPage;
