import React from 'react';
import { Nav } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';


const TopNavRightSideNavItem = () => {
  let nombre;
  let objsesion = JSON.parse(sessionStorage.getItem('Usuario')) ;
  if(objsesion === null){
    nombre = "Cargando nombre de usuario";
    window.location.replace('/authentication/basic/login');

  }else if (objsesion !== undefined){
    nombre = objsesion.nombre;
    
  }

  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      
      {nombre}&nbsp;&nbsp;
  
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
