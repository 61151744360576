import React, { useState, useEffect, Fragment } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import cogoToast from 'cogo-toast';
import PageHeader from '../../components/common/PageHeader';
import { InitCreditContext } from './InitCreditContext';
import InitCreditClient from './childrens/InitcreditClient';
import { creditServices } from '../../services';

const InitCreditPage = () => {
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState();
  const [clientCredits, setClientCredits] = useState([]);

  useEffect(() => {
    if (!client) return;
    loadClientCredits();
  }, [client])

  const loadClientCredits = async () => {
    const response = await creditServices.getCreditsByClient(client.id);
    if (!response || response.error) {
      cogoToast.error(response.msg,
        { position: 'bottom-left', heading: 'Error al cargar los créditos del cliente' });
      return;
    }

    setClientCredits(response.objResponse);
  }

  const onClientSelect = data => {
    setClient(data)
  }

  const handleCleanClient = () => {
    setClient(null);
    setClientCredits([]);
  }

  return (
    <InitCreditContext.Provider value={{
      loading,
      client,
      clientCredits,
      onClientSelect,
      loadClientCredits,
      handleCleanClient
    }}>
      <Fragment>
        <PageHeader title="Inicializar Crédito" className="mb-3" >
        </PageHeader>
        <div>
          <InitCreditClient />
        </div>
      </Fragment>
    </InitCreditContext.Provider>
  );
};

export default InitCreditPage
