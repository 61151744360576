import React, { useContext } from 'react';
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SolicitudContext } from '../SolicitudContext';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import RequiredMarked from '../../../components/common/RequiredMarked';

const PesonalReference = () => {
  const { handleFormRef } = useContext(SolicitudContext);
  const { register, triggerValidation, errors, setValue, getValues } = useForm();

  const onChange = async e => {
    const { name, value } = e.target;
    setValue(name, value);
    const formRefData = getValues();
    await triggerValidation();
    const isValid = Object.keys(errors).length < 1;
    handleFormRef(isValid ? formRefData : null);
  }
  

  return (
    <Card className={`my-3`} >
      <FalconCardHeader title="Referencia Personal">
      </FalconCardHeader>
      <CardBody className="bg-light border-top">
        <form onChange={onChange}>
        <Row>
          <Col lg={6} xs={12} >
            <FormGroup>
            <Label for="identificacion">
              <RequiredMarked />
              Identificación
            </Label>
            <Input
              className="text-uppercase"
              id="identificacion"
              invalid={errors['identificacion']}
              maxLength={20}
              name="identificacion"
              {...register("identificacion", {
                required: true,
              })}
            />
            </FormGroup>
          </Col>
          <Col lg={6} xs={12} >
            <FormGroup>
              <Label for="fullName">
                <RequiredMarked />
                Nombres y Apellidos
              </Label>
              <Input
                className="text-uppercase"
                id="fullName"
                invalid={errors['fullName']}
                maxLength={100}
                name="fullName"
                {...register("fullName", {
                  required: true,
                })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col >
            <FormGroup>
              <Label for="address">
                <RequiredMarked />
                Domicilio
              </Label>
              <Input
                className="text-uppercase"
                id="address"
                invalid={errors['address']}
                maxLength={100}
                name="address"
                {...register("address", {
                  required: true,
                })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xs={12} >
            <FormGroup>
              <Label for="phone">
                <RequiredMarked />
                Teléfono
              </Label>
              <Input
                id="phone"
                invalid={errors['phone']}
                name="phone"
                maxLength={20}
                {...register("phone", {
                  required: true,
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                })}
              />
            </FormGroup>
          </Col>
          <Col lg={6} xs={12} >
            <FormGroup>
            <WizardInput
              type="email"
              label="Correo"
              invalid={errors['email']}
              id="email"
              maxLength={50}
              name="email"
              {...register("email", {
                required: false,
                validate: value => {
                  return value ? !!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) : true;
                }
              })}
              errors={errors}
            />
            </FormGroup>
          </Col>
        </Row>
        </form>
      </CardBody>
    </Card>
  )
}

export default PesonalReference