import React, { useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import {urlApi} from  '../configuracion/serverConfig';
import { toast } from 'react-toastify';

const AuthProvider = (props) => {

    const [auth, setAuth] = useState(undefined);

    useEffect(() => {
       var objSesion = JSON.parse(sessionStorage.getItem('Usuario'));
        if(objSesion !== null && objSesion.hasOwnProperty("sesionId") && objSesion.hasOwnProperty("usuario")){
            fetch(`${urlApi}/usuario/login/${objSesion.sesionId}/${objSesion.usuario}`, {
                method: 'GET',
                body: JSON.stringify(),
                headers: {
                  'Content-Type': 'application/json; charset=UTF-8',
                  Accept: 'application/json'
                }
              })
                .then(res => res.json())
                .then(data => data)
                .then(data => {
                  if (data.status === 0) {
                     setAuth(true);
                  } else {
                    setAuth(false);
                    toast.info(data.objError.msgError);
                  }
                })
                .catch(err => console.log('err', err));
        }else {
          setAuth(false);
        }
    }, []);


    if(auth === undefined) return null;

        return (
            <>
            <AuthContext.Provider value={[auth, setAuth]}>
                {props.children}
            </AuthContext.Provider>
            </>
        );
    
    
}

export default AuthProvider;

