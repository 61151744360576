import React, { Fragment } from 'react';
import WizardInput from './WizardInput';
import { Col, CustomInput, Row } from 'reactstrap';
import Select from 'react-select';
const options = [
  {
    label: 'CC-Cédula de Ciudadania',
    value: 'CC'},
    {label: 'CE-Cédula de Extranjería',
    value: 'CE'},
    {label: 'PS-Pasaporte',
    value: 'PS'},

  
];
class BasicUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.initialdata = {
      Codigo: '',
      Procedimiento: ''
    };

    this.state = {
      ListData: [],
      EstadoForm: false,
      DataEdict: this.initialdata,
      EstadoUsuario: false,
      identificacion: '',
      SelectTipoDocumentoRep: [{label: '', value: ''}]
    };
  }
  componentDidMount() {
  if(this.props.IdentificacionRep){
    this.setState(state => ({
      ...state,
      SelectTipoDocumentoRep: this.props.IdentificacionRep
    }));
  }
  }
  cambiarselect = data => {
    let datac = data.target.value;
    this.setState(state => ({
      ...state,
      SelectTipoDocumentoRep: datac
    }));
    this.props.cambiarselect2(datac);
  };





  render() {
    return (
      <>
        <Fragment>
          <Row form>
          <Col>
          <label>Tipo de Identificacion*</label>
              <WizardInput
                    type="select"
                    tag={CustomInput}
                    placeholder="Tipo de Identificacion"
                    id="SelectTipoDocumentoRep"
                    name="SelectTipoDocumentoRep"
                    value={this.state.SelectTipoDocumentoRep}
                    errors={this.props.errors}
                    options={options}
                    onChange={this.cambiarselect}
                    innerRef={this.props.register({
                      required: 'Tipo Identificacion',
                    })}
                  />
                </Col>
            
            <Col>
              <WizardInput
                type="text"
                label="Numero Identificación*"
                value={this.state.NumeroidentificacionRep}
                // placeholder="Name"
                name="NumeroidentificacionRep"
                innerRef={this.props.register({
                  required: 'Name is required',
                  minLength: {
                    value: 2,
                    message: 'Min length 2'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="text"
                label="Primer Nombre*"
                value={this.state.PrimerNombreRep}
                id="PrimerNombreRep"
                name="PrimerNombreRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Segundo Nombre*"
                value={this.state.SegundoNombreRep}
                id="SegundoNombreRep"
                name="SegundoNombreRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="text"
                label="Primer Apellido*"
                value={this.state.PrimerApellidoRep}
                id="PrimerApellidoRep"
                name="PrimerApellidoRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                label="Segundo Apellido*"
                value={this.state.SegundoApellidoRep}
                id="SegundoApellidoRep"
                name="SegundoApellidoRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="Date"
                label="Fecha de Inicio Representación"
                value={this.state.FechaInicioRepresentacionRep}
                id="FechaInicioRepresentacionRep"
                name="FechaInicioRepresentacionRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
            <Col>
              <WizardInput
              value={this.state.CorreoElectronicoRep}
                type="text"
                label="Correo Electrónico"
                id="CorreoElectronicoRep"
                name="CorreoElectronicoRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
          <Row form>
            <Col>
              <WizardInput
                type="text"
                label="Teléfono Celular*"
                value={this.state.TelefonoCelularRep}
                id="TelefonoCelularRep"
                name="TelefonoCelularRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
            <Col>
              <WizardInput
                type="text"
                value={this.state.TelefonoAlternoRep}
                label="Teléfono Alterno*"
                // placeholder="Confirm Password"
                id="TelefonoAlternoRep"
                name="TelefonoAlternoRep"
                innerRef={this.props.register({
                  required: 'You must specify a password',
                  minLength: {
                    value: 2,
                    message: 'Password must have at least 2 characters'
                  }
                })}
                errors={this.props.errors}
              />
            </Col>
          </Row>
        </Fragment>
      </>
    );
  }
}

export default BasicUserForm;
