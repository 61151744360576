import React, { useState, useContext, useEffect } from 'react';
import {
  Input,
  Button,
  FormGroup,
  Card,
  CardBody,
  Row,
  Col,
} from 'reactstrap';
import cogoToast from 'cogo-toast';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import CustomInput from 'reactstrap/lib/CustomInput';
import DetailRow from '../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import FalconCardHeader from '../../../components/common/FalconCardHeader';
import { DesembolsoContext } from '../DesembolsoContext';
import WizardInput from '../../../components/auth/wizard/WizardInput';
import { desembolsoServices } from '../../../services';
import { formatterId } from '../../../helpers/formatters';

const ObservationsSection = () => {
  const { register, setValue, getValues, errors } = useForm({
    defaultValues: {
      chequeValue: 0,
    }
  });
  const context = useContext(DesembolsoContext);
  const { selected, loading, handleBack, onSubmit, handleObservationData } = context;

  const onChange = async e => {
    let { name, value } = e.target;
    setValue(name, value);
    onSetData();
  }

  const onSetData = () => {
    const formData = getValues();
    handleObservationData(formData);
  }

  return (
    <Card className={`my-3`} >
      <CardBody className="bg-light border-top px-4">
        {!loading && selected ? (
          <form onChange={onChange}>
            <Row>
              <Col lg={12}>
                <DetailRow title="Observaciones">
                  <FormGroup>
                    <Input
                      type="textarea"
                      id="observations"
                      invalid={errors['observations']}
                      maxLength={500}
                      name="observations"
                      tabIndex="13"
                      {...register("observations", {
                        required: false,
                      })}
                    />
                  </FormGroup>
                </DetailRow>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={5} xs={12} className="px-1">
                <Button onClick={handleBack} className="w-100 px-1" transform="shrink-3 down-2" color="secondary">
                  Cancelar
                </Button>
              </Col>
              <Col lg={7} xs={12} className="px-1">
                <Button onClick={onSubmit} className="w-100 px-1" transform="shrink-3 down-2" color="primary" >
                  Desembolsar
                </Button>
              </Col>
            </Row>
          </form>
        ) : <></>}
      </CardBody>
    </Card>
  );
};

export default ObservationsSection;
