import React, { useState, useContext, useEffect } from 'react';
import { Col, Card, CardBody, Row, FormGroup, Input, Label, CustomInput,  Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import DetailRow from '../../../../components/GestionPersonaEmpresa/personDetails/DetailRow';
import CountUp from 'react-countup';
import WizardInput from '../../../../components/auth/wizard/WizardInput';
import FalconCardHeader from '../../../../components/common/FalconCardHeader';
import { juridicoServices } from '../../../../services';
import Flatpickr  from 'react-flatpickr';
import { getLocaleCalendarConfig } from '../../../../helpers/utils';

const GeneralInfo = ({context: inputContext}) => {
    const { loading, totalCost, generalInfo, total, handleGeneralInfoChange } = useContext(inputContext);
    const { register, triggerValidation, errors, setValue, getValues } = useForm({
      defaultValues: {
        despacho: generalInfo && generalInfo.despacho,
        dateNotification: generalInfo && generalInfo.dateNotification,
        numRef: generalInfo && generalInfo.numRef,
      }
    });
    const [despachos, setDespachos] = useState([]);
    const today = new Date().toISOString().split("T")[0].replaceAll("-","/");

    useEffect(() => {
    }, [generalInfo])

    useEffect(() => {
      loadDespachos();
    }, [])

    const onChange = async e => {
      const { name, value } = e.target;
      setValue(name, value);
      const formCreditInfo = getValues();
      await triggerValidation();
      const isValid = Object.keys(errors).length < 1;
      handleGeneralInfoChange(formCreditInfo);
    }

    const loadDespachos = async () => {
      const res = await juridicoServices.getDespachos();
      if(res.error || !res.objResponse) return;

      const tempDespachos = res.objResponse.map(despacho => ({
        label: despacho.nombre,
        value: despacho.juzgado
      }));
      setDespachos(tempDespachos);
    }

    return (
        <Card className={`my-3`}>
          <FalconCardHeader title="Resumen Costas Procesales"></FalconCardHeader>
          <CardBody className="border-top">
            <form onChange={onChange}>
              <Row className="px-2">
                <Col xl={12}>
                  <DetailRow title="Despacho Judicial">
                    <FormGroup>
                      <WizardInput
                        type="select"
                        tag={CustomInput}
                        value={generalInfo && generalInfo.despacho}
                        placeholder="Seleccionar"
                        id="despacho"
                        name="despacho"
                        options={despachos}
                        errors={errors}
                        innerRef={register({
                          required: true,
                        })}
                      />
                    </FormGroup>
                  </DetailRow>
                </Col>

                <Col lg={6}>
                  <DetailRow title="Numero Referencia del Proceso">
                    <FormGroup className="pr-4">
                      <Input
                        id="numRef"
                        value={generalInfo && generalInfo.numRef}
                        name="numRef"
                        {...register("numRef", {
                          required: true,
                        })}
                      />
                    </FormGroup>
                  </DetailRow>
                </Col>

                <Col lg={6}>
                  <DetailRow title="Fecha Notificación a Pagaduría">
                    <FormGroup className="pr-4">
                      <Flatpickr
                        className="form-control"
                        placeholder='aaaa/mm/dd'
                        value={generalInfo?.dateNotification && new Date(generalInfo.dateNotification.replaceAll('-', '/'))}
                        name="dateNotification"
                        id="dateNotification"
                        {...register('dateNotification')}
                        options={{
                          dateFormat: "Y/m/d",
                          maxDate: today,
                          locale: getLocaleCalendarConfig()
                        }}
                        onChange={(date, dateStr) => {
                          onChange({target: {name: "dateNotification", value: dateStr.replaceAll("/", "-")}})
                        }}
                      />
                    </FormGroup>
                  </DetailRow>
                </Col>
              </Row>

              <Row className="px-2">
                <Col lg={6}>
                  <h4>Valor del Embargo</h4>
                </Col>
                <Col lg={6}>
                  <h4><CountUp end={total ? total : 0} duration={1} prefix="$" separator="." decimal="," decimals={2} /></h4>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Card>
    )
};

export default GeneralInfo;
